import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import ModelDeleteModal from './ModelDeleteModal';
import TableContainer from '../shared/TableContainer';

const ModelTable = ({ models, commodities, fetchModels, apiUrl }) => {
  const [selectedModel, setSelectedModel] = useState(null);
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const toast = useToast();

  const handleDeleteClick = (model) => {
    setSelectedModel(model);
    onDeleteOpen();
  };

  const isModelAssigned = (modelId) => {
    return commodities.some((commodity) => commodity.modelId === modelId);
  };

  return (
    <>
    <TableContainer>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Preprocessing</Th>
            <Th>Cutoffs (L/M/U)</Th>
            <Th>Plotting Enabled</Th>
            <Th>Filename</Th>
          </Tr>
        </Thead>
        <Tbody>
          {models.map((model) => (
            <Tr key={model.id}>
              <Td>{model.name}</Td>
              <Td>{model.modeltype}</Td>
              <Td>{model.preprocessing}</Td>
              <Td>{`${model.lower}/${model.middle}/${model.upper}`}</Td>
              <Td>{model.enablePlotting ? "True" : "False"}</Td>
              <Td>{model.filename}</Td>
              {/* Commenting out actions menu as per your existing code
              <Td>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<ChevronDownIcon />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem onClick={() => handleDeleteClick(model)}>
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
              */}
            </Tr>
          ))}
        </Tbody>
      </Table>
      </TableContainer>

      <ModelDeleteModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        model={selectedModel}
        isModelAssigned={isModelAssigned}
        onSuccess={fetchModels}
        apiUrl={apiUrl}
      />
    </>
  );
};

export default ModelTable;