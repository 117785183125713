// src/context/AuthContext.js
import React, { createContext, useContext, useState, useCallback } from "react";
import { Amplify } from "aws-amplify";
import {
  signUp,
  signIn,
  signOut,
  confirmSignUp,
  getCurrentUser,
  fetchAuthSession,
} from "aws-amplify/auth";
import axios from "axios";

// Initialize Amplify configuration
Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
  },
});

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to decode JWT token
  const decodeToken = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  // Helper function for parsing roles
  const parseRoles = (rolesString) => {
    if (!rolesString) return [];
    try {
      if (rolesString.startsWith('custom:roles')) {
        rolesString = rolesString.substring(rolesString.indexOf('['));
      }
      return JSON.parse(rolesString);
    } catch (error) {
      console.error('Error parsing roles:', error);
      // Fallback to single role if available
      return rolesString ? [rolesString] : [];
    }
  };


  const ensureUserInDatabase = async (firstName, lastName) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/ensure`, {
        first_name: firstName,
        last_name: lastName
      });
    } catch (error) {
      console.error('Error ensuring user in database:', error);
    }
  };

  // New function to get user ID from token
  const getUserId = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      if (tokens?.idToken) {
        const decodedToken = decodeToken(tokens.idToken.toString());
        return decodedToken?.sub || null; // 'sub' claim contains the Cognito User ID
      }
      return null;
    } catch (error) {
      console.error("Error getting user ID:", error);
      return null;
    }
  };


  const getUserFirstName = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      if (tokens?.idToken?.payload) {
        return tokens.idToken.payload.given_name || null;
      }
      return null;
    } catch (error) {
      console.error("Error getting user given name:", error);
      return null;
    }
  };

  // Check if email domain is allowed
  const checkAllowedDomain = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/public/companies/check-domain/${email}`
      );
      return response.data.has_access;
    } catch (error) {
      console.error("Error checking domain access:", error);
      return false;
    }
  };

  // Sign up
  const handleSignUp = async ({ email, password, firstName, lastName }) => {
    try {
      if (!(await checkAllowedDomain(email))) {
        throw new Error("Email domain not allowed");
      }

      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            given_name: firstName,
            family_name: lastName,
          },
        },
      });

      return { email };
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  // Confirm sign up (verify email)
  const handleConfirmSignUp = async (email, code) => {
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  // Sign in
  const handleSignIn = async (email, password) => {
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password,
      });
  
      if (isSignedIn) {
        const currentUser = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        const userAttributes = tokens.idToken.payload;
        
        // Get roles from token
        const roles = parseRoles(userAttributes['custom:roles']);
        
        setUser({
          ...currentUser,
          roles,
          company_id: userAttributes['custom:company_id'],
          sub: userAttributes['sub'],
        });
  
        try {
          await axios.post(`${process.env.REACT_APP_API_URL}/api/users/ensure`, {
            first_name: userAttributes.given_name,
            last_name: userAttributes.family_name
          });
        } catch (error) {
          console.error('Error ensuring user in database:', error);
        }
        
        return currentUser;
      }
  
      return nextStep;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  // Sign out
  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  // Get current session
  const getCurrentSession = useCallback(async () => {
    try {
      const { tokens } = await fetchAuthSession();
      return tokens;
    } catch (error) {
      console.log("Error getting session:", error); // Debug log
      if (error.message !== "No current user") {
        setError(error.message);
      }
      return null;
    }
  }, []);

  const getIdToken = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      return tokens?.idToken?.toString();
    } catch (error) {
      console.error("Error getting ID token:", error);
      return null;
    }
  };

  // Check auth state on mount
  React.useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        const userAttributes = tokens.idToken.payload;
        
        // Get roles from token
        const roles = parseRoles(userAttributes['custom:roles']);
        
        setUser({
          ...currentUser,
          roles,
          role: roles[0], // Keep single role for backward compatibility
          company_id: userAttributes['custom:company_id']
        });
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
  
    checkUser();
  }, []);

  // Set up axios interceptor for adding auth token
  React.useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        try {
          const session = await getCurrentSession();

          if (session?.idToken) {
            config.headers.Authorization = `Bearer ${session.idToken.toString()}`;
          } else {
            console.log("No idToken available"); // Debug log
          }

          return config;
        } catch (error) {
          console.error("Error in interceptor:", error); // Debug log
          return config;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [getCurrentSession]);

  const value = {
    user,
    loading,
    error,
    signUp: handleSignUp,
    confirmSignUp: handleConfirmSignUp,
    signIn: handleSignIn,
    signOut: handleSignOut,
    getCurrentSession,
    getIdToken,
    getUserId,
    getUserFirstName,
    ensureUserInDatabase,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
