import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Badge,
  Spinner,
  Text,
  VStack,
  HStack,
  IconButton,
  Tooltip,
  useToast,
  Progress,
  Box,
  TableContainer,
} from "@chakra-ui/react";
import { FileText, Download, QrCode } from "lucide-react";
import { format } from "date-fns";
import CertificateForm from "./CertificateForm";
import {
  fetchCertificates,
  generateCertificate,
  getCertificateDownloadUrl,
  getCertificateFormData,
} from "../../../modules/JobApiService.jsx";
import axios from "axios";
import { biaColors } from "../../../styles/colors.jsx";

const CertificateModal = ({ isOpen, onClose, set, jobId, userRoles }) => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [previousVersion, setPreviousVersion] = useState(null);
  const [pollingCertificate, setPollingCertificate] = useState(null);
  const [pollingTimeout, setPollingTimeout] = useState(null);
  const toast = useToast();

  const isGlobalAdmin = userRoles.includes("GLOBAL_ADMIN");

  const loadPreviousVersionData = async () => {
    if (certificates.length > 0) {
      const latestVersion = Math.max(...certificates.map((c) => c.version));
      try {
        const data = await getCertificateFormData(set.id, latestVersion);
        setPreviousVersion(data);
      } catch (error) {
        console.error("Error loading previous version data:", error);
        // Don't show error to user - gracefully fall back to empty form
        setPreviousVersion(null);
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setShowForm(false);
      setPreviousVersion(null);
      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
      }
      setPollingCertificate(null);
      setPollingTimeout(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && set) {
      loadCertificates();
    }
  }, [isOpen, set]);

  useEffect(() => {
    let interval;
    if (pollingCertificate) {
      interval = setInterval(checkCertificateStatus, 5000);

      // Set a timeout to stop polling after 5 minutes
      const timeout = setTimeout(() => {
        if (interval) clearInterval(interval);
        setPollingCertificate(null);
        setPollingTimeout(null);
        toast({
          title: "Certificate Generation Timeout",
          description:
            "The certificate generation process took too long. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }, 5 * 60 * 1000);

      setPollingTimeout(timeout);
    }

    return () => {
      if (interval) clearInterval(interval);
      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
        setPollingTimeout(null);
      }
    };
  }, [pollingCertificate]);

  const loadCertificates = async () => {
    try {
      setLoading(true);
      const data = await fetchCertificates(set.id);
      setCertificates(data);

      // If we have certificates and about to show form, get the latest version data
      if (data.length > 0) {
        const latestVersion = Math.max(...data.map(c => c.version));
        try {
          const formData = await getCertificateFormData(set.id, latestVersion);
          setPreviousVersion(formData);
        } catch (error) {
          console.error("Error loading previous version data:", error);
          // Don't show error to user - gracefully fall back to empty form
          setPreviousVersion(null);
        }
      }
    } catch (error) {
      toast({
        title: "Error loading certificates",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };


  const checkCertificateStatus = async () => {
    try {
      const data = await fetchCertificates(set.id);
      setCertificates(data);

      // Find the certificate we're polling for
      const cert = data.find((c) => c.version === pollingCertificate.version);
      if (cert && ["COMPLETED", "ERROR"].includes(cert.status)) {
        // Clear both the interval and timeout
        if (pollingTimeout) {
          clearTimeout(pollingTimeout);
          setPollingTimeout(null);
        }
        setPollingCertificate(null);

        if (cert.status === "ERROR") {
          toast({
            title: "Certificate Generation Failed",
            description: cert.error_message || "An unknown error occurred",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Certificate Generated",
            description: "The certificate has been generated successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error("Error checking certificate status:", error);
    }
  };

  const handleDownload = async (certificateId, fileType) => {
    try {
      const { url, filename } = await getCertificateDownloadUrl(
        set.id,
        certificateId,
        fileType
      );

      // Download file using axios
      const response = await fetch(url);
      const blob = await response.blob();

      // Create object URL and trigger download
      const objectUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
      }, 100);
    } catch (error) {
      toast({
        title: "Download Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCertificateSubmit = async (formData) => {
    try {
      await generateCertificate(set.id, formData);

      // Set polling state with new version
      setPollingCertificate({
        version: Math.max(...certificates.map((c) => c.version), 0) + 1,
      });

      setShowForm(false);
      toast({
        title: "Certificate Request Submitted",
        description: "The certificate generation process has started",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error Generating Certificate",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderStatus = (certificate) => {
    const getColor = (status) => {
      switch (status) {
        case "COMPLETED":
          return biaColors.customGreen
        case "PENDING":
          return biaColors.customBlue;
        case "ERROR":
          return biaColors.customRed;
        default:
          return "gray";
      }
    };

    return (
      <Tooltip
        label={certificate.error_message}
        isDisabled={!certificate.error_message}
      >
        <Badge
          bg={getColor(certificate.status)}
          color="white"
          cursor={certificate.error_message ? "help" : "default"}
          borderRadius="md"
        >
          {certificate.status}
        </Badge>
      </Tooltip>
    );
  };

  const handleClose = () => {
    setShowForm(false);
    onClose();
  };

  const handleFormCancel = () => {
    setShowForm(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxWidth="90vw">
        <ModalHeader>Certificates - Set {set?.setRefNo}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {showForm ? (
            <CertificateForm
              set={set}
              jobId={jobId}
              onSubmit={handleCertificateSubmit}
              onCancel={handleFormCancel}
              previousVersion={previousVersion}
            />
          ) : (
            <VStack spacing={4} align="stretch">
              <HStack justify="space-between">
                <Text fontSize="md">Available Certificates</Text>
                <Button
                  colorScheme="blue"
                  onClick={() => setShowForm(true)}
                  isDisabled={!!pollingCertificate}
                >
                  Generate New Certificate
                </Button>
              </HStack>

              {pollingCertificate && (
                <Box>
                  <Text mb={2}>
                    Generating certificate version {pollingCertificate.version}
                    ...
                  </Text>
                  <Progress size="xs" isIndeterminate />
                </Box>
              )}

              {loading ? (
                <Spinner />
              ) : certificates.length === 0 ? (
                <Text>No certificates available</Text>
              ) : (
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Version</Th>
                        <Th>Created</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {certificates.map((cert) => (
                        <Tr key={cert.id}>
                          <Td>{cert.version}</Td>
                          <Td>
                            {format(
                              new Date(cert.created_at),
                              "yyyy-MM-dd HH:mm"
                            )}
                          </Td>
                          <Td>{renderStatus(cert)}</Td>
                          <Td>
                            <HStack spacing={2}>
                              {cert.status === "COMPLETED" && (
                                <>
                                  <Tooltip label="Download PDF">
                                    <IconButton
                                      icon={<FileText size={18} />}
                                      size="sm"
                                      onClick={() =>
                                        handleDownload(cert.id, "pdf")
                                      }
                                    />
                                  </Tooltip>

                                  <Tooltip label="Download QR Code">
                                    <IconButton
                                      icon={<QrCode size={18} />}
                                      size="sm"
                                      onClick={() =>
                                        handleDownload(cert.id, "qr")
                                      }
                                    />
                                  </Tooltip>

                                  {isGlobalAdmin && (
                                    <Tooltip label="Download DOCX">
                                      <IconButton
                                        icon={<Download size={18} />}
                                        size="sm"
                                        onClick={() =>
                                          handleDownload(cert.id, "docx")
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CertificateModal;
