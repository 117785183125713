import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const NewCommodityModal = ({
  isOpen,
  onClose,
  models,
  onSuccess,
  existingCommodities,
  apiUrl,
}) => {
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Commodity</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{ name: '', modelId: '' }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required('Name is required')
                .test(
                  'unique-name',
                  'This name is already in use',
                  (value) =>
                    !existingCommodities.some(
                      (c) => c.name.toLowerCase() === value?.toLowerCase()
                    )
                ),
              modelId: Yup.number().required('Model is required'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await axios.post(`${apiUrl}/api/commodities`, values, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                onSuccess();
                onClose();
                toast({
                  title: 'Commodity created successfully',
                  status: 'success',
                  duration: 3000,
                });
              } catch (error) {
                toast({
                  title: 'Error creating commodity',
                  description: error.response?.data?.detail || 'Something went wrong',
                  status: 'error',
                  duration: 3000,
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <VStack spacing={4}>
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter commodity name"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.modelId && touched.modelId}>
                    <FormLabel>Model</FormLabel>
                    <Select
                      name="modelId"
                      value={values.modelId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Model</option>
                      {models.map((model) => (
                        <option key={model.id} value={model.id}>
                          {model.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.modelId}</FormErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    width="full"
                  >
                    Create Commodity
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewCommodityModal;