import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from './context/AuthContext';
import ErrorPage from "./errorPage";
import Portal from "./routes/Portal";
import Root from "./routes/root";
import AveragingForm from "./routes/AveragingForm";
import PlotDevice from "./routes/PlotDevice";
import Plot3D from "./routes/Plot3D";
import './index.css';
import Predictions from "./routes/Predictions";
import NewPrediction from "./routes/NewPrediction";
import Admin from "./routes/Admin";
import SetsPage from "./components/SetsPage";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import DevTools from "./routes/DevTools";
import Dashboard from "./routes/Dashboard";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
    },
    {
        path: "portal",
        element: <ProtectedRoute><Portal /></ProtectedRoute>,
        children: [
            {
                path: "admin",
                element: <Admin />
            },
            {
                path: "average",
                element: <AveragingForm />,
            },
            {
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                path: "dev-tools",
                element: <DevTools />,
            },
            {
                path: "plot",
                element: <PlotDevice />,
            },
            {
                path: "plot3D",
                element: <Plot3D />,
            },
            {
                path: "predictions",
                element: <Predictions />,
            },
            {
                path: "predictions/new",
                element: <NewPrediction />,
            },
            {
                path: "predictions/sets",
                element: <SetsPage />,
            }
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider>
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    </ChakraProvider>
);