import React, { useState, useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Button,
  Checkbox,
  ButtonGroup,
  Text,
  useToast,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  VStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import {
  getPlotData,
  getUploadUrls,
  updateCertGraphStatus,
} from "../modules/JobApiService";
import axios from "axios";
import {biaColors} from "../styles/colors.jsx"

const PlotModal = ({ isOpen, onClose, selectedGraph, onSaveSuccess }) => {
  const [plotData, setPlotData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLabels, setShowLabels] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [plotHeight, setPlotHeight] = useState(600);
  const [pointSize, setPointSize] = useState(12);
  const toast = useToast();
  const cancelRef = useRef();
  const plotRef = useRef(null);
  const [currentView, setCurrentView] = useState(null);
  const [dynamicLayout, setDynamicLayout] = useState(null);
  const [showControls, setShowControls] = useState(false);
const [visibleTraces, setVisibleTraces] = useState({});
const [visibleControls, setVisibleControls] = useState({});

const categorizeTraces = (plottingData) => {
  const mainTraces = {};
  const controlTraces = {};
  const hasNonControlFiles = plottingData.some(
    point => !point.sample.toLowerCase().includes('ctrl')
  );
  
  plottingData.forEach(point => {
    if (point.sample.toLowerCase().includes('ctrl')) {
      controlTraces[point.sample] = !hasNonControlFiles; // Show if only controls exist
    } else {
      mainTraces[point.sample] = true; // Always show non-control files by default
    }
  });
  
  return { mainTraces, controlTraces, hasNonControlFiles };
};

  // Fetch plot data when modal opens
  useEffect(() => {
    const fetchData = async () => {
      if (isOpen && selectedGraph.setId) {
        try {
          setLoading(true);
          const data = await getPlotData(selectedGraph.setId);
          setPlotData(data);
  
          // Initialize traces from plotting data
          if (selectedGraph.plotting) {
            const plottingData = typeof selectedGraph.plotting === 'string' 
              ? JSON.parse(selectedGraph.plotting) 
              : selectedGraph.plotting;
  
            if (Array.isArray(plottingData)) {
              const { mainTraces, controlTraces, hasNonControlFiles } = categorizeTraces(plottingData);
              setVisibleTraces(mainTraces);
              setVisibleControls(controlTraces);
              setShowControls(!hasNonControlFiles); // Show controls by default if only controls exist
            }
          }
        } catch (err) {
          console.error("Error:", err);
          setError("Failed to load plot data");
          toast({
            title: "Error loading plot data",
            status: "error",
            duration: 5000,
          });
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchData();
  }, [isOpen, selectedGraph.setId]);

  useEffect(() => {
    if (isOpen) {
      // Set default heights based on graph type
      if (selectedGraph.type === "3d") {
        setPlotHeight(650);
        setPointSize(6);
      } else {
        setPlotHeight(600);
        setPointSize(12);
      }
    }
  }, [isOpen, selectedGraph.type]);

  

  const getInitialLayout = () => ({
    showlegend: true,
    legend: {
      x: 0,
      y: -0.2,
      orientation: "h",
      yanchor: "bottom",
      xanchor: "left",
    },
    margin: { l: 50, r: 50, t: 50, b: 100 },
    hovermode: "closest",
    plot_bgcolor: "rgb(255, 255, 255)",
    paper_bgcolor: "rgb(255, 255, 255)",
    ...(selectedGraph.type === "3d"
      ? {
          scene: {
            xaxis: { gridcolor: "#f0f0f0", showgrid: true },
            yaxis: { gridcolor: "#f0f0f0", showgrid: true },
            zaxis: { gridcolor: "#f0f0f0", showgrid: true },
            camera: {
              eye: { x: 1.5, y: 1.5, z: 1.5 }, // Initial camera position
              center: { x: 0, y: 0, z: 0 },
              up: { x: 0, y: 0, z: 1 },
            },
          },
        }
      : {
          xaxis: {
            gridcolor: "#f0f0f0",
            showgrid: true,
            zeroline: true,
            zerolinecolor: "#969696",
            zerolinewidth: 1,
          },
          yaxis: {
            gridcolor: "#f0f0f0",
            showgrid: true,
            zeroline: true,
            zerolinecolor: "#969696",
            zerolinewidth: 1,
          },
        }),
  });

  useEffect(() => {
    if (isOpen) {
      setDynamicLayout(getInitialLayout());
    }
  }, [isOpen, selectedGraph.type]);

  const handleSaveForCertificate = async () => {
    if (selectedGraph.certGraphSaved) {
      setIsConfirmDialogOpen(true);
    } else {
      await savePlotImage();
    }
  };

  const savePlotImage = async () => {
    try {
      setIsSaving(true);

      if (!selectedGraph?.jobId || !selectedGraph?.setRefNo) {
        throw new Error("Missing required job ID or set reference number");
      }
  
      // Validate plot reference exists
      if (!plotRef.current) {
        throw new Error("Plot reference not found");
      }
  
      // Rest of your image capture logic
      const plotElement = plotRef.current.getElementsByClassName('js-plotly-plot')[0];
      if (!plotElement) {
        throw new Error("Plotly element not found");
      }
      
      // Use dynamicLayout to ensure current view is captured
      const imageData = await Plotly.toImage(plotElement, {
        format: 'png',
        height: plotHeight,
        width: plotRef.current?.clientWidth || 1200,
        scale: 2,
      });

      // Get upload URL
      const uploadResponse = await getUploadUrls({
        jobId: selectedGraph.jobId,
        setRefNo: selectedGraph.setRefNo,
        files: [{ filename: 'cert_graph.png' }]
      });

      // Convert base64 to blob
      const response = await fetch(imageData);
      const blob = await response.blob();

      // Upload to S3
      const s3Request = axios.create();
      delete s3Request.defaults.headers.common['Authorization'];
      await s3Request.put(uploadResponse.data.file1, blob, {
        headers: {
          "Content-Type": "image/png",
        },
      });

      // Update status in database
      await updateCertGraphStatus(selectedGraph.setId);

      toast({
        title: "Graph saved successfully",
        status: "success",
        duration: 3000,
      });

      if (onSaveSuccess) {
        onSaveSuccess();
      }
    } catch (err) {
      console.error("Error saving graph:", err);
      toast({
        title: "Failed to save graph",
        description: err.message,
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
      setIsConfirmDialogOpen(false);
    }
  };

  // Prepare plot data for Plotly
  const getPlotlyData = () => {
    if (!plotData) return [];

    const traces = [];

    // Add typical data
    if (plotData.typical && plotData.typical.length > 0) {
      traces.push({
        name: "Typical",
        x: plotData.typical.map((p) => parseFloat(p.x)), // Changed from X to x
        y: plotData.typical.map((p) => parseFloat(p.y)), // Changed from Y to y
        ...(selectedGraph.type === "3d" && {
          z: plotData.typical.map((p) => parseFloat(p.z)), // Changed from Z to z
          type: "scatter3d",
        }),
        mode: "markers",
        marker: {
          color: biaColors.customGreen, // more specific green
          size: pointSize,
          line: {
            color: "rgb(0, 100, 0)", // darker green
            width: 1,
          },
          opacity: 0.7,
        },
        showlegend: true,
        type: selectedGraph.type === "3d" ? "scatter3d" : "scatter",
      });
    }

    // Add atypical data
    if (plotData.atypical && plotData.atypical.length > 0) {
      traces.push({
        name: "Atypical",
        x: plotData.atypical.map((p) => parseFloat(p.x)), // Changed from X to x
        y: plotData.atypical.map((p) => parseFloat(p.y)), // Changed from Y to y
        ...(selectedGraph.type === "3d" && {
          z: plotData.atypical.map((p) => parseFloat(p.z)), // Changed from Z to z
          type: "scatter3d",
        }),
        mode: "markers",
        marker: {
          color: biaColors.customRed, // more specific red
          size: pointSize,
          line: {
            color: "rgb(139, 0, 0)", // darker red
            width: 1,
          },
          opacity: 0.7,
        },
        showlegend: true,
        type: selectedGraph.type === "3d" ? "scatter3d" : "scatter",
      });
    }

    // Add results data (keep uppercase X, Y, Z for this since it comes from a different source)
    if (selectedGraph.plotting) {
      const plottingData = typeof selectedGraph.plotting === 'string'
        ? JSON.parse(selectedGraph.plotting)
        : selectedGraph.plotting;
    
      // Add this helper function for generating similar grays
    const getControlColor = (index, totalControls) => {
      // Start from 128 (middle gray) and make tiny increments
      const baseValue = 128;
      const increment = index / (totalControls || 1);  // Avoid division by zero
      return `rgb(${baseValue + increment}, ${baseValue + increment}, ${baseValue + increment})`;
    };
    
    // In getPlotlyData, modify the plotting section:
    if (Array.isArray(plottingData)) {
      // First, count total number of control files
      const controlFiles = plottingData.filter(point => point.sample.toLowerCase().includes('ctrl'));
      const controlsMap = new Map(controlFiles.map((file, index) => [file.sample, index]));
    
      plottingData.forEach((point) => {
        const isControl = point.sample.toLowerCase().includes('ctrl');
        const isVisible = isControl 
          ? (showControls && visibleControls[point.sample])
          : visibleTraces[point.sample];
    
        if (isVisible) {
          traces.push({
            name: point.sample,
            x: [parseFloat(point.X)],
            y: [parseFloat(point.Y)],
            ...(selectedGraph.type === "3d" && {
              z: [parseFloat(point.Z)],
              type: "scatter3d",
            }),
            mode: showLabels ? "markers+text" : "markers",
            text: showLabels ? point.sample : "",
            textposition: "top center",
            marker: {
              color: isControl 
                ? getControlColor(controlsMap.get(point.sample), controlFiles.length)
                : point.sample.toLowerCase().includes('avg')
                  ? biaColors.customBlue
                  : point.sample.endsWith('a')
                    ? "rgb(147, 51, 147)"
                    : point.sample.endsWith('b')
                      ? "rgb(148, 52, 148)"
                      : "rgb(149, 53, 149)",
              size: pointSize,
              line: {
                color: isControl 
                  ? "rgb(100, 100, 100)"
                  : point.sample.toLowerCase().includes('avg')
                    ? "rgb(34, 34, 89)"
                    : "rgb(128, 34, 128)",
                width: 1.5,
              },
              opacity: 0.9,
            },
            showlegend: true,
            type: selectedGraph.type === "3d" ? "scatter3d" : "scatter",
          });
        }
      });
      }
    }

    return traces;
  };

  // Layout configuration
  const layout = {
    showlegend: true,
    legend: {
      x: 0,
      y: -0.2,
      orientation: "h",
    },
    margin: { l: 50, r: 50, t: 50, b: 100 },
    hovermode: "closest",
    grid: { rows: 1, columns: 1, pattern: "independent" },
    ...(selectedGraph.type === "3d"
      ? {
          scene: {
            xaxis: { gridcolor: "#f0f0f0" },
            yaxis: { gridcolor: "#f0f0f0" },
            zaxis: { gridcolor: "#f0f0f0" },
          },
        }
      : {
          xaxis: { gridcolor: "#f0f0f0" },
          yaxis: { gridcolor: "#f0f0f0" },
        }),
  };

  // Config for plot interactions
  const config = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToAdd: ["toImage"],
    modeBarButtonsToRemove: ["lasso2d", "select2d"],
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="90vw">
          <ModalHeader>{selectedGraph.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH="600px">
            <Flex direction="column" gap={4}>
              {/* Controls */}
              <ButtonGroup size="sm" isAttached variant="outline">
                <Button onClick={() => setShowLabels(!showLabels)}>
                  {showLabels ? "Hide Sample Labels" : "Show Sample Labels"}
                </Button>
                {selectedGraph.type === "3d" && (
                  <Button
                    onClick={() => {
                      setDynamicLayout(getInitialLayout());
                    }}
                  >
                    Reset View
                  </Button>
                )}
                <Button
                  onClick={handleSaveForCertificate}
                  colorScheme="blue"
                  isLoading={isSaving}
                  variant="solid"
                >
                  Save for Certificate
                </Button>
              </ButtonGroup>
              <HStack>
                <FormControl>
                  <HStack spacing={4} align="center">
                    <FormLabel htmlFor="plotHeight" mb="0">
                      Plot Height
                    </FormLabel>
                    <Slider
                      id="plotHeight"
                      min={400}
                      max={1000}
                      step={50}
                      value={plotHeight}
                      onChange={setPlotHeight}
                      w="200px"
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                    <Text>{plotHeight}px</Text>
                  </HStack>
                </FormControl>
                <FormControl>
                  <HStack spacing={4} align="center">
                    <FormLabel htmlFor="pointSize" mb="0">
                      Data Point Size
                    </FormLabel>
                    <Slider
                      id="plotHeight"
                      min={1}
                      max={25}
                      step={1}
                      value={pointSize}
                      onChange={setPointSize}
                      w="200px"
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                    <Text>{pointSize}px</Text>
                  </HStack>
                </FormControl>
              </HStack>
              {/* Plot */}
              <Box h={plotHeight} ref={plotRef}>
                {!loading && (
                  <Plot
                    id="plot"
                    data={getPlotlyData()}
                    layout={dynamicLayout}
                    onRelayout={(update) => {
                      setDynamicLayout((prev) => ({ ...prev, ...update }));
                    }}
                    config={{
                      responsive: true,
                      displaylogo: false,
                      modeBarButtonsToAdd: ["toImage"],
                      modeBarButtonsToRemove: ["lasso2d", "select2d"],
                      toImageButtonOptions: {
                        format: "png",
                        filename: `plot_set_${selectedGraph.setId}`,
                        height: plotHeight,
                        width: plotRef.current?.clientWidth || 1200,
                        scale: 2,
                      },
                    }}
                    style={{ width: "100%", height: "100%" }}
                    useResizeHandler={true}
                  />
                )}
              </Box>

              {selectedGraph.plotting && (
  <Box borderWidth="1px" borderRadius="lg" p={4}>
    <VStack align="stretch" spacing={4}>
      <HStack spacing={6} justify="start">
        <HStack spacing={8}>
          {Object.keys(visibleTraces).map(traceName => (
            <FormControl
              key={traceName}
              display="flex"
              alignItems="center"
              size="sm"
              flexBasis="auto"
            >
              <FormLabel htmlFor={traceName} mb="0" mr={2} whiteSpace="nowrap">
                {traceName}
              </FormLabel>
              <Switch
                id={traceName}
                isChecked={visibleTraces[traceName]}
                onChange={(e) =>
                  setVisibleTraces(prev => ({
                    ...prev,
                    [traceName]: e.target.checked,
                  }))
                }
                colorScheme={
                  traceName.toLowerCase().includes('avg') 
                    ? "blue"
                    : "purple"
                }
              />
            </FormControl>
          ))}
        </HStack>
        {Object.keys(visibleControls).length > 0 && (
          <FormControl display="flex" alignItems="center" maxW="200px">
            <FormLabel htmlFor="controls" mb="0" mr={2}>
              Show Controls
            </FormLabel>
            <Switch
              id="controls"
              isChecked={showControls}
              onChange={(e) => {
                setShowControls(e.target.checked);
                if (!e.target.checked) {
                  // Reset all control visibilities when hiding controls
                  const resetControls = {};
                  Object.keys(visibleControls).forEach(key => {
                    resetControls[key] = false;
                  });
                  setVisibleControls(resetControls);
                }
              }}
              colorScheme="gray"
            />
          </FormControl>
        )}
      </HStack>

      {showControls && Object.keys(visibleControls).length > 0 && (
        <VStack align="stretch" spacing={2}>
          <Text fontSize="sm" fontWeight="medium">
            Control Files:
          </Text>
          <VStack align="stretch" spacing={1}>
            {Object.keys(visibleControls).map(controlName => (
              <FormControl
                key={controlName}
                display="flex"
                alignItems="center"
                size="sm"
              >
                <FormLabel htmlFor={`control-${controlName}`} mb="0" mr={2}>
                  {controlName}
                </FormLabel>
                <Switch
                  id={`control-${controlName}`}
                  isChecked={visibleControls[controlName]}
                  onChange={(e) =>
                    setVisibleControls(prev => ({
                      ...prev,
                      [controlName]: e.target.checked,
                    }))
                  }
                  colorScheme="gray"
                />
              </FormControl>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  </Box>
)}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsConfirmDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Overwrite Existing Graph
            </AlertDialogHeader>

            <AlertDialogBody>
              A certificate graph already exists for this set. Are you sure you
              want to overwrite it?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsConfirmDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={savePlotImage}
                ml={3}
                isLoading={isSaving}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PlotModal;
