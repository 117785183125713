import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Badge,
  Button,
  HStack,
  useDisclosure,
  Text,
  Tooltip,
  Flex,
  Select,
  Box,
} from '@chakra-ui/react';
import { MoreVertical, Plus, ChevronLeft, ChevronRight } from 'lucide-react';
import TableContainer from '../shared/TableContainer';
import CustomerForm from './CustomerForm';
import { biaColors } from '../../../styles/colors';

const CustomerTable = ({ 
  customers, 
  loading, 
  onUpdateCustomer, 
  onStatusChange,
  onCreateCustomer,
  isGlobalAdmin,
  currentUser,
  page,
  pageSize,
  totalCount,
  onPageChange,
  onPageSizeChange,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const { 
    isOpen: isFormOpen, 
    onOpen: onFormOpen, 
    onClose: onFormClose 
  } = useDisclosure();

  const handleEditClick = (customer) => {
    setSelectedCustomer(customer);
    onFormOpen();
  };

  const handleFormClose = () => {
    setSelectedCustomer(null);
    onFormClose();
  };

  const handleSubmit = async (values) => {
    let success;
    if (selectedCustomer) {
      success = await onUpdateCustomer(selectedCustomer.id, values);
    } else {
      success = await onCreateCustomer(values);
    }
    
    if (success) {
      handleFormClose();
    }
  };

  const canModifyCustomer = (customer) => {
    if (isGlobalAdmin) return true;
    if (currentUser?.roles?.includes('COMPANY_ADMIN') && customer.company_id === currentUser.company_id) return true;
    return false;
  };

  const getStatusBadge = (isActive) => (
    <Badge bg={isActive ? biaColors.customGreen : biaColors.customRed} color="white">
      {isActive ? 'Active' : 'Inactive'}
    </Badge>
  );

  const totalPages = Math.ceil(totalCount / pageSize);

  const renderPagination = () => (
    <Flex justify="space-between" align="center" mt={4}>
      <Box>
        <Text color="gray.600" fontSize="sm">
          Showing {((page - 1) * pageSize) + 1} to {Math.min(page * pageSize, totalCount)} of {totalCount} results
        </Text>
      </Box>
      <HStack spacing={4}>
        <Select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          size="sm"
          width="auto"
        >
          <option value={10}>10 per page</option>
          <option value={25}>25 per page</option>
          <option value={50}>50 per page</option>
        </Select>
        <HStack>
          <IconButton
            icon={<ChevronLeft size={16} />}
            onClick={() => onPageChange(page - 1)}
            isDisabled={page === 1}
            size="sm"
          />
          <Text fontSize="sm">
            Page {page} of {totalPages}
          </Text>
          <IconButton
            icon={<ChevronRight size={16} />}
            onClick={() => onPageChange(page + 1)}
            isDisabled={page === totalPages}
            size="sm"
          />
        </HStack>
      </HStack>
    </Flex>
  );

  return (
    <>
      <HStack justify="flex-end" mb={4}>
        <Button
          leftIcon={<Plus size={16} />}
          colorScheme="blue"
          onClick={() => {
            setSelectedCustomer(null);
            onFormOpen();
          }}
        >
          Add Customer
        </Button>
      </HStack>

      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead position="sticky" top={0} bg="white" zIndex={1}>
            <Tr>
              <Th>Name</Th>
              <Th>Contact Name</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              <Th>Address</Th>
              {isGlobalAdmin && <Th>Company</Th>}
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {customers.map((customer) => (
              <Tr key={customer.id}>
                <Td>{customer.name}</Td>
                <Td>{customer.contact_name}</Td>
                <Td>{customer.contact_email}</Td>
                <Td>{customer.contact_phone || '-'}</Td>
                <Td>
                  <Tooltip label={customer.address} placement="top">
                    <Text noOfLines={1} maxW="200px">
                      {customer.address}
                    </Text>
                  </Tooltip>
                </Td>
                {isGlobalAdmin && <Td>{customer.company_name}</Td>}
                <Td>{getStatusBadge(customer.is_active)}</Td>
                <Td>
                  {canModifyCustomer(customer) && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<MoreVertical size={16} />}
                        variant="ghost"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleEditClick(customer)}>
                          Edit Customer
                        </MenuItem>
                        {customer.is_active ? (
                          <MenuItem
                            color="red.500"
                            onClick={() => onStatusChange(customer.id, 'deactivate')}
                          >
                            Deactivate
                          </MenuItem>
                        ) : (
                          <MenuItem
                            color="green.500"
                            onClick={() => onStatusChange(customer.id, 'activate')}
                          >
                            Activate
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {renderPagination()}
      </TableContainer>

      <CustomerForm
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleSubmit}
        initialValues={selectedCustomer}
        isGlobalAdmin={isGlobalAdmin}
      />
    </>
  );
};

export default CustomerTable;