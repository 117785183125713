import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  Select,
  VStack,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Customer name is required')
    .min(2, 'Name must be at least 2 characters'),
  address: Yup.string()
    .required('Address is required')
    .min(5, 'Address must be at least 5 characters'),
  contact_name: Yup.string()
    .required('Contact name is required')
    .min(2, 'Contact name must be at least 2 characters'),
  contact_email: Yup.string()
    .email('Invalid email address')
    .required('Contact email is required'),
  contact_phone: Yup.string()
    .nullable(),
  company_id: Yup.string()
    .when('$isGlobalAdmin', {
      is: true,
      then: () => Yup.string().required('Company is required'),
    }),
});

const CustomerForm = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  initialValues = {}, 
  isGlobalAdmin 
}) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (isGlobalAdmin) {
      const fetchCompanies = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies`);
          setCompanies(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
      fetchCompanies();
    }
  }, [isGlobalAdmin]);

  const defaultValues = {
    name: '',
    address: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    company_id: '',
    ...Object.fromEntries(
      Object.entries(initialValues || {}).map(([key, value]) => [
        key,
        value === null ? '' : value
      ])
    )
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const success = await onSubmit(values);
            if (success) {
              onClose();
            }
            actions.setSubmitting(false);
          }}
          validateOnMount
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <ModalHeader>
                {initialValues?.id ? 'Edit Customer' : 'Add New Customer'}
              </ModalHeader>

              <ModalBody>
                <VStack spacing={4}>
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel>Customer Name</FormLabel>
                        <Input {...field} placeholder="Enter customer name" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="address">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.address && form.touched.address}>
                        <FormLabel>Address</FormLabel>
                        <Textarea 
                          {...field} 
                          placeholder="Enter complete address"
                          rows={4}
                        />
                        <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="contact_name">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.contact_name && form.touched.contact_name}>
                        <FormLabel>Contact Name</FormLabel>
                        <Input {...field} placeholder="Enter contact person's name" />
                        <FormErrorMessage>{form.errors.contact_name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="contact_email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.contact_email && form.touched.contact_email}>
                        <FormLabel>Contact Email</FormLabel>
                        <Input {...field} type="email" placeholder="Enter contact email" />
                        <FormErrorMessage>{form.errors.contact_email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="contact_phone">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.contact_phone && form.touched.contact_phone}>
                        <FormLabel>Contact Phone</FormLabel>
                        <Input {...field} placeholder="Enter contact phone number" />
                        <FormErrorMessage>{form.errors.contact_phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {isGlobalAdmin && (
                    <Field name="company_id">
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.company_id && form.touched.company_id}>
                          <FormLabel>Company</FormLabel>
                          <Select {...field} placeholder="Select company">
                            {companies.map(company => (
                              <option key={company.id} value={company.id}>
                                {company.name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>{form.errors.company_id}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  type="submit"
                  isLoading={isSubmitting}
                  isDisabled={!isValid}
                >
                  {initialValues?.id ? 'Save Changes' : 'Create Customer'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CustomerForm;