import React, { useState, useEffect } from "react";
import { Box, VStack, useToast } from "@chakra-ui/react";
import { useAuth } from "../../../context/AuthContext";
import UserTable from "./UserTable";
import UserFilters from "./UserFilters";
import axios from "axios";

const UserManagement = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    search: "",
    company: "",
    status: "ACTIVE",
    pageSize: 10,
    page: 1,
  });

  const isGlobalAdmin = user?.roles?.includes("GLOBAL_ADMIN");

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const params = {
        search: filters.search,
        status: filters.status,
        page: filters.page,
        per_page: filters.pageSize,
      };

      if (!isGlobalAdmin) {
        params.company_id = user.company_id;
      } else if (filters.company === "unassigned") {
        params.unassigned = true;
      } else if (filters.company) {
        params.company_id = filters.company;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users`,
        {
          params,
        }
      );

      setUsers(response.data);
    } catch (error) {
      toast({
        title: "Error fetching users",
        description: error.response?.data?.detail || "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [filters]);

  const handleUpdateUser = async (userId, updates) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
        updates
      );
      await fetchUsers();
      toast({
        title: "Success",
        description: "User updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || "Failed to update user",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStatusChange = async (userId, action) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/${userId}/${action}`
      );
      await fetchUsers();
      toast({
        title: "Success",
        description: `User ${action}d successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || `Failed to ${action} user`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={5} align="stretch">
      <UserFilters
        filters={filters}
        setFilters={setFilters}
        isGlobalAdmin={isGlobalAdmin}
      />
      <UserTable
        users={users}
        loading={loading}
        onUpdateUser={handleUpdateUser}
        onStatusChange={handleStatusChange}
        isGlobalAdmin={isGlobalAdmin}
        currentUser={user}
      />
    </VStack>
  );
};

export default UserManagement;
