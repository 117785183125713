import React, { useState, useEffect, useRef } from 'react';
import {
  HStack,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Search } from 'lucide-react';
import axios from 'axios';
import { debounce } from 'lodash';
import { useAuth } from '../../context/AuthContext';

const JobFilters = ({ filters, setFilters }) => {
  const [companies, setCompanies] = useState([]);
  const [localSearch, setLocalSearch] = useState(filters.search || '');
  const { user } = useAuth();
  const isGlobalAdmin = user?.roles?.includes("GLOBAL_ADMIN");
  const isCompanyAdmin = user?.roles?.includes("COMPANY_ADMIN");

  // Create a ref for the debounced function
  const debouncedSearch = useRef(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        search: value,
        page: 1 // Reset to first page on search
      }));
    }, 500)
  ).current;

  useEffect(() => {
    if (isGlobalAdmin) {
      const fetchCompanies = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies`);
          setCompanies(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
      fetchCompanies();
    }
  }, [isGlobalAdmin]);

  // Cleanup the debounced function
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleViewTypeChange = (newViewType) => {
    setFilters(prev => ({
      ...prev,
      view_type: newViewType,
      company_id: newViewType === 'all_jobs' ? prev.company_id : undefined,
      page: 1 // Reset to first page on view type change
    }));
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearch(value);
    debouncedSearch(value);
    setFilters(prev => ({ ...prev, search: value, page: 1 }));
  };

  return (
    <HStack spacing={4}>
      <InputGroup maxW="300px">
        <InputLeftElement>
          <Search size={20} />
        </InputLeftElement>
        <Input
          placeholder="Search jobs..."
          value={localSearch}
          onChange={handleSearchChange}
        />
      </InputGroup>

      <Select
        value={filters.view_type || 'my_jobs'}
        onChange={(e) => handleViewTypeChange(e.target.value)}
        maxW="200px"
      >
        <option value="my_jobs">My Jobs</option>
        {(isGlobalAdmin || isCompanyAdmin) && (
          <option value="company_jobs">Company Jobs</option>
        )}
        {isGlobalAdmin && (
          <option value="all_jobs">All Jobs</option>
        )}
      </Select>

      {isGlobalAdmin && filters.view_type === 'all_jobs' && (
        <Select
          value={filters.company_id || ''}
          onChange={(e) => setFilters(prev => ({ 
            ...prev, 
            company_id: e.target.value || undefined,
            page: 1 // Reset to first page on company change
          }))}
          maxW="250px"
        >
          <option value="">All Companies</option>
          {companies.map(company => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </Select>
      )}
    </HStack>
  );
};

export default JobFilters;