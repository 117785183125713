import axios from "axios";
import {biaColors} from "../styles/colors.jsx"

const apiUrl = process.env.REACT_APP_API_URL;
const api = axios;

export const fetchJobs = async (filters = {}) => {
  const params = {
    search: filters.search,
    view_type: filters.view_type,
    company_id: filters.company_id,
    page: filters.page,
    per_page: filters.per_page,
    sort_by: filters.sort_by,
    sort_desc: filters.sort_desc,
  };

  const response = await api.get(`${apiUrl}/api/job`, { params });
  return response.data;
};

export const fetchSets = async (jobId) => {
  const response = await api.get(`${apiUrl}/api/sets/${jobId}`);
  return response.data;
};

export const fetchCommodities = async () => {
  const response = await api.get(`${apiUrl}/api/commodities`);
  return response.data;
};

export const fetchModels = async () => {
  const response = await api.get(`${apiUrl}/api/models`);
  return response.data;
};

export const getDownloadUrl = async (type, setId) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/sets/download/${type}/${setId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPlotData = async (setId) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/sets/download/plot-data/${setId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSpectrogramData = async (setId, includeControls = false) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/sets/download/spectrogram-data/${setId}?include_controls=${includeControls}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getResultsData = async (setId) => {
  try {
    const response = await api.get(`${apiUrl}/api/sets/results-data/${setId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotes = async (setId) => {
  try {
    const response = await api.get(`${apiUrl}/api/sets/${setId}/notes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNoteCount = async (setId) => {
  try {
    const notes = await fetchNotes(setId);
    return notes.length;
  } catch (error) {
    console.error("Error fetching note count:", error);
    return 0;
  }
};

export const createNote = async (setId, noteData) => {
  try {
    const response = await api.post(`${apiUrl}/api/sets/${setId}/notes`, {
      ...noteData,
      set_id: setId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNote = async (setId, noteId, noteData) => {
  try {
    const response = await api.put(
      `${apiUrl}/api/sets/${setId}/notes/${noteId}`,
      noteData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNote = async (setId, noteId) => {
  try {
    await api.delete(`${apiUrl}/api/sets/${setId}/notes/${noteId}`);
  } catch (error) {
    throw error;
  }
};

export const updateCertGraphStatus = async (setId) => {
  try {
    const response = await api.put(`${apiUrl}/api/sets/cert-graph/${setId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUploadUrls = async (request) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/sets/upload/get-upload-urls`,
      request
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchCertificates = async (setId) => {
  const response = await api.get(`${apiUrl}/api/sets/certificates/${setId}`);
  return response.data;
};

export const generateCertificate = async (setId, certificateData) => {
  const response = await api.post(
    `${apiUrl}/api/sets/certificates/${setId}`,
    certificateData
  );
  return response.data;
};

export const getCertificateDownloadUrl = async (setId, certificateId, fileType) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/sets/certificates/download/${setId}/${certificateId}/${fileType}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCustomers = async (params = {}) => {
  const queryParams = {
    company_id: params.company_id,
    search: params.search,
    page: params.page,
    per_page: params.per_page
  };

  const response = await api.get(`${apiUrl}/api/customers`, { params: queryParams });
  return response.data;
};

export const getCertificateFormData = async (setId, version) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/sets/certificates/${setId}/form-data/${version}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const JOB_STATUS = {
  1: { label: "Created", color: "gray" },
  2: { label: "Processing", color: biaColors.customBlue },
  3: { label: "Complete", color: biaColors.customGreen },
  4: { label: "Errored", color: biaColors.customRed },
  5: { label: "Cancelled", color: "orange" },
  6: { label: "Complete with Errors", color: "yellow" },
};

export const SET_STATUS = {
  1: { label: "Created", color: "gray" },
  2: { label: "Averaging", color: "purple" },
  3: { label: "Predicting", color: biaColors.customBlue },
  4: { label: "Finalising", color: "teal" },
  5: { label: "Complete", color: biaColors.customGreen },
  6: { label: "Errored", color: biaColors.customRed },
};

// Helper method for parsing file dates (used in CertificateForm)
export const parseFileDate = (filename) => {
  if (!filename) return { date: new Date(), sampleId: "" };

  // Remove file extension and a/b/c suffix
  const baseName = filename.replace(/[abc]\.[^.]+$/, "");
  
  // Try to find a date pattern
  const datePattern = /\d{4}-\d{2}-\d{2}/;
  const dateMatch = baseName.match(datePattern);
  
  // Get sample ID (everything before the date or the whole name if no date)
  const sampleId = dateMatch ? 
    baseName.substring(0, dateMatch.index).trim() : 
    baseName.trim();

  return {
    date: dateMatch ? new Date(dateMatch[0]) : new Date(),
    sampleId,
  };
};

export const parseFilename = (filepath) => {
  if (!filepath) return { date: new Date(), sampleId: "" };

  // Extract just the filename from the path
  const filename = filepath.split("/").pop();

  // Remove file extension and a/b/c suffix
  const baseName = filename.replace(/[abc]\.[^.]+$/, "");

  // Array of date patterns to check
  const datePatterns = [
    {
      regex: /\d{4}-\d{2}-\d{2}/, // YYYY-MM-DD
      parser: (match) => new Date(match),
    },
    {
      regex: /(\d{2})\.(\d{2})\.(\d{2})/, // DD.MM.YY
      parser: (match, d, m, y) =>
        new Date(2000 + parseInt(y), parseInt(m) - 1, parseInt(d)),
    },
    {
      regex: /(\d{2})\.(\d{2})\.(\d{4})/, // DD.MM.YYYY
      parser: (match, d, m, y) => new Date(y, parseInt(m) - 1, parseInt(d)),
    },
    {
      regex: /(\d{2})-(\d{2})-(\d{4})/, // DD-MM-YYYY
      parser: (match, d, m, y) => new Date(y, parseInt(m) - 1, parseInt(d)),
    },
    {
      regex: /(\d{2})\/(\d{2})\/(\d{4})/, // DD/MM/YYYY
      parser: (match, d, m, y) => new Date(y, parseInt(m) - 1, parseInt(d)),
    },
    {
      regex: /(\d{2})\/(\d{2})\/(\d{2})/, // DD/MM/YY
      parser: (match, d, m, y) =>
        new Date(2000 + parseInt(y), parseInt(m) - 1, parseInt(d)),
    },
    {
      regex: /(\d{8})/, // YYYYMMDD
      parser: (match) =>
        new Date(
          match.slice(0, 4),
          parseInt(match.slice(4, 6)) - 1,
          match.slice(6, 8)
        ),
    },
  ];

  // Try each date pattern
  for (const pattern of datePatterns) {
    const dateMatch = baseName.match(pattern.regex);
    if (dateMatch) {
      try {
        const date = pattern.parser(...dateMatch);
        if (!isNaN(date.getTime())) {
          // Check if date is valid
          // Get sample ID (everything before the date)
          const sampleId = baseName.substring(0, dateMatch.index).trim();
          return { date, sampleId };
        }
      } catch (e) {
        // Continue to next pattern if parsing fails
        continue;
      }
    }
  }

  // If no valid date found, return today's date and use whole basename as sampleId
  return {
    date: new Date(),
    sampleId: baseName.trim(),
  };
};
