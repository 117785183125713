import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  Button,
  Textarea,
  Checkbox,
  Box,
  useToast,
  IconButton,
  Tooltip,
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Pencil, Trash2, InfoIcon } from 'lucide-react';
import {
  fetchNotes,
  createNote,
  updateNote,
  deleteNote,
} from '../../modules/JobApiService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { biaColors } from '../../styles/colors.jsx';

const NotesModal = ({ isOpen, onClose, setId, userId, userRoles, onNotesUpdate }) => {
  const [notes, setNotes] = useState([]);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [deletingNoteId, setDeletingNoteId] = useState(null);
  const toast = useToast();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const cancelRef = React.useRef();

  const loadNotes = async () => {
    try {
      const notesData = await fetchNotes(setId);
      setNotes(notesData);
      if (onNotesUpdate) {
        onNotesUpdate(notesData.length);
      }
    } catch (error) {
      toast({
        title: 'Error loading notes',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadNotes();
    }
  }, [isOpen, setId]);

  const noteFormik = useFormik({
    initialValues: {
      notes: '',
      include_in_cert: false,
    },
    validationSchema: Yup.object({
      notes: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await createNote(setId, values);
        await loadNotes();
        resetForm();
        toast({
          title: 'Note added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error adding note',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      notes: '',
      include_in_cert: false,
    },
    validationSchema: Yup.object({
      notes: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        await updateNote(setId, editingNoteId, values);
        await loadNotes();
        setEditingNoteId(null);
        toast({
          title: 'Note updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error updating note',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const handleDeleteConfirm = async () => {
    try {
      await deleteNote(setId, deletingNoteId);
      await loadNotes();
      onDeleteClose();
      setDeletingNoteId(null);
      toast({
        title: 'Note deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting note',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditClick = (note) => {
    editFormik.setValues({
      notes: note.notes,
      include_in_cert: note.include_in_cert,
    });
    setEditingNoteId(note.note_id);
  };

  const handleCertFlagChange = async (noteId, currentValue) => {
    try {
      await updateNote(setId, noteId, { notes: '', include_in_cert: !currentValue });
      await loadNotes();
      toast({
        title: 'Certificate flag updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating certificate flag',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const canEdit = (note) => {
    console.log('Note:', note);
    console.log('Current userId:', userId);
    console.log('Last note id:', notes[notes.length - 1]?.note_id);
    console.log('Is users note?', note.user_id === userId);
    console.log('Is last note?', note.note_id === notes[notes.length - 1]?.note_id);
    return note.user_id === userId && 
           note.note_id === notes[notes.length - 1]?.note_id;
  };

  const canDelete = (note) => {
    return note.user_id === userId || 
           userRoles.includes('GLOBAL_ADMIN') ||
           (userRoles.includes('COMPANY_ADMIN') && note.user_company_id === userId.company_id);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {notes.map((note) => (
                <Box
                  key={note.note_id}
                  p={4}
                  borderWidth={1}
                  borderRadius="md"
                  position="relative"
                >
                  {editingNoteId === note.note_id ? (
                    <form onSubmit={editFormik.handleSubmit}>
                      <VStack spacing={3} align="stretch">
                        <Textarea
                          name="notes"
                          value={editFormik.values.notes}
                          onChange={editFormik.handleChange}
                          isInvalid={editFormik.touched.notes && editFormik.errors.notes}
                        />
                        <HStack justify="space-between">
                          <HStack>
                            <Checkbox
                              name="include_in_cert"
                              isChecked={editFormik.values.include_in_cert}
                              onChange={editFormik.handleChange}
                            >
                              Include in certificate
                            </Checkbox>
                            <Tooltip label="When checked, this note will be included in the final certificate generation">
                              <InfoIcon size={16} />
                            </Tooltip>
                          </HStack>
                          <HStack>
                            <Button size="sm" onClick={() => setEditingNoteId(null)}>
                              Cancel
                            </Button>
                            <Button size="sm" colorScheme="blue" type="submit">
                              Save
                            </Button>
                          </HStack>
                        </HStack>
                      </VStack>
                    </form>
                  ) : (
                    <>
                      <Text fontSize="sm" color="gray.500" mb={1}>
                        {note.user_name} - {new Date(note.modified_at).toLocaleString()}
                      </Text>
                      <Text mb={3}>{note.notes}</Text>
                      <HStack justify="space-between">
                        <HStack>
                          <Checkbox
                            isChecked={note.include_in_cert}
                            onChange={() => handleCertFlagChange(note.note_id, note.include_in_cert)}
                            isDisabled={note.user_id !== userId}
                          >
                            Include in certificate
                          </Checkbox>
                          <Tooltip label="When checked, this note will be included in the final certificate generation">
                            <InfoIcon size={16} />
                          </Tooltip>
                        </HStack>
                        <HStack>
                          {canEdit(note) && (
                            <IconButton
                              icon={<Pencil size={16} />}
                              size="sm"
                              aria-label="Edit note"
                              onClick={() => handleEditClick(note)}
                            />
                          )}
                          {canDelete(note) && (
                            <IconButton
                              icon={<Trash2 size={16} />}
                              size="sm"
                              bg={biaColors.customRed}
                              color="white"
                              aria-label="Delete note"
                              onClick={() => {
                                setDeletingNoteId(note.note_id);
                                onDeleteOpen();
                              }}
                            />
                          )}
                        </HStack>
                      </HStack>
                    </>
                  )}
                </Box>
              ))}

              <Box mt={4}>
                <form onSubmit={noteFormik.handleSubmit}>
                  <VStack spacing={3} align="stretch">
                    <Textarea
                      name="notes"
                      placeholder="Add a new note..."
                      value={noteFormik.values.notes}
                      onChange={noteFormik.handleChange}
                      isInvalid={noteFormik.touched.notes && noteFormik.errors.notes}
                    />
                    <HStack justify="space-between">
                      <HStack>
                        <Checkbox
                          name="include_in_cert"
                          isChecked={noteFormik.values.include_in_cert}
                          onChange={noteFormik.handleChange}
                        >
                          Include in certificate
                        </Checkbox>
                        <Tooltip label="When checked, this note will be included in the final certificate generation">
                          <InfoIcon size={16} />
                        </Tooltip>
                      </HStack>
                      <Button type="submit" bg={biaColors.customBlue} color="white">
                        Add Note
                      </Button>
                    </HStack>
                  </VStack>
                </form>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete Note</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this note? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default NotesModal;