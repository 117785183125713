import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const RenameModal = ({
  isOpen,
  onClose,
  commodity,
  onSuccess,
  existingCommodities,
  apiUrl,
}) => {
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rename {commodity.name}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ name: commodity.name }}
          validationSchema={Yup.object({
            name: Yup.string()
              .required('Name is required')
              .test(
                'unique-name',
                'This name is already in use',
                (value) =>
                  !existingCommodities.some(
                    (c) =>
                      c.id !== commodity.id &&
                      c.name.toLowerCase() === value?.toLowerCase()
                  )
              ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await axios.patch(
                `${apiUrl}/api/commodities/${commodity.id}`,
                {
                  id: commodity.id,
                  name: values.name,
                  modelId: commodity.modelId,
                }
              );
              onSuccess();
              onClose();
              toast({
                title: 'Commodity renamed successfully',
                status: 'success',
                duration: 3000,
              });
            } catch (error) {
              toast({
                title: 'Error renaming commodity',
                description: error.response?.data?.detail || 'Something went wrong',
                status: 'error',
                duration: 3000,
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <ModalBody>
                <FormControl isInvalid={errors.name && touched.name}>
                  <FormLabel>New Name</FormLabel>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter new name"
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Save
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default RenameModal;