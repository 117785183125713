import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ModelDeleteModal = ({ 
  isOpen, 
  onClose, 
  model, 
  isModelAssigned, 
  onSuccess,
  apiUrl 
}) => {
  const toast = useToast();

  const deleteFormik = useFormik({
    initialValues: {
      confirmDelete: '',
    },
    validationSchema: Yup.object({
      confirmDelete: Yup.string()
        .required('Please type "delete" to confirm')
        .matches(/^delete$/, 'Please type "delete" to confirm'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!model) return;

      if (isModelAssigned(model.id)) {
        toast({
          title: "Error",
          description: "Cannot delete model as it is assigned to commodities",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      try {
        await axios.delete(`${apiUrl}/api/models/${model.id}`);

        toast({
          title: "Success",
          description: "Model deleted successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        onSuccess();
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Failed to delete model",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setSubmitting(false);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Model</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={deleteFormik.handleSubmit}>
          <ModalBody>
            {isModelAssigned(model?.id) ? (
              <div>
                This model cannot be deleted as it is assigned to commodities.
              </div>
            ) : (
              <FormControl
                isInvalid={
                  deleteFormik.touched.confirmDelete &&
                  deleteFormik.errors.confirmDelete
                }
              >
                <FormLabel>Type "delete" to confirm deletion</FormLabel>
                <Input
                  name="confirmDelete"
                  onChange={deleteFormik.handleChange}
                  value={deleteFormik.values.confirmDelete}
                  placeholder="delete"
                />
                <FormErrorMessage>
                  {deleteFormik.errors.confirmDelete}
                </FormErrorMessage>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              type="submit"
              isLoading={deleteFormik.isSubmitting}
              isDisabled={
                isModelAssigned(model?.id) ||
                deleteFormik.values.confirmDelete !== "delete"
              }
            >
              Delete
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ModelDeleteModal;