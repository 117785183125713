import React, { useState, useEffect } from 'react';
import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import ModelTable from './ModelTable';
import ModelUploadModal from './ModelUploadModal';
import axios from 'axios';

const ModelManagement = () => {
  const [models, setModels] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const { isOpen: isNewModelOpen, onOpen: onNewModelOpen, onClose: onNewModelClose } = useDisclosure();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchModels();
    fetchCommodities();
  }, []);

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/models`);
      setModels(response.data);
    } catch (error) {
      toast({
        title: "Error fetching models",
        status: "error",
        duration: 3000,
      });
    }
  };

  const fetchCommodities = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/commodities`);
      setCommodities(response.data);
    } catch (error) {
      toast({
        title: "Error fetching commodities",
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <Box>
      <Box mb={4}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          onClick={onNewModelOpen}
        >
          Add Model
        </Button>
      </Box>

      <ModelTable
        models={models}
        commodities={commodities}
        fetchModels={fetchModels}
        apiUrl={apiUrl}
      />

      <ModelUploadModal
        isOpen={isNewModelOpen}
        onClose={onNewModelClose}
        onSuccess={fetchModels}
        existingModels={models}
      />
    </Box>
  );
};

export default ModelManagement;