import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import RenameModal from './RenameModal';
import ChangeModelModal from './ChangeModelModal';
import DeleteControlModal from './DeleteControlModal';
import TableContainer from '../shared/TableContainer';

const CommodityTable = ({ commodities, models, onCommodityUpdate, apiUrl }) => {
  const [selectedCommodity, setSelectedCommodity] = useState(null);
  const {
    isOpen: isRenameOpen,
    onOpen: onRenameOpen,
    onClose: onRenameClose,
  } = useDisclosure();
  const {
    isOpen: isModelChangeOpen,
    onOpen: onModelChangeOpen,
    onClose: onModelChangeClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteControlOpen,
    onOpen: onDeleteControlOpen,
    onClose: onDeleteControlClose,
  } = useDisclosure();

  const handleAction = (commodity, action) => {
    setSelectedCommodity(commodity);
    switch (action) {
      case 'rename':
        onRenameOpen();
        break;
      case 'changeModel':
        onModelChangeOpen();
        break;
      case 'resetControl':
        onDeleteControlOpen();
        break;
      default:
        break;
    }
  };

  return (
    <>
     <TableContainer>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Has Valid Control</Th>
            <Th>Model</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {commodities.map((commodity) => (
            <Tr key={commodity.id}>
              <Td>{commodity.name}</Td>
              <Td>{commodity.hasValidControl ? "Yes" : "No"}</Td>
              <Td>
                {models.find((m) => m.id === commodity.modelId)?.name ||
                  "N/A"}
              </Td>
              <Td>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<ChevronDownIcon />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem
                      onClick={() => handleAction(commodity, 'rename')}
                    >
                      Rename
                    </MenuItem>
                    {commodity.hasValidControl && (
                      <MenuItem
                        onClick={() => handleAction(commodity, 'resetControl')}
                      >
                        Reset Control
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => handleAction(commodity, 'changeModel')}
                    >
                      Change Model
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      </TableContainer>

      {selectedCommodity && (
        <>
          <RenameModal
            isOpen={isRenameOpen}
            onClose={() => {
              onRenameClose();
              setSelectedCommodity(null);
            }}
            commodity={selectedCommodity}
            onSuccess={onCommodityUpdate}
            existingCommodities={commodities}
            apiUrl={apiUrl}
          />

          <ChangeModelModal
            isOpen={isModelChangeOpen}
            onClose={() => {
              onModelChangeClose();
              setSelectedCommodity(null);
            }}
            commodity={selectedCommodity}
            models={models}
            onSuccess={onCommodityUpdate}
            apiUrl={apiUrl}
          />

          <DeleteControlModal
            isOpen={isDeleteControlOpen}
            onClose={() => {
              onDeleteControlClose();
              setSelectedCommodity(null);
            }}
            commodity={selectedCommodity}
            onSuccess={onCommodityUpdate}
            apiUrl={apiUrl}
          />
        </>
      )}
    </>
  );
};

export default CommodityTable;