import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertCircle,
  Copy,
  TableIcon,
  MessageSquare,
  Activity,
  ShieldCheck,
  LineChart,
  Download,
} from "lucide-react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Spinner,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  Heading,
  SimpleGrid,
  ModalFooter,
  useClipboard,
  IconButton,
  Tooltip,
  ButtonGroup,
} from "@chakra-ui/react";
import { ChevronDownIcon, ArrowBackIcon } from "@chakra-ui/icons";
import {
  fetchSets,
  SET_STATUS,
  getDownloadUrl,
  fetchNoteCount,
} from "../modules/JobApiService";
import PlotModal from "./PlotModal";
import SpectrogramModal from "./SpectrogramModal";
import ResultsModal from "./ResultsModal";
import NotesModal from "./predictions/NotesModal";
import { useAuth } from "../context/AuthContext";
import CertificateModal from "./predictions/certificates/CertificateModal";
import {biaColors} from "../styles/colors.jsx";

const SetsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { jobId, jobName, commodityName, userName, raised, customerRef } =
    location.state || {};
  const toast = useToast();
  const [sets, setSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGraph, setSelectedGraph] = useState({
    type: null,
    setId: null,
  });
  const [isSpectrogramModalOpen, setIsSpectrogramModalOpen] = useState(false);
  const [selectedSetId, setSelectedSetId] = useState(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [selectedSetForNotes, setSelectedSetForNotes] = useState(null);
  const [noteCounts, setNoteCounts] = useState({});
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [selectedSetForCertificate, setSelectedSetForCertificate] =
    useState(null);
  const { user } = useAuth();
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const [resultsSetRefNo, setResultsSetRefNo] = useState(null);

  useEffect(() => {
    if (!jobId) {
      navigate("/portal/predictions");
    }
  }, [jobId, navigate]);

  const loadSets = async () => {
    try {
      const setsData = await fetchSets(jobId);
      setSets(setsData);
      setError(null);

      const counts = {};
      await Promise.all(
        setsData.map(async (set) => {
          if (set.statusId === 5) {
            // Only fetch for completed sets
            const count = await fetchNoteCount(set.id);
            counts[set.id] = count;
          }
        })
      );
      setNoteCounts(counts); // Update the noteCounts state
    } catch (err) {
      setError("Failed to load sets");
      toast({
        title: "Error loading sets",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSets();
    // Set up auto-refresh
    //const intervalId = setInterval(loadSets, 60000);
    //return () => clearInterval(intervalId);
  }, [jobId]);

  const handleDownloadFiles = async (type, set) => {
    try {
      const { url, filename } = await getDownloadUrl(type, set.id);

      // Use fetch to get the file and prevent navigation
      const response = await fetch(url);
      const blob = await response.blob();

      // Create object URL from blob
      const objectUrl = window.URL.createObjectURL(blob);

      // Create and click link
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      link.style.display = "none"; // Hide the link
      document.body.appendChild(link);
      link.click();

      // Cleanup
      setTimeout(() => {
        // Give browser time to start download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
      }, 100);
    } catch (error) {
      toast({
        title: "Download failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGenerateGraph = (type, set) => {
    setSelectedGraph({
      type,
      setId: set.id,
      jobId: jobId, // From page state
      setRefNo: set.setRefNo, // Directly from set object
      plotting: set.plotting,
      title: `${jobName} - Set: ${set.setRefNo} (${commodityName})`,
      certGraphSaved: set.cert_graph_saved,
    });
    onOpen();
  };

  const ResultCell = ({ scoreString, setId, setRefNo, statusId }) => {
    const [showFullDecimals, setShowFullDecimals] = React.useState(false);

    const canViewResults = statusId === 5;

    let scoreObj;
    try {
      scoreObj = scoreString ? JSON.parse(scoreString) : null;
    } catch (error) {
      console.error("Error parsing score JSON:", error);
      scoreObj = null;
    }

    return (
      <Box position="relative" width="100%">
        <VStack align="center" spacing={1}>
          {scoreObj ? (
            <Badge
              bg={
                scoreObj.authentic_score >= scoreObj.cutoff &&
                scoreObj.authentic_score <= scoreObj.upper_cutoff
                  ? biaColors.customGreen
                  : biaColors.customRed
              }
              color="white"
              p={1}
              borderRadius="md"
              cursor={canViewResults ? "pointer" : "default"}
              onClick={
                canViewResults ? () => setIsResultsModalOpen(true) : undefined
              }
              _hover={
                canViewResults
                  ? {
                      opacity: 0.8,
                      transform: "scale(1.05)",
                      transition: "all 0.2s ease-in-out",
                    }
                  : undefined
              }
            >
              {showFullDecimals
                ? scoreObj.authentic_score
                : scoreObj.authentic_score.toFixed(3)}
            </Badge>
          ) : (
            <Text fontSize="sm" align="center">
              -
            </Text>
          )}
          {scoreObj && (
            <Text fontSize="xs" color="gray.600">
              {scoreObj.cutoff.toFixed(2)} - {scoreObj.upper_cutoff.toFixed(2)}
            </Text>
          )}
        </VStack>

        {/* {canViewResults && (
          <Tooltip label="View Results">
            <Box
              position="absolute"
              right="-8"
              top="50%"
              transform="translateY(-50%)"
            >
              <Button
                size="xs"
                variant="ghost"
                onClick={() => setIsResultsModalOpen(true)}
                aria-label="View detailed results"
              >
                <TableIcon size={14} />
              </Button>
            </Box>
          </Tooltip>
        )} */}

        
      </Box>
    );
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  const ErrorModal = ({ isOpen, onClose, error }) => {
    const { hasCopied, onCopy } = useClipboard(error || "");

    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="red.500">Error Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              p={4}
              bg="gray.50"
              borderRadius="md"
              whiteSpace="pre-wrap"
              fontFamily="mono"
            >
              {error || "No error details available"}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button leftIcon={<Copy size={16} />} onClick={onCopy} mr={3}>
              {hasCopied ? "Copied!" : "Copy Error"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const StatusCell = ({ status, error }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isError = status === 6; // Error status ID is 6

    return (
      <>
        <Badge
          bg={SET_STATUS[status]?.color || "gray"}
          color="white"
          p={1}
          borderRadius="md"
          cursor={isError ? "pointer" : "default"}
          onClick={isError ? onOpen : undefined}
          _hover={
            isError
              ? {
                  opacity: 0.8,
                  transform: "scale(1.05)",
                  transition: "all 0.2s ease-in-out",
                }
              : undefined
          }
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          {SET_STATUS[status]?.label || "Unknown"}
          {isError && error && <AlertCircle size={14} />}
        </Badge>

        <ErrorModal isOpen={isOpen} onClose={onClose} error={error} />
      </>
    );
  };

  return (
    <Box p={4}>
      <HStack mb={6} spacing={4}>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/portal/predictions")}
          colorScheme="gray"
        >
          Back to Jobs
        </Button>
        <Heading size="lg">{jobName}</Heading>
      </HStack>

      <Box
        mb={6}
        p={4}
        borderWidth="1px"
        borderRadius="lg"
        backgroundColor={biaColors.customBlue}
        color="white"
      >
        <SimpleGrid columns={6} spacing={4}>
          <Box>
            <Text fontSize="sm" color="white" fontWeight="bold">
              Job ID
            </Text>
            <Text>{jobId}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="white" fontWeight="bold">
              Job Name
            </Text>
            <Text>{jobName}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="white" fontWeight="bold">
              Customer Reference
            </Text>
            <Text>{customerRef}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="white" fontWeight="bold">
              Commodity
            </Text>
            <Text>{commodityName}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="white" fontWeight="bold">
              Created By
            </Text>
            <Text>{userName}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="white" fontWeight="bold">
              Created On
            </Text>
            <Text>{new Date(raised).toLocaleDateString()}</Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textAlign="center">Reference</Th>
                <Th textAlign="center">Input Files</Th>
                <Th textAlign="center">Type</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Results</Th>
                {/* <Th textAlign="center">Notes</Th> */}
                <Th textAlign="center">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sets.map((set) => (
                <Tr key={set.id}>
                  <Td>
                    <Text fontSize="sm">{set.setRefNo}</Text>
                  </Td>
                  <Td>
                    <VStack align="start" spacing={1}>
                      {[set.inputFile1, set.inputFile2, set.inputFile3]
                        .filter(Boolean)
                        .map((file, index) => (
                          <Text key={index} fontSize="sm">
                            {file.split("/").pop()}
                          </Text>
                        ))}
                    </VStack>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      {set.isControl ? "Control" : "Sample"}
                    </Text>
                  </Td>
                  <Td>
                    <StatusCell status={set.statusId} error={set.error} />
                  </Td>
                  <Td>
                    <ResultCell
                      scoreString={set.score}
                      setId={set.id}
                      setRefNo={set.setRefNo}
                      statusId={set.statusId}
                    />
                  </Td>
                  <Td>
                    <ButtonGroup size="sm" variant="ghost" spacing={2}>
                      <Tooltip label="View Notes" hasArrow>
                        <IconButton
                          icon={
                            <Box position="relative">
                              <MessageSquare size={18} />
                              {noteCounts[set.id] > 0 && (
                                <Box
                                  position="absolute"
                                  top="-8px"
                                  right="-8px"
                                  bg="red.500"
                                  color="white"
                                  borderRadius="full"
                                  w="16px"
                                  h="16px"
                                  fontSize="xs"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  {noteCounts[set.id]}
                                </Box>
                              )}
                            </Box>
                          }
                          onClick={() => {
                            setSelectedSetForNotes(set);
                            setIsNotesModalOpen(true);
                          }}
                        />
                      </Tooltip>

                      <Menu>
                        <Tooltip label="Download Files" hasArrow>
                          <MenuButton
                            as={IconButton}
                            icon={<Download size={18} />}
                            variant="ghost"
                            size="sm"
                          />
                        </Tooltip>
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              handleDownloadFiles("input-files", set)
                            }
                          >
                            Input Files
                          </MenuItem>
                          {set.averageFile && (
                            <MenuItem
                              onClick={() =>
                                handleDownloadFiles("average", set)
                              }
                            >
                              Average File
                            </MenuItem>
                          )}
                          {set.statusId === 5 && (
                            <MenuItem
                              onClick={() =>
                                handleDownloadFiles("results", set)
                              }
                            >
                              Results File
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>

                      {set.statusId === 5 && (
                        <>
                          <Tooltip label="View Results" hasArrow>
                            <IconButton
                              icon={<TableIcon size={18} />}
                              onClick={() => {
                                setSelectedSetId(set.id);
                                setResultsSetRefNo(set.setRefNo);
                                setIsResultsModalOpen(true);
                              }}
                            />
                          </Tooltip>

                          {set.enableplotting && (
                            <Menu>
                              <Tooltip label="Generate Graphs" hasArrow>
                                <MenuButton
                                  as={IconButton}
                                  icon={<LineChart size={18} />}
                                  variant="ghost"
                                  size="sm"
                                />
                              </Tooltip>
                              <MenuList>
                                <MenuItem
                                  onClick={() => handleGenerateGraph("2d", set)}
                                >
                                  2D Graph
                                </MenuItem>
                                {set.plot3d && (
                                  <MenuItem
                                    onClick={() =>
                                      handleGenerateGraph("3d", set)
                                    }
                                  >
                                    3D Graph
                                  </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          )}

                          <Tooltip label="View Spectra" hasArrow>
                            <IconButton
                              icon={<Activity size={18} />}
                              onClick={() => {
                                setSelectedSetId(set.id);
                                setIsSpectrogramModalOpen(true);
                              }}
                            />
                          </Tooltip>

                          <Tooltip label="Manage Certificates" hasArrow>
                            <IconButton
                              icon={<ShieldCheck size={18} />}
                              onClick={() => {
                                setSelectedSetForCertificate(set);
                                setIsCertificateModalOpen(true);
                              }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </ButtonGroup>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/* Graph Modal */}
      <PlotModal
        isOpen={isOpen}
        onClose={onClose}
        selectedGraph={{
          ...selectedGraph,
          jobId: jobId,
          setRefNo: sets.find((s) => s.id === selectedGraph.setId)?.setRefNo,
          certGraphSaved: sets.find((s) => s.id === selectedGraph.setId)
            ?.cert_graph_saved,
        }}
        onSaveSuccess={loadSets}
      />

      {/* Spectrogram Modal */}
      <SpectrogramModal
        isOpen={isSpectrogramModalOpen}
        onClose={() => setIsSpectrogramModalOpen(false)}
        setId={selectedSetId}
      />

      {/*Notes Modal */}
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={() => {
          setIsNotesModalOpen(false);
          setSelectedSetForNotes(null);
        }}
        setId={selectedSetForNotes?.id}
        userId={user.userId}
        userRoles={user.roles}
        onNotesUpdate={(count) => {
          setNoteCounts((prev) => ({
            ...prev,
            [selectedSetForNotes?.id]: count,
          }));
        }}
      />

      {/*Certificate Modal */}
      <CertificateModal
        isOpen={isCertificateModalOpen}
        onClose={() => {
          setIsCertificateModalOpen(false);
          setSelectedSetForCertificate(null);
        }}
        set={selectedSetForCertificate}
        jobId={jobId}
        userRoles={user.roles}
      />

      {/* Results Modal */}
      <ResultsModal
          isOpen={isResultsModalOpen}
          onClose={() => setIsResultsModalOpen(false)}
          setId={selectedSetId}
          setRefNo={resultsSetRefNo}
        />
    </Box>

    
  );
};

export default SetsPage;
