import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  useDisclosure, 
  useToast,
  HStack,
  VStack
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';

import CompanyTable from './CompanyTable';
import CompanyDetails from './CompanyDetails';
import CompanyForm from './CompanyForm';
import { useAuth } from '../../../context/AuthContext';

const CompanyManagement = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const { user } = useAuth();
  const isGlobalAdmin = user?.roles?.includes("GLOBAL_ADMIN");
  
  const { 
    isOpen: isFormOpen, 
    onOpen: onFormOpen, 
    onClose: onFormClose 
  } = useDisclosure();

  const { 
    isOpen: isDetailsOpen, 
    onOpen: onDetailsOpen, 
    onClose: onDetailsClose 
  } = useDisclosure();

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      if (!user.company_id && !isGlobalAdmin) {
        setCompanies([]);
        return;
      }

      // For company admins, only fetch their company
      const url = isGlobalAdmin 
        ? `${process.env.REACT_APP_API_URL}/api/companies`
        : `${process.env.REACT_APP_API_URL}/api/companies/${user.company_id}`;

      const response = await axios.get(url);
      
      // For company admins, wrap the single company response in an array
      setCompanies(isGlobalAdmin ? response.data : [response.data]);
    } catch (error) {
      toast({
        title: 'Error fetching companies',
        description: error.response?.data?.detail || 'Failed to fetch companies',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [user]);

  const handleCreateCompany = async (data) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/companies`, data);
      fetchCompanies();
      onFormClose();
      toast({
        title: 'Success',
        description: 'Company created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.detail || 'Failed to create company',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const handleCompanyClick = async (company) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies/${company.id}`);
      setSelectedCompany(response.data);
      onDetailsOpen();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.detail || 'Failed to fetch company details',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const updateSelectedCompany = async () => {
    if (selectedCompany) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies/${selectedCompany.id}`);
        setSelectedCompany(response.data);
      } catch (error) {
        toast({
          title: 'Error',
          description: error.response?.data?.detail || 'Failed to refresh company details',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
    await fetchCompanies();
  };

  const handleStatusChange = async (companyId, action) => {
    if (!isGlobalAdmin) return;

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/${action}`
      );
      fetchCompanies();
      if (selectedCompany?.id === companyId) {
        setSelectedCompany(prev => ({ ...prev, is_active: action === 'reactivate' }));
      }
      toast({
        title: 'Success',
        description: `Company ${action}d successfully`,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.detail || `Failed to ${action} company`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <VStack spacing={5} align="stretch">
      {isGlobalAdmin && (
        <HStack justify="space-between">
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            onClick={onFormOpen}
          >
            Add Company
          </Button>
        </HStack>
      )}

      <CompanyTable
        companies={companies}
        loading={loading}
        onCompanyClick={handleCompanyClick}
        onStatusChange={handleStatusChange}
      />

      {isGlobalAdmin && (
        <CompanyForm
          isOpen={isFormOpen}
          onClose={onFormClose}
          onSubmit={handleCreateCompany}
        />
      )}

      {selectedCompany && (
        <CompanyDetails
          isOpen={isDetailsOpen}
          onClose={onDetailsClose}
          company={selectedCompany}
          onUpdate={updateSelectedCompany}
        />
      )}
    </VStack>
  );
};

export default CompanyManagement;