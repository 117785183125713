import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  Text,
  HStack,
  VStack,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Badge,
  Spinner
} from '@chakra-ui/react';
import { 
  Settings, 
  LogOut,
  ChevronDown,
  Building2,
  ShieldCheck
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { biaColors } from '../../styles/colors';
import { updatePassword } from 'aws-amplify/auth';
import axios from 'axios';

const UserProfile = () => {
  const { user, signOut } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  
  const { 
    isOpen: isPasswordOpen, 
    onOpen: onPasswordOpen, 
    onClose: onPasswordClose 
  } = useDisclosure();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/profile`
        );
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast({
          title: 'Error loading profile',
          status: 'error',
          duration: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.userId) {
      fetchProfile();
    }
  }, [user]);

  const [passwordForm, setPasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handlePasswordChange = async () => {
    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      toast({
        title: 'Passwords do not match',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    try {
      await updatePassword({
        oldPassword: passwordForm.oldPassword,
        newPassword: passwordForm.newPassword
      });
      
      toast({
        title: 'Password updated successfully',
        status: 'success',
        duration: 3000,
      });
      onPasswordClose();
      setPasswordForm({ oldPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      toast({
        title: 'Error updating password',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner size="sm" color={biaColors.customBlue} />;
  }

  if (!profileData) return null;

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDown size={16} />}
          variant="ghost"
          _hover={{ bg: 'gray.100' }}
        >
          <HStack spacing={3}>
            <Avatar
              size="sm"
              name={profileData.name}
              bg={biaColors.customBlue}
              color="white"
              fontWeight="600"
            />
            <VStack spacing={0} alignItems="flex-start">
              <Text fontSize="sm" fontWeight="medium">
                {profileData.name}
              </Text>
              <Text fontSize="xs" color="gray.500">
                {profileData.email}
              </Text>
            </VStack>
          </HStack>
        </MenuButton>
        
        <MenuList>
          <Box px={4} py={2}>
            <VStack align="stretch" spacing={2}>
              <HStack>
                <Building2 size={14} />
                <Text fontSize="sm">{profileData.company_name || 'No Company Assigned'}</Text>
              </HStack>
              <HStack>
                <ShieldCheck size={14} />
                <Box>
                  {profileData.roles.map(role => (
                    <Badge
                      key={role}
                      bg={biaColors.customBlue}
                      color="white"
                      mr={1}
                      fontSize="xs"
                    >
                      {role.toLowerCase().replace('_', ' ')}
                    </Badge>
                  ))}
                </Box>
              </HStack>
            </VStack>
          </Box>
          
          <MenuDivider />
          
          <MenuItem icon={<Settings size={16} />} onClick={onPasswordOpen}>
            Change Password
          </MenuItem>
          <MenuDivider />
          <MenuItem 
            icon={<LogOut size={16} />} 
            onClick={signOut}
            color={biaColors.customRed}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Password Change Modal */}
      <Modal isOpen={isPasswordOpen} onClose={onPasswordClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Current Password</FormLabel>
                <Input
                  type="password"
                  value={passwordForm.oldPassword}
                  onChange={(e) => setPasswordForm({
                    ...passwordForm,
                    oldPassword: e.target.value
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  value={passwordForm.newPassword}
                  onChange={(e) => setPasswordForm({
                    ...passwordForm,
                    newPassword: e.target.value
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Confirm New Password</FormLabel>
                <Input
                  type="password"
                  value={passwordForm.confirmPassword}
                  onChange={(e) => setPasswordForm({
                    ...passwordForm,
                    confirmPassword: e.target.value
                  })}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onPasswordClose}>
              Cancel
            </Button>
            <Button
              bg={biaColors.customBlue}
              color="white"
              _hover={{ bg: biaColors.customBlueHover }}
              onClick={handlePasswordChange}
              isLoading={isLoading}
              isDisabled={
                !passwordForm.oldPassword ||
                !passwordForm.newPassword ||
                !passwordForm.confirmPassword
              }
            >
              Update Password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserProfile;