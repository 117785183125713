import React, { useState, useEffect } from 'react';
import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import CommodityTable from './CommodityTable';
import NewCommodityModal from './NewCommodityModal';
import { biaColors } from '../../../styles/colors';

const CommodityManagement = () => {
  const [commodities, setCommodities] = useState([]);
  const [models, setModels] = useState([]);
  const {
    isOpen: isNewCommodityOpen,
    onOpen: onNewCommodityOpen,
    onClose: onNewCommodityClose,
  } = useDisclosure();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchCommodities = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/commodities`);
      setCommodities(response.data);
    } catch (error) {
      toast({
        title: "Error fetching commodities",
        status: "error",
        duration: 3000,
      });
    }
  };

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/models`);
      setModels(response.data);
    } catch (error) {
      toast({
        title: "Error fetching models",
        status: "error",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    fetchCommodities();
    fetchModels();
  }, []);

  return (
    <Box>
      <Box mb={4}>
        <Button
          leftIcon={<AddIcon />}
          bg={biaColors.customBlue}
          color="white"
          _hover={{bg: biaColors.customBlueHover}}
          onClick={onNewCommodityOpen}
        >
          Add Commodity
        </Button>
      </Box>

      <CommodityTable
        commodities={commodities}
        models={models}
        onCommodityUpdate={fetchCommodities}
        apiUrl={apiUrl}
      />

      <NewCommodityModal
        isOpen={isNewCommodityOpen}
        onClose={onNewCommodityClose}
        models={models}
        onSuccess={fetchCommodities}
        existingCommodities={commodities}
      />
    </Box>
  );
};

export default CommodityManagement;