import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { MoreVertical, Info } from 'lucide-react';
import TableContainer from '../shared/TableContainer';
import { biaColors } from '../../../styles/colors';

const CompanyTable = ({ 
  companies, 
  loading, 
  onCompanyClick, 
  onStatusChange 
}) => {
  const getStatusBadge = (isActive) => (
    <Badge bg={isActive ? biaColors.customGreen : biaColors.customRed} color="white">
      {isActive ? 'Active' : 'Inactive'}
    </Badge>
  );

  return (
    <TableContainer>
    <Table variant="simple" size="sm">
      <Thead position="sticky" top={0} bg="white" zIndex={1}>
        <Tr>
          <Th>Name</Th>
          <Th>Domain</Th>
          <Th>Contact</Th>
          <Th>Status</Th>
          <Th>Created</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {companies.map((company) => (
          <Tr key={company.id}>
            <Td>
              <Text 
                color="blue.600" 
                cursor="pointer"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => onCompanyClick(company)}
              >
                {company.name}
              </Text>
            </Td>
            <Td>{company.email_domain}</Td>
            <Td>
              {company.contact_name && (
                <Text fontSize="sm">
                  {company.contact_name}
                  {company.contact_email && (
                    <Text color="gray.500" as="span">
                      {' '}({company.contact_email})
                    </Text>
                  )}
                </Text>
              )}
            </Td>
            <Td>{getStatusBadge(company.is_active)}</Td>
            <Td>{new Date(company.created_at).toLocaleDateString()}</Td>
            <Td>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<MoreVertical size={16} />}
                  variant="ghost"
                  size="sm"
                />
                <MenuList>
                  <MenuItem 
                    icon={<Info size={16} />}
                    onClick={() => onCompanyClick(company)}
                  >
                    View Details
                  </MenuItem>
                  {company.is_active ? (
                    <MenuItem
                      color={biaColors.customRed}
                      onClick={() => onStatusChange(company.id, 'deactivate')}
                    >
                      Deactivate
                    </MenuItem>
                  ) : (
                    <MenuItem
                      color={biaColors.customGreen}
                      onClick={() => onStatusChange(company.id, 'reactivate')}
                    >
                      Reactivate
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
    </TableContainer>
  );
};

export default CompanyTable;