import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  useToast,
  Switch,
  FormControl,
  FormLabel,
  Box,
  Text,
  Card,
  CardBody,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Badge,
  List,
  ListItem,
  Divider,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  Select,
  UnorderedList,
  SimpleGrid,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist";
import { getSpectrogramData } from "../modules/JobApiService";
import SpiceAnalysis from "./SpiceAnalysis";
import SpectralAnalysisTab from "./SpectralAnalysisTab";
import { biaColors } from "../styles/colors";

// Advanced visualization options
const visualizationModes = {
  STANDARD: "standard",
  CONTOUR: "contour",
  WATERFALL: "waterfall",
  DIFFERENCE: "difference",
  FIRST_DERIVATIVE: "1st-derivative",
  DERIVATIVE: "2nd-derivative",
};

// Functional group database
const functionalGroups = {
  OH_STRETCH: {
    range: [3650, 3200],
    label: "O-H Stretch",
    description: "Indicates presence of alcohols, phenols, or carboxylic acids",
    foodContext:
      "Common in sugars, phenolic compounds in spices, and organic acids",
    intensity: "Usually strong and broad",
    interferents: "Water absorption can interfere in this region",
  },

  CARBOXYLIC_OH: {
    range: [3300, 2500],
    label: "O-H Stretch (Carboxylic)",
    description: "Specific to carboxylic acid O-H stretch",
    foodContext:
      "Present in organic acids like citric, malic, and ascorbic acid",
    intensity: "Broad, often overlapped with C-H stretching",
    interferents: "Can be masked by water absorption",
  },

  // Carbon-Hydrogen groups
  CH_STRETCH: {
    range: [2950, 2850],
    label: "C-H Stretch",
    description: "Alkane/alkyl groups presence",
    foodContext:
      "Indicates presence of lipids, fatty acids, and essential oils",
    intensity: "Usually strong",
    subtypes: {
      CH3: [2962, 2872],
      CH2: [2926, 2853],
    },
  },

  // Carbonyl groups
  CO_STRETCH: {
    range: [1750, 1735],
    label: "C=O Stretch",
    description: "Carbonyl groups, often in esters or acids",
    foodContext:
      "Important for detecting rancidity in oils, presence of esters in flavoring compounds",
    intensity: "Strong",
    subtypes: {
      Ester: [1750, 1735],
      Aldehyde: [1740, 1720],
      Ketone: [1725, 1705],
      CarboxylicAcid: [1720, 1700],
    },
  },

  // Aromatic structures
  CC_AROMATIC: {
    range: [1600, 1585],
    label: "C=C Aromatic",
    description: "Aromatic ring structures",
    foodContext: "Common in spices, herbs, and phenolic compounds",
    intensity: "Medium to strong",
    relatedPeaks: [1500, 1450, 750, 690],
  },

  // Nitrogen-containing groups
  NH_STRETCH: {
    range: [3500, 3300],
    label: "N-H Stretch",
    description: "Primary and secondary amines",
    foodContext: "Important for protein and amino acid analysis",
    intensity: "Medium",
    interferents: "Can overlap with O-H stretching",
  },

  AMIDE: {
    range: [1690, 1630],
    label: "Amide I (C=O stretch)",
    description: "Protein backbone structure",
    foodContext: "Primary indicator for protein content and structure",
    intensity: "Strong",
    relatedBands: {
      AmideII: [1560, 1530],
      AmideIII: [1300, 1230],
    },
  },

  // Carbon-based groups
  CH_BEND: {
    range: [1470, 1450],
    label: "C-H Bend",
    description: "Methylene groups",
    foodContext: "Present in fatty acids and hydrocarbons",
    intensity: "Medium",
    subtypes: {
      CH3: [1390, 1370],
      CH2: [1470, 1450],
    },
  },

  CO_SINGLE: {
    range: [1300, 1000],
    label: "C-O Single Bond",
    description: "Alcohols, ethers, esters",
    foodContext:
      "Important for carbohydrate analysis, particularly in studying sugars and starches",
    intensity: "Strong",
    specificBands: {
      "Primary Alcohol": [1050, 1030],
      "Secondary Alcohol": [1100, 1075],
      Ether: [1150, 1070],
      Ester: [1200, 1150],
    },
  },

  // Carbohydrate-specific
  GLYCOSIDIC: {
    range: [1150, 1050],
    label: "Glycosidic Links",
    description: "C-O-C bonds in carbohydrates",
    foodContext:
      "Essential for identifying different types of sugars and polysaccharides",
    intensity: "Strong",
    specificPatterns:
      "Pattern variations can distinguish different sugar types",
  },

  // Complex regions
  FINGERPRINT: {
    range: [1500, 600],
    label: "Fingerprint Region",
    description: "Complex mixture of various bending vibrations",
    foodContext:
      "Used for authentication and identification of specific spices/herbs",
    significance: "Unique pattern specific to each compound",
    analyticalUse: "Pattern recognition and chemometric analysis often applied",
  },
};

const SpectrogramModal = ({ isOpen, onClose, setId }) => {
  // State management
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showControls, setShowControls] = useState(false);
  const [isXAxisReversed, setIsXAxisReversed] = useState(true);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [visualizationMode, setVisualizationMode] = useState(
    visualizationModes.STANDARD
  );
  const [showPeakLabels, setShowPeakLabels] = useState(false);
  const [baselineCorrected, setBaselineCorrected] = useState(false);
  const [plotHeight, setPlotHeight] = useState(400);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [detectedGroups, setDetectedGroups] = useState([]);
  const [visibleTraces, setVisibleTraces] = useState({
    input1: true,
    input2: true,
    input3: true,
  });
  const [visibleControls, setVisibleControls] = useState({});
  const inputs = ["input1", "input2", "input3"];
  const [referenceSpectrum, setReferenceSpectrum] = useState("input1");
  const [showOriginalSpectra, setShowOriginalSpectra] = useState(false);
  const [showControlDifferences, setShowControlDifferences] = useState(false);

  const plotRef = useRef(null);
  const toast = useToast();

  const colors = {
    input1: "#2E7D32",
    input2: "#C62828",
    input3: "#1565C0",
    controls: "#9E9E9E",
    highlight: "rgba(255, 255, 0, 0.2)",
  };

  // Data processing utilities
  const dataProcessing = {
    correctBaseline: (data) => {
      // Simple baseline correction implementation
      const baseline =
        data.slice(0, 50).reduce((sum, point) => sum + point.y, 0) / 50;
      return data.map((point) => ({
        ...point,
        y: point.y - baseline,
      }));
    },
  };

  const handleReset = () => {
    setVisibleTraces({
      input1: true,
      input2: true,
      input3: true,
    });
    setShowControls(false);
    setShowPeakLabels(false); // Add this if you have the peak labels feature

    const resetControls = {};
    Object.keys(visibleControls).forEach((key) => {
      resetControls[key] = false;
    });
    setVisibleControls(resetControls);
  };

  // Analysis functions
  const detectFunctionalGroups = (spectralData) => {
    const detected = [];
    Object.entries(functionalGroups).forEach(([key, group]) => {
      const regionData = spectralData.filter(
        (point) => point.x >= group.range[1] && point.x <= group.range[0]
      );
      if (regionData.length > 0) {
        const avgIntensity =
          regionData.reduce((sum, point) => sum + point.y, 0) /
          regionData.length;
        const peaks = findPeaks(regionData);
        detected.push({
          name: group.label,
          range: group.range,
          description: group.description,
          foodContext: group.foodContext, // Added this
          intensity: avgIntensity,
          confidence: calculateConfidence(avgIntensity),
          peakCount: peaks.length,
          interferents: group.interferents, // Added this
          subtypes: group.subtypes, // Added this
          relatedPeaks: group.relatedPeaks, // Added this
        });
      }
    });
    return detected;
  };

  const findPeaks = (data, threshold = 0.1) => {
    const peaks = [];
    for (let i = 1; i < data.length - 1; i++) {
      if (
        data[i].y > data[i - 1].y &&
        data[i].y > data[i + 1].y &&
        data[i].y > threshold
      ) {
        peaks.push({ x: data[i].x, y: data[i].y });
      }
    }
    return peaks;
  };

  const calculateConfidence = (intensity) => {
    if (intensity > 0.5) return "High";
    if (intensity > 0.2) return "Medium";
    return "Low";
  };

  // Data fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getSpectrogramData(setId, showControls);
        setData(response);

        if (response?.input_data?.input1?.data) {
          const detected = detectFunctionalGroups(
            response.input_data.input1.data
          );
          setDetectedGroups(detected);
        }

        if (response?.control_data) {
          const controlStates = {};
          response.control_data.forEach((control) => {
            controlStates[control.filename] = false;
          });
          setVisibleControls(controlStates);
        } else {
          setVisibleControls({});
        }

        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load spectrogram data");
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && setId) {
      fetchData();
    }
  }, [setId, isOpen, showControls]);

  const processData = {
    // Standard view (already implemented)
    standard: (data) => {
      return data;
    },

    // Waterfall plot - offset multiple spectra vertically
    waterfall: (data, index = 0) => {
      const offset = index * 0.5; // Vertical offset for each spectrum
      return data.map((point) => ({
        ...point,
        y: point.y + offset,
      }));
    },

    // Difference view - subtract reference spectrum from sample
    difference: (sampleData, referenceData) => {
      if (!referenceData || !sampleData) return sampleData;

      // Ensure we have matching x values or interpolate
      return sampleData.map((point, i) => ({
        x: point.x,
        y: point.y - (referenceData[i]?.y || 0),
      }));
    },

    firstDerivative: (data) => {
      if (data.length < 2) return data;

      const derivative = [];
      for (let i = 1; i < data.length - 1; i++) {
        // Central difference formula for first derivative
        const d1 = (data[i + 1].y - data[i - 1].y) / 2;
        derivative.push({
          x: data[i].x,
          y: d1,
        });
      }
      return derivative;
    },

    // Second derivative - for enhanced peak resolution
    secondDerivative: (data) => {
      if (data.length < 3) return data;

      const windowSize = 5; // Savitzky-Golay window size
      const derivative = [];

      for (let i = 2; i < data.length - 2; i++) {
        // Simplified Savitzky-Golay second derivative
        const d2 =
          (2 * data[i - 2].y -
            data[i - 1].y -
            2 * data[i].y -
            data[i + 1].y +
            2 * data[i + 2].y) /
          4;
        derivative.push({
          x: data[i].x,
          y: d2,
        });
      }

      return derivative;
    },
  };

  // Plot configuration
  const getPlotData = () => {
    if (!data?.input_data?.input1?.data) {
      return [];
    }

    const plotData = [];

    const addPeakLabels = (inputData, color, offset = 0) => {
      if (showPeakLabels) {
        const peaks = findPeaks(inputData.data, 0.1);
        peaks.slice(0, 10).forEach((peak) => {
          plotData.push({
            x: [peak.x],
            y: [peak.y + offset],
            type: "scatter",
            mode: "markers+text",
            marker: {
              size: 8,
              color: color,
              symbol: "circle",
            },
            text: [peak.x.toFixed(0)],
            textposition: "top center",
            textfont: {
              size: 10,
              color: color,
            },
            hoverinfo: "x+y",
            showlegend: false,
            name: `Peak ${peak.x.toFixed(0)} cm⁻¹`,
          });
        });
      }
    };

    const addControlFiles = (yOffset = 0) => {
      if (showControls && Array.isArray(data.control_data)) {
        data.control_data.forEach((controlSet, index) => {
          if (
            Array.isArray(controlSet?.data) &&
            visibleControls[controlSet.filename]
          ) {
            const processedData =
              visualizationMode === visualizationModes.WATERFALL
                ? processData.waterfall(controlSet.data, index + inputs.length)
                : visualizationMode === visualizationModes.DERIVATIVE
                ? processData.secondDerivative(controlSet.data)
                : controlSet.data;

            plotData.push({
              x: processedData.map((point) => point.x),
              y: processedData.map((point) => point.y + yOffset),
              type: "scatter",
              mode: "lines",
              line: {
                color: colors.controls,
                width: 1,
                dash: "dot",
                opacity: 0.7,
              },
              name: controlSet.filename,
              showlegend: true,
            });
          }
        });
      }
    };

    switch (visualizationMode) {
      case visualizationModes.STANDARD:
        // Add standard traces with existing logic
        inputs.forEach((input, index) => {
          const inputData = data.input_data[input];
          if (visibleTraces[input] && Array.isArray(inputData?.data)) {
            plotData.push({
              x: inputData.data.map((point) => point.x),
              y: processData.standard(inputData.data).map((point) => point.y),
              type: "scatter",
              mode: "lines",
              line: { color: colors[input], width: 2 },
              name: inputData.filename || input,
            });
            addPeakLabels(inputData, colors[input]);
          }
        });
        addControlFiles();
        break;

      case visualizationModes.WATERFALL:
        // Stack multiple spectra with vertical offset
        inputs.forEach((input, index) => {
          const inputData = data.input_data[input];
          if (visibleTraces[input] && Array.isArray(inputData?.data)) {
            const offset = index * 0.5;
            plotData.push({
              x: inputData.data.map((point) => point.x),
              y: processData
                .waterfall(inputData.data, index)
                .map((point) => point.y),
              type: "scatter",
              mode: "lines",
              line: { color: colors[input], width: 2 },
              name: inputData.filename || input,
            });
            addPeakLabels(inputData, colors[input], offset);
          }
        });
        addControlFiles();
        break;

      case visualizationModes.DIFFERENCE:
        const referenceData = data.input_data[referenceSpectrum]?.data;

        // Show original spectra if enabled
        if (showOriginalSpectra) {
          inputs.forEach((input) => {
            const inputData = data.input_data[input];
            if (visibleTraces[input] && Array.isArray(inputData?.data)) {
              plotData.push({
                x: inputData.data.map((point) => point.x),
                y: inputData.data.map((point) => point.y),
                type: "scatter",
                mode: "lines",
                line: {
                  color: colors[input],
                  width: 1.5,
                  dash: "dot",
                },
                name: `Original (${inputData.filename || input})`,
              });
            }
          });
        }

        // Show differences for all non-reference spectra
        inputs.forEach((input) => {
          if (input !== referenceSpectrum) {
            const inputData = data.input_data[input];
            if (visibleTraces[input] && Array.isArray(inputData?.data)) {
              const diffData = processData.difference(
                inputData.data,
                referenceData
              );
              plotData.push({
                x: diffData.map((point) => point.x),
                y: diffData.map((point) => point.y),
                type: "scatter",
                mode: "lines",
                line: { color: colors[input], width: 2 },
                name: `Difference (${inputData.filename || input})`,
              });

              // Add peak labels for difference spectrum
              if (showPeakLabels) {
                const peaks = findPeaks(diffData, 0.1);
                peaks.slice(0, 10).forEach((peak) => {
                  plotData.push({
                    x: [peak.x],
                    y: [peak.y],
                    type: "scatter",
                    mode: "markers+text",
                    marker: {
                      size: 8,
                      color: colors[input],
                      symbol: "circle",
                    },
                    text: [peak.x.toFixed(0)],
                    textposition: "top center",
                    textfont: {
                      size: 10,
                      color: colors[input],
                    },
                    hoverinfo: "x+y",
                    showlegend: false,
                    name: `Peak ${peak.x.toFixed(0)} cm⁻¹`,
                  });
                });
              }
            }
          }
        });

        // Show control file differences if enabled
        if (
          showControlDifferences &&
          showControls &&
          Array.isArray(data.control_data)
        ) {
          data.control_data.forEach((controlSet, index) => {
            if (
              Array.isArray(controlSet?.data) &&
              visibleControls[controlSet.filename]
            ) {
              const diffData = processData.difference(
                controlSet.data,
                referenceData
              );
              plotData.push({
                x: diffData.map((point) => point.x),
                y: diffData.map((point) => point.y),
                type: "scatter",
                mode: "lines",
                line: {
                  color: colors.controls,
                  width: 1,
                  dash: "dot",
                  opacity: 0.7,
                },
                name: `Difference (${controlSet.filename})`,
                showlegend: true,
              });
            }
          });
        }
        break;

      case visualizationModes.FIRST_DERIVATIVE:
        // Show first derivative for rate of change analysis
        inputs.forEach((input) => {
          const inputData = data.input_data[input];
          if (visibleTraces[input] && Array.isArray(inputData?.data)) {
            const derivativeData = processData.firstDerivative(inputData.data);
            plotData.push({
              x: derivativeData.map((point) => point.x),
              y: derivativeData.map((point) => point.y),
              type: "scatter",
              mode: "lines",
              line: { color: colors[input], width: 2 },
              name: `1st Derivative (${inputData.filename || input})`,
            });
            addPeakLabels({ data: derivativeData }, colors[input]);
          }
        });
        addControlFiles();
        break;

      case visualizationModes.DERIVATIVE:
        // Show second derivative for enhanced peak resolution
        inputs.forEach((input) => {
          const inputData = data.input_data[input];
          if (visibleTraces[input] && Array.isArray(inputData?.data)) {
            const derivativeData = processData.secondDerivative(inputData.data);
            plotData.push({
              x: derivativeData.map((point) => point.x),
              y: derivativeData.map((point) => point.y),
              type: "scatter",
              mode: "lines",
              line: { color: colors[input], width: 2 },
              name: `2nd Derivative (${inputData.filename || input})`,
            });
            addPeakLabels({ data: derivativeData }, colors[input]);
          }
        });
        addControlFiles();
        break;

      default:
        // Fall back to standard view if mode is not recognized
        console.warn(
          `Unrecognized visualization mode: ${visualizationMode}. Falling back to standard view.`
        );
        inputs.forEach((input, index) => {
          const inputData = data.input_data[input];
          if (visibleTraces[input] && Array.isArray(inputData?.data)) {
            plotData.push({
              x: inputData.data.map((point) => point.x),
              y: processData.standard(inputData.data).map((point) => point.y),
              type: "scatter",
              mode: "lines",
              line: { color: colors[input], width: 2 },
              name: inputData.filename || input,
            });
            addPeakLabels(inputData, colors[input]);
          }
        });
        addControlFiles();
        break;
    }

    if (selectedRegion) {
      const allYValues = plotData.flatMap((trace) =>
        trace.visible !== false ? trace.y || [] : []
      );

      // Calculate min and max with 10% padding
      const maxY = Math.max(...allYValues) * 1.1; // Add 10% padding on top
      const minY = Math.min(...allYValues) * 1.1;

      plotData.push({
        x: [
          selectedRegion.range[0],
          selectedRegion.range[0],
          selectedRegion.range[1],
          selectedRegion.range[1],
        ],
        y: [minY, maxY, maxY, minY],
        fill: "toself",
        fillcolor: colors.highlight,
        type: "scatter",
        mode: "none",
        name: selectedRegion.label,
        showlegend: true,
      });
    }

    return plotData;
  };

  // Modified layout configuration based on visualization mode
  const getLayout = () => {
    const baseLayout = {
      ...layout,
      /* title: {
        text:
          visualizationMode === visualizationModes.STANDARD
            ? "Standard View"
            : visualizationMode === visualizationModes.WATERFALL
            ? "Waterfall Plot"
            : visualizationMode === visualizationModes.DIFFERENCE
            ? "Difference Spectrum"
            : visualizationMode === visualizationModes.DERIVATIVE
            ? "2nd Derivative"
            : "",
        font: { size: 16 },
      }, */
    };

    // Adjust y-axis title based on mode
    baseLayout.yaxis.title =
      visualizationMode === visualizationModes.DERIVATIVE
        ? "d²A/dv²"
        : visualizationMode === visualizationModes.FIRST_DERIVATIVE
        ? "dA/dv"
        : visualizationMode === visualizationModes.DIFFERENCE
        ? "ΔAbsorbance"
        : "Absorbance";

    return baseLayout;
  };

  // Layout and configuration for plot
  const layout = {
    autosize: true,
    height: plotHeight,
    margin: { l: 50, r: 50, t: 30, b: 50 }, // Fixed margin
    plot_bgcolor: "#fff",
    paper_bgcolor: "#fff",
    xaxis: {
      showgrid: true,
      gridcolor: "#eee",
      zeroline: false,
      autorange: isXAxisReversed ? "reversed" : true,
      title: "Wavenumber (cm⁻¹)",
    },
    yaxis: {
      showgrid: true,
      gridcolor: "#eee",
      zeroline: false,
      title: "Absorbance",
    },
    modebar: {
      orientation: "v",
      bgcolor: "transparent",
      color: "#333", // Add this to ensure buttons are visible
      activecolor: "#000", // Add this for hover state
    },
    showlegend: false,
    legend: {
      x: 1,
      xanchor: "right",
      y: 1,
      bgcolor: "rgba(255, 255, 255, 0.9)",
      bordercolor: "#ddd",
      borderwidth: 1,
    },
  };

  const config = {
    responsive: true,
    displaylogo: false,
    displayModeBar: true,
    modeBarPosition: "top-right",
    toImageButtonOptions: {
      format: "png",
      filename: "spectrogram",
      height: plotHeight,
      width: plotRef.current?.clientWidth || 1200,
      scale: 2,
    },
    modeBarButtonsToAdd: [
      "zoom2d",
      "pan2d",
      "zoomIn2d",
      "zoomOut2d",
      "autoScale2d",
      "resetScale2d",
    ],
    scrollZoom: true,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent maxW="95vw" h="95vh">
        <ModalHeader>Spectrogram View</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} overflow="hidden">
          <Flex h="full" gap={4}>
            <VStack
              spacing={4}
              align="stretch"
              flex={showAnalysis ? 2 : 1}
              h="full"
              overflow="auto"
            >
              <Box w="full" p={4}>
                <HStack
                  spacing={4}
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <HStack spacing={4}>
                    {/* Display Options Group */}
                    <Box borderWidth="1px" borderRadius="md" p={3}>
                      <HStack spacing={3}>
                        {/* Each toggle gets its own box */}
                        <Box
                          borderWidth="1px"
                          borderRadius="md"
                          p={2}
                          bg="gray.50"
                          _hover={{ bg: "gray.100" }}
                        >
                          <FormControl
                            display="flex"
                            alignItems="center"
                            size="sm"
                          >
                            <FormLabel htmlFor="reverseAxis" mb="0" mr={2}>
                              Reverse X-Axis
                            </FormLabel>
                            <Switch
                              id="reverseAxis"
                              isChecked={isXAxisReversed}
                              onChange={(e) =>
                                setIsXAxisReversed(e.target.checked)
                              }
                              colorScheme="purple"
                            />
                          </FormControl>
                        </Box>

                        <Box
                          borderWidth="1px"
                          borderRadius="md"
                          p={2}
                          bg="gray.50"
                          _hover={{ bg: "gray.100" }}
                        >
                          <FormControl
                            display="flex"
                            alignItems="center"
                            size="sm"
                          >
                            <FormLabel htmlFor="showAnalysis" mb="0" mr={2}>
                              Show Analysis
                            </FormLabel>
                            <Switch
                              id="showAnalysis"
                              isChecked={showAnalysis}
                              onChange={(e) =>
                                setShowAnalysis(e.target.checked)
                              }
                              colorScheme="blue"
                            />
                          </FormControl>
                        </Box>
                        {visualizationMode !==
                          visualizationModes.DIFFERENCE && (
                          <Box
                            borderWidth="1px"
                            borderRadius="md"
                            p={2}
                            bg="gray.50"
                            _hover={{ bg: "gray.100" }}
                          >
                            <FormControl
                              display="flex"
                              alignItems="center"
                              size="sm"
                            >
                              <FormLabel htmlFor="showPeakLabels" mb="0" mr={2}>
                                Peak Labels
                              </FormLabel>
                              <Switch
                                id="showPeakLabels"
                                isChecked={showPeakLabels}
                                onChange={(e) =>
                                  setShowPeakLabels(e.target.checked)
                                }
                                colorScheme="green"
                              />
                            </FormControl>
                          </Box>
                        )}
                      </HStack>
                    </Box>

                    {/* Visualization Mode Select */}
                    <Box borderWidth="1px" borderRadius="md" p={3}>
                      <Select
                        size="sm"
                        width="200px"
                        value={visualizationMode}
                        onChange={(e) => setVisualizationMode(e.target.value)}
                      >
                        <option value={visualizationModes.STANDARD}>
                          Standard View
                        </option>
                        <option value={visualizationModes.WATERFALL}>
                          Waterfall Plot
                        </option>
                        <option value={visualizationModes.DIFFERENCE}>
                          Difference View
                        </option>
                        <option value={visualizationModes.FIRST_DERIVATIVE}>
                          1st Derivative
                        </option>
                        <option value={visualizationModes.DERIVATIVE}>
                          2nd Derivative
                        </option>
                      </Select>
                    </Box>
                  </HStack>

                  {/* Action Buttons */}
                  <Box borderWidth="1px" borderRadius="md" p={3}>
                    <HStack spacing={2}>
                      <Button onClick={() => handleReset()} size="sm">
                        Reset View
                      </Button>
                      <Button
                        size="sm"
                        leftIcon={<DownloadIcon />}
                        onClick={() => {
                          const plotElement =
                            document.querySelector(".js-plotly-plot");
                          if (plotElement) {
                            Plotly.downloadImage(plotElement, {
                              format: "png",
                              width: plotElement.clientWidth,
                              height: plotElement.clientHeight,
                              filename: "spectrogram",
                              scale: 2,
                            });
                          }
                        }}
                      >
                        Download PNG
                      </Button>
                    </HStack>
                  </Box>
                </HStack>
              </Box>

              <Box borderWidth="1px" borderRadius="lg" p={4} ref={plotRef}>
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <HStack spacing={4} align="center">
                      <FormLabel htmlFor="plotHeight" mb="0">
                        Plot Height
                      </FormLabel>
                      <Slider
                        id="plotHeight"
                        min={400}
                        max={1000}
                        step={50}
                        value={plotHeight}
                        onChange={setPlotHeight}
                        w="200px"
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                      <Text>{plotHeight}px</Text>
                    </HStack>
                  </FormControl>

                  {loading ? (
                    <Box
                      height={plotHeight}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Loading...
                    </Box>
                  ) : error ? (
                    <Box
                      height={plotHeight}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {error}
                    </Box>
                  ) : (
                    <Plot
                      data={getPlotData()}
                      layout={getLayout()}
                      config={config}
                      style={{ width: "100%" }}
                    />
                  )}
                  {visualizationMode === visualizationModes.DIFFERENCE && (
                    <Box borderWidth="1px" borderRadius="lg" p={4} mt={4}>
                      <VStack align="stretch" spacing={4}>
                        <Text fontWeight="bold">Difference View Settings</Text>

                        <FormControl>
                          <FormLabel>Reference Spectrum</FormLabel>
                          <Select
                            value={referenceSpectrum}
                            onChange={(e) =>
                              setReferenceSpectrum(e.target.value)
                            }
                          >
                            {inputs.map((input) => (
                              <option key={input} value={input}>
                                {data?.input_data?.[input]?.filename || input}
                              </option>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="showOriginal" mb="0">
                            Show Original Spectra
                          </FormLabel>
                          <Switch
                            id="showOriginal"
                            isChecked={showOriginalSpectra}
                            onChange={(e) =>
                              setShowOriginalSpectra(e.target.checked)
                            }
                          />
                        </FormControl>

                        {showControls && (
                          <FormControl display="flex" alignItems="center">
                            <FormLabel htmlFor="showControlDiff" mb="0">
                              Show Control Differences
                            </FormLabel>
                            <Switch
                              id="showControlDiff"
                              isChecked={showControlDifferences}
                              onChange={(e) =>
                                setShowControlDifferences(e.target.checked)
                              }
                            />
                          </FormControl>
                        )}
                      </VStack>
                    </Box>
                  )}
                </VStack>
              </Box>

              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <VStack align="stretch" spacing={4}>
                  <HStack spacing={6} justify="start">
                    <HStack spacing={8}>
                      {["input1", "input2", "input3"].map((input) => (
                        <FormControl
                          key={input}
                          display="flex"
                          alignItems="center"
                          size="sm"
                          flexBasis="auto"
                        >
                          <FormLabel
                            htmlFor={input}
                            mb="0"
                            mr={2}
                            whiteSpace="nowrap"
                          >
                            {data?.input_data?.[input]?.filename ||
                              `Input ${input.slice(-1)}`}
                          </FormLabel>
                          <Switch
                            id={input}
                            isChecked={visibleTraces[input]}
                            onChange={(e) =>
                              setVisibleTraces((prev) => ({
                                ...prev,
                                [input]: e.target.checked,
                              }))
                            }
                            colorScheme={
                              input === "input1"
                                ? "green"
                                : input === "input2"
                                ? "red"
                                : "blue"
                            }
                          />
                        </FormControl>
                      ))}
                    </HStack>
                    <FormControl
                      display="flex"
                      alignItems="center"
                      maxW="200px"
                    >
                      <FormLabel htmlFor="controls" mb="0" mr={2}>
                        Show Controls
                      </FormLabel>
                      <Switch
                        id="controls"
                        isChecked={showControls}
                        onChange={(e) => {
                          setShowControls(e.target.checked);
                          if (!e.target.checked) {
                            const resetControls = {};
                            Object.keys(visibleControls).forEach((key) => {
                              resetControls[key] = false;
                            });
                            setVisibleControls(resetControls);
                          }
                        }}
                        colorScheme="gray"
                      />
                    </FormControl>
                  </HStack>
                  {showControls &&
                    data?.control_data &&
                    data.control_data.length > 0 && (
                      <VStack align="stretch" spacing={2}>
                        <Text fontSize="sm" fontWeight="medium">
                          Control Files:
                        </Text>
                        <VStack align="stretch" spacing={1}>
                          {data.control_data.map((control, index) => (
                            <FormControl
                              key={control.filename}
                              display="flex"
                              alignItems="center"
                              size="sm"
                            >
                              <FormLabel
                                htmlFor={`control-${index}`}
                                mb="0"
                                mr={2}
                              >
                                {control.filename}
                              </FormLabel>
                              <Switch
                                id={`control-${index}`}
                                isChecked={
                                  visibleControls[control.filename] || false
                                }
                                onChange={(e) =>
                                  setVisibleControls((prev) => ({
                                    ...prev,
                                    [control.filename]: e.target.checked,
                                  }))
                                }
                                colorScheme="gray"
                              />
                            </FormControl>
                          ))}
                        </VStack>
                      </VStack>
                    )}
                </VStack>
              </Box>
            </VStack>

            {showAnalysis && (
              <Box
                flex={1}
                overflowY="auto"
                maxH="full"
                borderLeft="1px"
                borderColor="gray.200"
                pl={4}
              >
                <Tabs isLazy>
                  <TabList>
                    <Tab>Functional Groups</Tab>
                    <Tab>Peak Analysis</Tab>
                    <Tab>Spectral Analysis</Tab>
                    <Tab>Quality Metrics</Tab>
                    <Tab>Spice Markers</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          <VStack align="stretch" spacing={4}>
                            <Text fontWeight="bold" mb={2}>
                              Functional Group Analysis
                            </Text>
                            {detectedGroups.map((group, index) => (
                              <Box
                                key={index}
                                p={4}
                                borderWidth="1px"
                                borderRadius="md"
                                cursor="pointer"
                                onClick={() =>
                                  setSelectedRegion({
                                    range: group.range,
                                    label: group.label,
                                  })
                                }
                                _hover={{ bg: "gray.50" }}
                              >
                                {/* Header Section */}
                                <HStack justify="space-between" mb={3}>
                                  <VStack align="start" spacing={1}>
                                    <Text fontWeight="bold">{group.name}</Text>
                                    <Text fontSize="sm" color="gray.600">
                                      {group.label}
                                    </Text>
                                  </VStack>
                                  <Badge
                                    bg={
                                      group.confidence === "High"
                                        ? biaColors.customGreen
                                        : group.confidence === "Medium"
                                        ? "yellow"
                                        : biaColors.customRed
                                    }
                                    color="white"
                                  >
                                    {group.confidence}
                                  </Badge>
                                </HStack>

                                {/* Main Content Grid */}
                                <SimpleGrid
                                  columns={{ base: 1, md: 2 }}
                                  spacing={4}
                                  mb={3}
                                >
                                  {/* Left Column */}
                                  <VStack align="start" spacing={2}>
                                    <Text fontSize="sm">
                                      <Text as="span" fontWeight="medium">
                                        Range:
                                      </Text>{" "}
                                      {group.range[1]} - {group.range[0]} cm⁻¹
                                    </Text>
                                    <Text fontSize="sm">
                                      <Text as="span" fontWeight="medium">
                                        Peak count:
                                      </Text>{" "}
                                      {group.peakCount}
                                    </Text>
                                    <Text fontSize="sm">
                                      <Text as="span" fontWeight="medium">
                                        Intensity:
                                      </Text>{" "}
                                      {group.intensity || "Not specified"}
                                    </Text>
                                  </VStack>

                                  {/* Right Column */}
                                  <VStack align="start" spacing={2}>
                                    {group.interferents && (
                                      <Text fontSize="sm">
                                        <Text as="span" fontWeight="medium">
                                          Interferents:
                                        </Text>{" "}
                                        {group.interferents}
                                      </Text>
                                    )}
                                    {group.subtypes && (
                                      <Box fontSize="sm">
                                        <Text fontWeight="medium" mb={1}>
                                          Subtypes:
                                        </Text>
                                        {Object.entries(group.subtypes).map(
                                          ([name, range]) => (
                                            <Text key={name} ml={2}>
                                              {name}: {range[1]} - {range[0]}{" "}
                                              cm⁻¹
                                            </Text>
                                          )
                                        )}
                                      </Box>
                                    )}
                                  </VStack>
                                </SimpleGrid>

                                {/* Description and Context */}
                                <VStack align="start" spacing={2} mt={2}>
                                  <Text fontSize="sm">
                                    <Text as="span" fontWeight="medium">
                                      Description:
                                    </Text>{" "}
                                    {group.description}
                                  </Text>
                                  <Text fontSize="sm">
                                    <Text as="span" fontWeight="medium">
                                      Food Context:
                                    </Text>{" "}
                                    {group.foodContext}
                                  </Text>
                                  {group.relatedPeaks && (
                                    <Text fontSize="sm">
                                      <Text as="span" fontWeight="medium">
                                        Related Peaks:
                                      </Text>{" "}
                                      {Array.isArray(group.relatedPeaks)
                                        ? group.relatedPeaks.join(", ") +
                                          " cm⁻¹"
                                        : Object.entries(group.relatedPeaks)
                                            .map(
                                              ([name, range]) =>
                                                `${name}: ${range[1]}-${range[0]} cm⁻¹`
                                            )
                                            .join(", ")}
                                    </Text>
                                  )}
                                </VStack>
                              </Box>
                            ))}
                          </VStack>
                        </CardBody>
                      </Card>
                    </TabPanel>

                    <TabPanel>
                      <Card>
                        <CardBody>
                          <VStack align="stretch" spacing={4}>
                            <Text fontWeight="bold" mb={2}>
                              Peak Analysis
                            </Text>
                            <TableContainer>
                              <Table variant="simple" size="sm">
                                <Thead>
                                  <Tr>
                                    <Th>Wavenumber (cm⁻¹)</Th>
                                    <Th>Intensity</Th>
                                    <Th>Assignment</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {data?.input_data?.input1?.data &&
                                    findPeaks(data.input_data.input1.data)
                                      .slice(0, 10)
                                      .map((peak, index) => (
                                        <Tr key={index}>
                                          <Td>{peak.x.toFixed(1)}</Td>
                                          <Td>{peak.y.toFixed(3)}</Td>
                                          <Td>
                                            {Object.entries(
                                              functionalGroups
                                            ).find(
                                              ([_, group]) =>
                                                peak.x >= group.range[1] &&
                                                peak.x <= group.range[0]
                                            )?.[1]?.label || "-"}
                                          </Td>
                                        </Tr>
                                      ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </VStack>
                        </CardBody>
                      </Card>
                    </TabPanel>

                    <TabPanel>
                      <SpectralAnalysisTab setId={setId} />
                      {/* <Card>
                        <CardBody>
                          <VStack align="stretch" spacing={4}>
                            <Text fontWeight="bold" mb={2}>
                              Key Spectral Regions and Features (EXAMPLE ONLY - FIXED TEXT)
                            </Text>

                            <Box p={4} borderWidth="1px" borderRadius="md">
                              <Text fontWeight="bold" mb={3}>
                                Functional Group Region (4000-1500 cm⁻¹)
                              </Text>
                              <UnorderedList spacing={2}>
                                <ListItem>
                                  Strong C-H stretch absorptions around 2920
                                  cm⁻¹
                                </ListItem>
                                <ListItem>
                                  Carbonyl (C=O) stretch region shows
                                  differences around 1740 cm⁻¹
                                </ListItem>
                                <ListItem>
                                  O-H stretching region (3400-3200 cm⁻¹) shows
                                  subtle variations
                                </ListItem>
                              </UnorderedList>
                            </Box>

                            <Box p={4} borderWidth="1px" borderRadius="md">
                              <Text fontWeight="bold" mb={3}>
                                Fingerprint Region (1500-600 cm⁻¹)
                              </Text>
                              <UnorderedList spacing={2}>
                                <ListItem>
                                  Multiple characteristic peaks specific to
                                  sample compounds
                                </ListItem>
                                <ListItem>
                                  Notable differences in the 1200-1000 cm⁻¹
                                  region (C-O stretching)
                                </ListItem>
                                <ListItem>
                                  Complex pattern of peaks below 1000 cm⁻¹
                                  typical of molecular structures
                                </ListItem>
                              </UnorderedList>
                            </Box>

                          </VStack>
                        </CardBody>
                      </Card> */}
                    </TabPanel>

                    <TabPanel>
                      <Card>
                        <CardBody>
                          <VStack align="stretch" spacing={4}>
                            <Text fontWeight="bold" mb={2}>
                              Quality Metrics
                            </Text>
                            <List spacing={3}>
                              <ListItem>
                                <HStack justify="space-between">
                                  <Tooltip label="Signal-to-Noise Ratio indicates the quality of the spectral data">
                                    <Text>Signal-to-Noise Ratio:</Text>
                                  </Tooltip>
                                  <Badge bg={biaColors.customGreen} color="white">
                                    {(data?.input_data?.input1?.data
                                      ? Math.max(
                                          ...data.input_data.input1.data.map(
                                            (d) => d.y
                                          )
                                        ) /
                                        Math.sqrt(
                                          data.input_data.input1.data
                                            .slice(0, 100)
                                            .reduce(
                                              (sum, d) =>
                                                sum + Math.pow(d.y, 2),
                                              0
                                            ) / 100
                                        )
                                      : 0
                                    ).toFixed(1)}
                                  </Badge>
                                </HStack>
                              </ListItem>
                              <ListItem>
                                <HStack justify="space-between">
                                  <Tooltip label="Baseline stability affects the reliability of peak intensities">
                                    <Text>Baseline Stability:</Text>
                                  </Tooltip>
                                  <Badge bg={biaColors.customGreen} color="white">Good</Badge>
                                </HStack>
                              </ListItem>
                              <ListItem>
                                <HStack justify="space-between">
                                  <Tooltip label="Resolution determines the ability to distinguish nearby peaks">
                                    <Text>Spectral Resolution:</Text>
                                  </Tooltip>
                                  <Badge bg={biaColors.customGreen} color="white">High</Badge>
                                </HStack>
                              </ListItem>
                              <Divider />
                              <ListItem>
                                <VStack align="stretch" spacing={2}>
                                  <Text fontWeight="medium">
                                    Recommendations:
                                  </Text>
                                  <Text fontSize="sm">
                                    • Check baseline in fingerprint region
                                  </Text>
                                  <Text fontSize="sm">
                                    • Verify peak assignments in 1500-1000 cm⁻¹
                                    range
                                  </Text>
                                  <Text fontSize="sm">
                                    • Consider multiple scans for better SNR
                                  </Text>
                                </VStack>
                              </ListItem>
                            </List>
                          </VStack>
                        </CardBody>
                      </Card>
                    </TabPanel>

                    <TabPanel>
                      <Card>
                        <CardBody>
                          <VStack align="stretch" spacing={4}>
                            <SpiceAnalysis />
                          </VStack>
                        </CardBody>
                      </Card>
                    </TabPanel>

                    {/* <TabPanel>
                      <Card>
                        <CardBody>
                          <VStack align="stretch" spacing={4}>
                            <Text fontWeight="bold" mb={2}>
                              Advanced Settings
                            </Text>
                            <FormControl>
                              <FormLabel>Resolution</FormLabel>
                              <Select defaultValue="medium">
                                <option value="low">Low (faster)</option>
                                <option value="medium">Medium</option>
                                <option value="high">High (slower)</option>
                              </Select>
                            </FormControl>
                            <FormControl>
                              <FormLabel>Smoothing</FormLabel>
                              <Select defaultValue="savitzky-golay">
                                <option value="none">None</option>
                                <option value="moving-average">
                                  Moving Average
                                </option>
                                <option value="savitzky-golay">
                                  Savitzky-Golay
                                </option>
                              </Select>
                            </FormControl>
                            <FormControl>
                              <FormLabel>Peak Detection Sensitivity</FormLabel>
                              <Slider
                                defaultValue={0.5}
                                min={0}
                                max={1}
                                step={0.1}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </FormControl>
                            <Button variant="outline">Reset to Defaults</Button>
                          </VStack>
                        </CardBody>
                      </Card>
                    </TabPanel> */}
                  </TabPanels>
                </Tabs>
              </Box>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SpectrogramModal;
