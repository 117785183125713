import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  VStack,
  Progress,
  Text,
  Spinner,
  useToast,
  Heading,
  Center,
  Container,
  Circle,
  Icon,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import axios from "axios";
import { biaColors } from "../styles/colors";

const UploadProgressPage = ({
  files,
  onComplete,
  formValues = {
    jobName: "",
    customerName: "",
    commodities: "",
  },
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState("");
  const [uploadStatus, setUploadStatus] = useState("processing"); // uploading, processing, complete, error
  const navigate = useNavigate();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [newJobId, setNewJobId] = useState(0);

  useEffect(() => {
    const uploadFiles = async () => {
      try {
        setNewJobId(0);
        const response = await axios.post(`${apiUrl}/api/job/create`, {
          name: formValues.jobName,
          commodity: formValues.commodities,
          customername: formValues.customerName,
        });

        const jobId = response.data.jobid;
        setNewJobId(jobId);
        console.log("New job created with ID: ", jobId);

        setUploadStatus("uploading");

        const flattenedSets = [];
        const samplesByBase = {};

        const getBaseFileName = (filename) => {
          const withoutExtension = filename.replace(/\.[^/.]+$/, "");
          return withoutExtension.slice(0, -1);
        };

        // Add control sets first
        files.forEach((set) => {
          if (set.control.length === 3) {
            flattenedSets.push({
              type: "control",
              files: [...set.control].sort((a, b) =>
                a.name.slice(-5, -4).localeCompare(b.name.slice(-5, -4))
              ),
            });
          }

          // Group samples by their base name
          set.sample.forEach((file) => {
            const base = getBaseFileName(file.name);
            if (!samplesByBase[base]) {
              samplesByBase[base] = [];
            }
            samplesByBase[base].push(file);
          });
        });

        // Add complete sample sets
        Object.values(samplesByBase).forEach((files) => {
          if (files.length === 3) {
            flattenedSets.push({
              type: "sample",
              files: [...files].sort((a, b) =>
                a.name.slice(-5, -4).localeCompare(b.name.slice(-5, -4))
              ),
            });
          }
        });

        let setRefNo = 0;
        // Process each set sequentially
        for (const set of flattenedSets) {
          setRefNo = setRefNo + 1; // This will now number controls 1,2,3 then samples 4,5,6 etc
          const uploadPromises = [];
          const fileLocations = {};

          console.log("Uploading set:", set);
          console.log(
            "Files being sent to get-upload-urls:",
            set.files.map((file) => ({ filename: file.name }))
          );

          // Get presigned URLs for all files in the set
          const presignedUrls = await axios.post(
            `${apiUrl}/api/sets/upload/get-upload-urls`,
            {
              jobId,
              setRefNo,
              files: set.files.map((file) => ({ filename: file.name })),
            }
          );

          // Upload each file in the set
          for (let i = 0; i < set.files.length; i++) {
            const file = set.files[i];
            setCurrentFile(file.name);
            const presignedUrl = presignedUrls.data[`file${i + 1}`];
            const s3Path = `${jobId}/${setRefNo}/${file.name}`;

            const getContentType = (file) => {
              const extension = file.name.toLowerCase().split(".").pop();
              const contentTypes = {
                csv: "text/csv",
                tsv: "text/tab-separated-values",
                txt: "text/plain",
                spa: "application/octet-stream",
              };
              return contentTypes[extension] || "application/octet-stream";
            };

            const s3Request = axios.create();
            delete s3Request.defaults.headers.common["Authorization"];

            const uploadPromise = s3Request
              .put(presignedUrl, file, {
                headers: {
                  "Content-Type": getContentType(file),
                },
                // Don't transform the file content
                transformRequest: [(data) => data],
              })
              .then(() => {
                fileLocations[`file${i + 1}`] = s3Path;
              });

            uploadPromises.push(uploadPromise);

            setUploadProgress(
              (prev) => prev + 100 / (flattenedSets.length * 3)
            );
          }

          // Wait for all files in the set to upload
          await Promise.all(uploadPromises);

          let isControl = set.type === "control";

          await axios.post(`${apiUrl}/api/sets/create`, {
            setRefNo,
            jobId,
            isControl,
            fileLocations,
          });
        }

        await axios.post(`${apiUrl}/api/job/process`, {
          jobId,
        });

        setUploadStatus("complete");
        toast({
          title: "Upload Complete",
          description:
            "All files have been successfully uploaded and submitted for processing.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Wait for 2 seconds to show success state before redirecting
        setTimeout(() => {
          onComplete();
          navigate("/portal/predictions");
        }, 2000);
      } catch (error) {
        setUploadStatus("error");
        await axios.delete(`${apiUrl}/api/job/${newJobId}`);
        toast({
          title: "Upload Failed",
          description: "There was an error uploading your files.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    uploadFiles();
  }, [files, navigate, onComplete, toast]);

  const renderContent = () => {
    switch (uploadStatus) {
      case "uploading":
        return (
          <VStack spacing={6}>
            <Spinner size="xl" color="blue.500" thickness="4px" />
            <Heading size="md">Uploading Files</Heading>
            <Text>{currentFile}</Text>
            <Progress
              value={uploadProgress}
              size="lg"
              width="100%"
              bg={biaColors.customBlue}
              color="white"
              hasStripe
              isAnimated
            />
            <Text>{Math.round(uploadProgress)}% Complete</Text>
          </VStack>
        );

      case "processing":
        return (
          <VStack spacing={6}>
            <Spinner size="xl" color="purple.500" thickness="4px" />
            <Heading size="md">Processing Job</Heading>
            <Progress
              value={0}
              size="lg"
              width="100%"
              bg={biaColors.customBlue}
              color="white"
              hasStripe
              isAnimated
            />
            <Text>Creating job ({formValues.jobName}) and preparing sets</Text>
          </VStack>
        );

      case "complete":
        return (
          <VStack spacing={6}>
            <Circle size="60px" bg="green.500">
              <Icon as={CheckIcon} color="white" boxSize={8} />
            </Circle>
            <Heading size="md">Upload Complete!</Heading>
            <Text>Redirecting to predictions page...</Text>
          </VStack>
        );

      case "error":
        return (
          <VStack spacing={6}>
            <Circle size="60px" bg="red.500">
              <Icon as={CheckIcon} color="white" boxSize={8} />
            </Circle>
            <Heading size="md">Upload Failed</Heading>
            <Text>Please try again later</Text>
          </VStack>
        );

      default:
        return null;
    }
  };

  return (
    <Container maxW="container.md" py={20}>
      <Center>
        <Box
          p={10}
          shadow="xl"
          borderRadius="xl"
          bg="white"
          width="100%"
          maxW="600px"
        >
          {renderContent()}
        </Box>
      </Center>
    </Container>
  );
};

export default UploadProgressPage;
