import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { MdFileUpload, MdClose } from "react-icons/md";
import { FileText, RefreshCw } from 'lucide-react';
import {
    VStack,
    Box,
    Center,
    Text,
    Select,
    Input,
    Button,
    Progress,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    List,
    ListItem,
    IconButton,
    HStack,
    useToast,
    Container,
    Flex,
    Badge,
    Icon
} from '@chakra-ui/react';
import { biaColors } from '../styles/colors';

const apiUrl = process.env.REACT_APP_API_URL;
const MAX_FILES = 30; // Increased to allow for more multiples of 3
const ALLOWED_EXTENSIONS = ['.spa', '.SPA'];
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

const AveragingForm = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [folderId, setFolderId] = useState(null);
    const [files, setFiles] = useState([]);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [isComplete, setIsComplete] = useState(false);
    const [pollAttempts, setPollAttempts] = useState(0);
    const [error, setError] = useState(null);
    const toast = useToast();

    const formik = useFormik({
        initialValues: {
            device: 'summitpro'
        },
        validationSchema: Yup.object({
            device: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {
            // Validate number of files
            if (files.length < 3 || files.length % 3 !== 0) {
                toast({
                    title: 'Invalid number of files',
                    description: 'Please select a number of files that is a multiple of 3 (minimum 3 files)',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            try {
                setIsUploading(true);
                setError(null);
                
                // First get presigned URLs
                const urlResponse = await axios.post(
                    `${apiUrl}/api/tooling/averaging/get-upload-urls`,
                    {
                        device: values.device,
                        files: files.map(file => ({ filename: file.name }))
                    }
                );
                
                // Upload files directly to S3
                const uploadPromises = [];
                const s3Request = axios.create();
                delete s3Request.defaults.headers.common['Authorization'];
                
                files.forEach((file, index) => {
                    const presignedUrl = urlResponse.data.urls[`file${index + 1}`];
                    
                    const uploadPromise = s3Request.put(
                        presignedUrl,
                        file,
                        {
                            headers: {
                                'Content-Type': 'application/octet-stream'
                            },
                            transformRequest: [(data) => data]
                        }
                    );
                    
                    uploadPromises.push(uploadPromise);
                });
                
                // Wait for all uploads to complete
                await Promise.all(uploadPromises);
                
                // Notify backend that uploads are complete
                const response = await axios.post(
                    `${apiUrl}/api/tooling/averaging/process`,
                    {
                        folder_id: urlResponse.data.folder_id,
                        device: values.device
                    }
                );
    
                setFolderId(response.data.folder_id);
                setPollAttempts(0);
                startPolling(response.data.folder_id);
                
                toast({
                    title: 'Upload successful',
                    description: 'Your files are being processed',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } catch (error) {
                setError(error.response?.data?.detail || 'An error occurred during upload');
                toast({
                    title: 'Upload failed',
                    description: error.response?.data?.detail || 'An error occurred during upload',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsUploading(false);
            }
        }
    });

    const validateFile = (file) => {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        if (!ALLOWED_EXTENSIONS.includes(extension)) {
            return `File type ${extension} is not supported`;
        }
        if (file.size > MAX_FILE_SIZE) {
            return 'File size exceeds 50MB limit';
        }
        return null;
    };

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validFiles = [...files]; // Start with existing files
        const errors = [];
    
        selectedFiles.forEach(file => {
            const error = validateFile(file);
            if (error) {
                errors.push(`${file.name}: ${error}`);
            } else if (validFiles.length >= MAX_FILES) {
                errors.push(`${file.name}: Maximum number of files (${MAX_FILES}) exceeded`);
            } else {
                validFiles.push(file);
            }
        });

        if (errors.length > 0) {
            toast({
                title: 'File validation errors',
                description: (
                    <List>
                        {errors.map((error, index) => (
                            <ListItem key={index}>{error}</ListItem>
                        ))}
                    </List>
                ),
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
        }

        setFiles(validFiles);
    };

    const removeFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const startPolling = (folder) => {
        const pollInterval = setInterval(async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/tooling/averaging/${folder}/files`);
                setProcessedFiles(response.data.files);
                setPollAttempts(prev => prev + 1);
                
                if (response.data.complete) {
                    setIsComplete(true);
                    clearInterval(pollInterval);
                    toast({
                        title: 'Processing complete',
                        description: 'Your files are ready for download',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } else if (pollAttempts >= 30) { // 5 minutes timeout
                    clearInterval(pollInterval);
                    setError('Processing timeout - please contact support');
                }
            } catch (error) {
                // Don't stop polling on 404s
                if (error.response?.status === 404) {
                    setPollAttempts(prev => prev + 1);
                    if (pollAttempts >= 30) { // 5 minutes timeout
                        clearInterval(pollInterval);
                        setError('Processing timeout - please contact support');
                    }
                } else {
                    console.error('Polling error:', error);
                    clearInterval(pollInterval);
                    setError('Error checking processing status');
                }
            }
        }, 11000);

        return () => clearInterval(pollInterval);
    };

    const startNewProcess = () => {
        setFolderId(null);
        setFiles([]);
        setProcessedFiles([]);
        setIsComplete(false);
        setError(null);
        setPollAttempts(0);
    };

    const ProcessingStatus = () => (
        <Container maxW="4xl" py={8}>
            <Box bg="white" shadow="xl" rounded="lg" p={6}>
                <VStack spacing={6} align="stretch">
                    <Text as="h2" fontSize="2xl" fontWeight="bold" textAlign="center">
                        {isComplete ? 'Processing Complete' : 'Processing Files...'}
                    </Text>
                    
                    {!isComplete && (
                        <Box>
                            <Progress size="xs" isIndeterminate colorScheme="blue" />
                            <Text mt={2} fontSize="sm" color="gray.600">
                                This may take several minutes
                            </Text>
                        </Box>
                    )}

                    <Box borderWidth="1px" rounded="lg" p={4}>
                        <VStack align="stretch" spacing={3}>
                            {processedFiles.map(file => (
                                <HStack key={file.name} justify="space-between">
                                    <HStack>
                                        <FileText size={20} />
                                        <Text>{file.name}</Text>
                                    </HStack>
                                    <Button
                                        size="sm"
                                        colorScheme="blue"
                                        as="a"
                                        href={file.url}
                                        download={file.name}
                                    >
                                        Download
                                    </Button>
                                </HStack>
                            ))}
                        </VStack>
                    </Box>

                    {error && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    {(isComplete || error) && (
                        <Button
                            leftIcon={<RefreshCw />}
                            colorScheme="green"
                            onClick={startNewProcess}
                            size="lg"
                            width="full"
                        >
                            Start New Process
                        </Button>
                    )}
                </VStack>
            </Box>
        </Container>
    );

    if (folderId) {
        return <ProcessingStatus />;
    }

    return (
        <Container maxW="4xl" py={8}>
            <Box bg="white" shadow="xl" rounded="lg" p={6}>
                <VStack spacing={6}>
                    <Text as="h2" fontSize="2xl" fontWeight="bold">
                        Averaged Spectra
                    </Text>

                    <Text textAlign="center" color="gray.600">
                        Select files for spectra averaging (must be a multiple of 3)
                    </Text>

                    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                        <VStack spacing={6} width="full">
                            <Select
                                id="device"
                                name="device"
                                onChange={formik.handleChange}
                                value={formik.values.device}
                                isInvalid={formik.touched.device && formik.errors.device}
                            >
                                <option value='summitpro'>SummitPro</option>
                                <option value='is5'>IS5</option>
                            </Select>

                            <Box
                                borderWidth={2}
                                borderStyle="dashed"
                                rounded="lg"
                                p={6}
                                width="full"
                                textAlign="center"
                                position="relative"
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.currentTarget.style.borderColor = 'blue';
                                }}
                                onDragLeave={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.currentTarget.style.borderColor = '';
                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.currentTarget.style.borderColor = '';
                                    const droppedFiles = Array.from(e.dataTransfer.files);
                                    const event = { target: { files: droppedFiles } };
                                    handleFileChange(event);
                                }}
                            >
                                <Input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    accept={ALLOWED_EXTENSIONS.join(',')}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    right={0}
                                    bottom={0}
                                    opacity={0}
                                    cursor="pointer"
                                    zIndex={2}
                                />
                                <VStack spacing={2}>
                                    <Icon as={MdFileUpload} boxSize={8} color="gray.400" />
                                    <Text fontWeight="medium">
                                        Drag and drop files here or click to select
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {ALLOWED_EXTENSIONS.join(', ')} files up to {MAX_FILE_SIZE / (1024 * 1024)}MB
                                    </Text>
                                </VStack>
                            </Box>

                            {files.length > 0 && (
                                <Box width="full">
                                    <Flex justify="space-between" align="center" mb={2}>
                                        <Text fontSize="sm">
                                            Selected files: {files.length}
                                        </Text>
                                        <Badge
                                            bg={files.length >= 3 && files.length % 3 === 0 ? biaColors.customGreen : biaColors.customRed}
                                            color="white"
                                        >
                                            {files.length >= 3 && files.length % 3 === 0 
                                                ? 'Valid number of files' 
                                                : 'Need multiple of 3 files'}
                                        </Badge>
                                    </Flex>
                                    <Box
                                        maxH="200px"
                                        overflowY="auto"
                                        borderWidth={1}
                                        rounded="md"
                                        p={2}
                                    >
                                        <List spacing={2}>
                                            {files.map((file, index) => (
                                                <ListItem key={index}>
                                                    <HStack justify="space-between">
                                                        <Text fontSize="sm" isTruncated maxW="300px">
                                                            {file.name}
                                                        </Text>
                                                        <IconButton
                                                            icon={<MdClose />}
                                                            size="sm"
                                                            onClick={() => removeFile(index)}
                                                            aria-label="Remove file"
                                                        />
                                                    </HStack>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Box>
                            )}

                            <Button
                                type="submit"
                                colorScheme="blue"
                                isDisabled={isUploading || files.length < 3 || files.length % 3 !== 0}
                                isLoading={isUploading}
                                leftIcon={<MdFileUpload />}
                                size="lg"
                                width="full"
                            >
                                Submit
                            </Button>
                        </VStack>
                    </form>
                </VStack>
            </Box>
        </Container>
    );
};

export default AveragingForm;