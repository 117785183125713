import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Button,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { biaColors } from '../../../styles/colors';

const ChangeModelModal = ({
  isOpen,
  onClose,
  commodity,
  models,
  onSuccess,
  apiUrl,
}) => {
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Model for {commodity.name}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ modelId: commodity.modelId || '' }}
          validationSchema={Yup.object({
            modelId: Yup.number().required('Please select a model'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await axios.patch(
                `${apiUrl}/api/commodities/${commodity.id}`,
                {
                  id: commodity.id,
                  name: commodity.name,
                  modelId: parseInt(values.modelId),
                }
              );
              onSuccess();
              onClose();
              toast({
                title: 'Model updated successfully',
                status: 'success',
                duration: 3000,
              });
            } catch (error) {
              toast({
                title: 'Error updating model',
                description: error.response?.data?.detail || 'Something went wrong',
                status: 'error',
                duration: 3000,
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <ModalBody>
                <FormControl isInvalid={errors.modelId && touched.modelId}>
                  <FormLabel>Select Model</FormLabel>
                  <Select
                    name="modelId"
                    value={values.modelId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Select a model</option>
                    {models.map((model) => (
                      <option key={model.id} value={model.id}>
                        {model.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.modelId}</FormErrorMessage>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  bg={biaColors.customBlue}
                  color="white"
                  mr={3}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Save
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ChangeModelModal;