import React from 'react';
import { Box } from '@chakra-ui/react';

const TableContainer = ({ children }) => {
    return (
      <Box
        w="100%"
        overflow="auto"
        borderWidth="1px"
        borderRadius="lg"
        bg="white"
      >
        {children}
      </Box>
    );
  };

export default TableContainer;