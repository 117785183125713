import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Badge,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  Select,
  FormControl,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react';
import { MoreVertical } from 'lucide-react';
import axios from 'axios';
import TableContainer from '../shared/TableContainer';
import { biaColors } from '../../../styles/colors';

const ROLES = [
  { id: 'GLOBAL_ADMIN', label: 'Global Admin' },
  { id: 'COMPANY_ADMIN', label: 'Company Admin' },
  { id: 'USER', label: 'User' }
];

const UserTable = ({ 
  users, 
  loading, 
  onUpdateUser, 
  onStatusChange, 
  isGlobalAdmin,
  currentUser 
}) => {
  const { 
    isOpen: isRolesOpen, 
    onOpen: onRolesOpen, 
    onClose: onRolesClose 
  } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (isGlobalAdmin) {
      const fetchCompanies = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies`);
          setCompanies(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
      fetchCompanies();
    }
  }, [isGlobalAdmin]);

  const handleRolesClick = (user) => {
    setSelectedUser(user);
    setSelectedRoles(user.roles || []);
    setSelectedCompany(user.company_id || null);
    onRolesOpen();
  };

  const handleRolesSave = async () => {
    const updates = {
      roles: selectedRoles,
      status: selectedUser.status // Maintain existing status
    };
    
    // Only include company_id if it's changed or if we're a global admin
    if (isGlobalAdmin) {
      updates.company_id = selectedCompany;
    }

    await onUpdateUser(selectedUser.cognito, updates);
    onRolesClose();
  };

  const getStatusBadge = (status) => {
    const statusColors = {
      ACTIVE: biaColors.customGreen,
      DEACTIVATED: biaColors.customRed,
      PENDING_VERIFICATION: 'yellow'
    };
    return (
      <Badge bg={statusColors[status]} color="white">
        {status.toLowerCase().replace('_', ' ')}
      </Badge>
    );
  };

  const getRolesBadges = (roles = []) => {
    return (
      <HStack spacing={2}>
        {roles.map(role => (
          <Badge key={role} bg={biaColors.customBlue} color="white">
            {role.replace('_', ' ')}
          </Badge>
        ))}
      </HStack>
    );
  };

  const canModifyUser = (targetUser) => {
    if (isGlobalAdmin) return true;
    if (targetUser.cognito === currentUser.cognito) return false;
    if (targetUser.roles?.includes('GLOBAL_ADMIN')) return false;
    return true;
  };

  return (
    <>
    <TableContainer>
      <Table variant="simple" size="sm">
        <Thead position="sticky" top={0} bg="white" zIndex={1}>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Company</Th>
            <Th>Roles</Th>
            <Th>Status</Th>
            <Th>Jobs</Th>
            <Th>Email Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.cognito}>
              <Td>{user.name}</Td>
              <Td>{user.email}</Td>
              <Td>{user.company_name || '-'}</Td>
              <Td>{getRolesBadges(user.roles)}</Td>
              <Td>{getStatusBadge(user.status)}</Td>
              <Td>{user.job_count}</Td>
              <Td>
                <Badge 
                  bg={user.email_verified ? biaColors.customGreen : 'yellow'}
                  color="white"
                >
                  {user.email_verified ? 'Verified' : 'Pending'}
                </Badge>
              </Td>
              <Td>
                {canModifyUser(user) && (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<MoreVertical size={16} />}
                      variant="ghost"
                      size="sm"
                    />
                    <MenuList>
                      <MenuItem onClick={() => handleRolesClick(user)}>
                        Manage Roles & Company
                      </MenuItem>
                      {user.status === 'ACTIVE' ? (
                        <MenuItem 
                          onClick={() => onStatusChange(user.cognito, 'deactivate')}
                          color="red.500"
                        >
                          Deactivate
                        </MenuItem>
                      ) : (
                        <MenuItem 
                          onClick={() => onStatusChange(user.cognito, 'activate')}
                          color="green.500"
                        >
                          Activate
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      </TableContainer>

      {/* Role & Company Management Modal */}
      <Modal isOpen={isRolesOpen} onClose={onRolesClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage User Access</ModalHeader>
          <ModalBody>
            {selectedUser && (
              <VStack align="start" spacing={4}>
                <Text>
                  Managing access for {selectedUser.name}
                </Text>

                {isGlobalAdmin && (
                  <FormControl>
                    <FormLabel>Company</FormLabel>
                    <Select
                      value={selectedCompany || ''}
                      onChange={(e) => setSelectedCompany(e.target.value || null)}
                    >
                      <option value="">No Company</option>
                      {companies.map(company => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <FormControl>
                  <FormLabel>Roles</FormLabel>
                  <VStack align="start">
                    {ROLES.map(role => {
                      // Only show Global Admin option for global admins
                      if (role.id === 'GLOBAL_ADMIN' && !isGlobalAdmin) return null;
                      
                      return (
                        <Checkbox
                          key={role.id}
                          isChecked={selectedRoles.includes(role.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedRoles([...selectedRoles, role.id]);
                            } else {
                              setSelectedRoles(selectedRoles.filter(r => r !== role.id));
                            }
                          }}
                          isDisabled={
                            role.id === 'GLOBAL_ADMIN' && !isGlobalAdmin ||
                            selectedUser.cognito === currentUser.cognito
                          }
                        >
                          {role.label}
                        </Checkbox>
                      );
                    })}
                  </VStack>
                </FormControl>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onRolesClose}>
              Cancel
            </Button>
            <Button 
              colorScheme="blue" 
              onClick={handleRolesSave}
              isDisabled={selectedRoles.length === 0}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserTable;