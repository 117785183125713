// src/components/dashboard/widgets/CommodityStatsWidget.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Progress,
  Select,
  Skeleton,
  Text,
  HStack
} from '@chakra-ui/react';
import axios from 'axios';
import {biaColors} from "../../../styles/colors.jsx"

const apiUrl = process.env.REACT_APP_API_URL;

const CommodityStatsWidget = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('30');

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/dashboard/commodity-stats`, {
        params: { days: timeRange }
      });
      setData(response.data);
    } catch (err) {
      setError('Failed to load commodity statistics');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timeRange]);

  if (loading) {
    return <Skeleton height="200px" />;
  }

  if (error) {
    return <Text color={biaColors.customRed}>{error}</Text>;
  }

  return (
    <Box>
      <HStack spacing={4} mb={4}>
        <Text>Time Range:</Text>
        <Select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          size="sm"
          w="150px"
        >
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="90">Last 90 days</option>
        </Select>
      </HStack>

      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Commodity</Th>
            <Th isNumeric>Total Sets</Th>
            <Th>Distribution</Th>
            <Th isNumeric>Typical %</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Tr key={item.commodity}>
              <Td>{item.commodity}</Td>
              <Td isNumeric>{item.total_sets}</Td>
              <Td>
                <Box w="100%">
                  <Progress
                    value={item.typical_percentage}
                    bg={biaColors.customRed}
                    color="green"
                    size="sm"
                    borderRadius="full"
                  />
                </Box>
              </Td>
              <Td isNumeric>
                <Text
                  color={item.typical_percentage >= 80 ? biaColors.customBlue : 
                         item.typical_percentage >= 60 ? 'orange.500' : biaColors.customRed}
                  fontWeight="bold"
                >
                  {item.typical_percentage.toFixed(1)}%
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {data.length === 0 && (
        <Text color="gray.500" textAlign="center" mt={4}>
          No data available for the selected time range
        </Text>
      )}
    </Box>
  );
};

export default CommodityStatsWidget;