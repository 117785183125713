import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { biaColors } from '../../../styles/colors';

const DeleteControlModal = ({
  isOpen,
  onClose,
  commodity,
  onSuccess,
  apiUrl,
}) => {
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      confirmDelete: '',
    },
    validationSchema: Yup.object({
      confirmDelete: Yup.string()
        .required('Please type "delete" to confirm')
        .matches(/^delete$/, 'Please type "delete" to confirm'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await axios.delete(
          `${apiUrl}/api/commodities/controls/${commodity.id}`
        );

        toast({
          title: 'Success',
          description: 'Control file deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        onSuccess();
        onClose();
      } catch (error) {
        toast({
          title: 'Error',
          description:
            error.response?.data?.detail || 'Failed to delete control file',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setSubmitting(false);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Control File</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <FormControl
              isInvalid={
                formik.touched.confirmDelete &&
                formik.errors.confirmDelete
              }
            >
              <FormLabel>Type "delete" to confirm deletion</FormLabel>
              <Input
                name="confirmDelete"
                onChange={formik.handleChange}
                value={formik.values.confirmDelete}
                placeholder="delete"
              />
              <FormErrorMessage>
                {formik.errors.confirmDelete}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg={biaColors.customRed}
              color="white"
              type="submit"
              isLoading={formik.isSubmitting}
              isDisabled={formik.values.confirmDelete !== 'delete'}
            >
              Delete
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DeleteControlModal;