
import React, {useState, useEffect} from 'react';
import Plot from 'react-plotly.js';
import {biaColors} from "../styles/colors.jsx"



export default function PlotChart(props) {

    const typical = props.myTypical;
    const atypical = props.myAtypical;
    const is3D = props.is3D;

    if(is3D) {
        var typicalValues = {
            xValues: [],
            yValues: [],
            zValues: []
        }

        var atypicalValues = {
            xValues: [],
            yValues: [],
            zValues: []
        }

        for(let x=0; x<typical.length;x++){
            typicalValues.xValues.push(typical[x]["x"]);
            typicalValues.yValues.push(typical[x]["y"]);
            typicalValues.zValues.push(typical[x]["z"]);
        }

        for(let x=0; x<atypical.length;x++){
            atypicalValues.xValues.push(atypical[x]["x"]);
            atypicalValues.yValues.push(atypical[x]["y"]);
            atypicalValues.zValues.push(atypical[x]["z"]);
        }

    }
    else{
        var typicalValues = {
            xValues: [],
            yValues: []
        }

        var atypicalValues = {
            xValues: [],
            yValues: []
        }

        for(let x=0; x<typical.length;x++){
            typicalValues.xValues.push(typical[x]["x"]);
            typicalValues.yValues.push(typical[x]["y"]);
        }

        for(let x=0; x<atypical.length;x++){
            atypicalValues.xValues.push(atypical[x]["x"]);
            atypicalValues.yValues.push(atypical[x]["y"]);
        }

    }





    if(props.myTypical && is3D){
        return(
            <Plot
                data={[
                    {x: typicalValues.xValues, y: typicalValues.yValues, z: typicalValues.zValues, type: 'scatter3d', mode: 'markers', name:'Typical', marker: {
                            size: 12,
                            line: {
                                color: biaColors.customRed,
                                width: 0.5},
                            opacity: 0.8}},
                    {x: atypicalValues.xValues, y: atypicalValues.yValues, z: typicalValues.zValues, type: 'scatter3d', mode: 'markers', name:'Atypical', marker: {
                            size: 12,
                            line: {
                                color: biaColors.customBlue,
                                width: 0.5},
                            opacity: 0.8}},
                    {x: [props.data.x], y: [props.data.y], z: [props.data.z], type: 'scatter3d', mode: 'markers', name: props.data.name, marker: {
                            size: 12,
                            line: {
                                color: biaColors.customGreen,
                                width: 0.5},
                            opacity: 0.8}}
                ]}
                layout={ {width:970, height: 800, showlegend: true, legend: {"orientation": "h"} }}
            />
    )}
    if(props.myTypical && !is3D){
        return(
            <Plot
                data={[
                    {x: typicalValues.xValues, y: typicalValues.yValues, type: 'scatter', mode: 'markers', name:'Typical', marker: {
                            size: 12,
                            line: {
                                color: biaColors.customRed,
                                width: 0.5},
                            opacity: 0.8}},
                    {x: atypicalValues.xValues, y: atypicalValues.yValues, type: 'scatter', mode: 'markers', name:'Atypical', marker: {
                            size: 12,
                            line: {
                                color: biaColors.customBlue,
                                width: 0.5},
                            opacity: 0.8}},
                    {x: [props.data.x], y: [props.data.y], type: 'scatter', mode: 'markers', name: props.data.name, marker: {
                            size: 12,
                            line: {
                                color: biaColors.customGreen,
                                width: 0.5},
                            opacity: 0.8}}
                ]}
                layout={ {width:970, height: 800, showlegend: true, legend: {"orientation": "h"} }}
            />
        )}
    else{
        return (
            <div>Loading!</div>
        )
    }

}