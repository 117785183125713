import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  IconButton,
  Spinner,
  Flex,
  Select,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
import { ViewIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { biaColors } from '../styles/colors.jsx';

const PaginationControls = ({ 
  currentPage, 
  pageSize, 
  totalItems, 
  onPageChange, 
  onPageSizeChange,
  isLoading 
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return (
    <Flex justify="space-between" align="center" w="full" gap={4}>
      <Stack direction="row" spacing={2} align="center">
        <Text>Show</Text>
        <Select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          w="100px"
          isDisabled={isLoading}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Select>
        <Text>entries</Text>
      </Stack>

      <Stack direction="row" spacing={2} align="center">
        <Text>
          Showing {startItem} to {endItem} of {totalItems} entries
        </Text>
        <Button
          size="sm"
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 1 || isLoading}
          leftIcon={<ChevronLeftIcon />}
        >
          Previous
        </Button>
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <Button
          size="sm"
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages || isLoading}
          rightIcon={<ChevronRightIcon />}
        >
          Next
        </Button>
      </Stack>
    </Flex>
  );
};

const TableContent = ({ jobs, commodities, JOB_STATUS, handleViewSets, isLoading }) => {
  if (isLoading) {
    return (
      <Tr>
        <Td colSpan={7}>
          <Flex justify="center" align="center" py={4}>
            <Spinner size="sm" mr={2} />
            <Text>Loading...</Text>
          </Flex>
        </Td>
      </Tr>
    );
  }

  if (jobs.length === 0) {
    return (
      <Tr>
        <Td colSpan={7}>
          <Flex justify="center" align="center" py={4}>
            <Text>No jobs found</Text>
          </Flex>
        </Td>
      </Tr>
    );
  }

  return jobs.map((job) => (
    <Tr key={job.jobId}>
      <Td isNumeric>{job.jobId}</Td>
      <Td>{job.jobName}</Td>
      <Td>{new Date(job.raised).toLocaleString()}</Td>
      <Td>{commodities[job.commodityId]?.name || 'Unknown'}</Td>
      <Td>
        <Badge
          bg={JOB_STATUS[job.jobStatusId]?.color || 'gray'}
          p={1}
          borderRadius="md"
          color="white"
        >
          {JOB_STATUS[job.jobStatusId]?.label || 'Unknown'}
        </Badge>
      </Td>
      <Td>{job.userName}</Td>
      <Td>
        <IconButton
          aria-label="View sets"
          icon={<ViewIcon />}
          size="sm"
          onClick={() => handleViewSets(job)}
          bg={biaColors.customBlue}
          color="white"
        />
      </Td>
    </Tr>
  ));
};

const JobsTable = ({ 
  jobs, 
  commodities, 
  JOB_STATUS, 
  handleViewSets,
  pagination,
  isLoading 
}) => {
  const { 
    currentPage, 
    pageSize,
    totalItems,
    onPageChange,
    onPageSizeChange 
  } = pagination;

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
      {/* Loading Overlay */}
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="whiteAlpha.800"
          zIndex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="xl" color="blue.500" thickness="3px" />
        </Box>
      )}

      <Box p={4}>
        <PaginationControls
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          isLoading={isLoading}
        />
      </Box>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th isNumeric>ID</Th>
              <Th>Name</Th>
              <Th>Date</Th>
              <Th>Commodity</Th>
              <Th>Status</Th>
              <Th>User</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>
          <Tbody>
            {jobs.length === 0 ? (
              <Tr>
                <Td colSpan={7} textAlign="center">
                  <Text py={4}>No jobs found</Text>
                </Td>
              </Tr>
            ) : (
              jobs.map((job) => (
                <Tr key={job.jobId}>
                  <Td isNumeric>{job.jobId}</Td>
                  <Td>{job.jobName}</Td>
                  <Td>{new Date(job.raised).toLocaleString()}</Td>
                  <Td>{commodities[job.commodityId]?.name || 'Unknown'}</Td>
                  <Td>
                    <Badge
                      bg={JOB_STATUS[job.jobStatusId]?.color || 'gray'}
                      color="white"
                      p={1}
                      borderRadius="md"
                    >
                      {JOB_STATUS[job.jobStatusId]?.label || 'Unknown'}
                    </Badge>
                  </Td>
                  <Td>{job.userName}</Td>
                  <Td>
                    <IconButton
                      aria-label="View sets"
                      icon={<ViewIcon />}
                      size="sm"
                      onClick={() => handleViewSets(job)}
                      bg={biaColors.customBlue}
                      color="white"
                    />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Box p={4}>
        <PaginationControls
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default JobsTable;