// src/modules/dashboard/DashboardContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';

const DashboardContext = createContext();

// Define available widgets configuration
const AVAILABLE_WIDGETS = [
  {
    id: 'recent-jobs',
    title: 'Recent Jobs',
    description: 'Shows the most recent jobs and their status',
    defaultSize: { w: 12, h: 8 }
  },
  {
    id: 'commodity-stats',
    title: 'Commodity Statistics',
    description: 'Display statistics for different commodities',
    defaultSize: { w: 12, h: 8 }
  },
  {
    id: 'deviation-analysis',
    title: 'Deviation Analysis',
    description: 'Shows deviation trends and significant changes',
    defaultSize: { w: 12, h: 8 }
  },
  {
    id: 'job-success-rate',
    title: 'Job Success Rate',
    description: 'Displays success rate metrics for jobs',
    defaultSize: { w: 12, h: 8 }
  },
  {
    id: 'navigation',
    title: 'Quick Navigation',
    description: 'Customizable shortcuts to frequently used pages',
    defaultSize: { w: 12, h: 6 }
  },
  {
    id: 'commodity-comparison',
    title: 'Commodity Comparison',
    defaultSize: { w: 12, h: 8 },
    description: 'Compare typical vs atypical results across commodities'
  },
  {
    id: 'trends',
    title: 'Prediction Trends',
    defaultSize: { w: 12, h: 8 },
    description: 'View the trends across predictions'
  }
];

// First, let's define the default widget configuration
const DEFAULT_WIDGETS = [
  {
    id: "navigation",
    settings: {
      columns: 3,
      buttonSize: "compact",
      enabledShortcuts: ["predictions", "plot3d", "new-prediction", "plot2d", "history", "average"]
    },
    instanceId: "navigation-default"
  },
  {
    id: "recent-jobs",
    settings: { limit: 5 },
    instanceId: "recent-jobs-default"
  },
  {
    id: "job-success-rate",
    settings: {},
    instanceId: "job-success-rate-default"
  },
  {
    id: "commodity-stats",
    settings: {},
    instanceId: "commodity-stats-default"
  },
  {
    id: "commodity-comparison",
    settings: {},
    instanceId: "commodity-comparison-default"
  },
  {
    id: "trends",
    settings: {},
    instanceId: "trends-default"
  }
];

// Define default layouts for all breakpoints
const DEFAULT_LAYOUTS = {
  lg: [
    { h: 7, i: "navigation-default", w: 6, x: 8, y: 0, moved: false, static: false },
    { h: 7, i: "recent-jobs-default", w: 9, x: 15, y: 0, moved: false, static: false },
    { h: 8, i: "job-success-rate-default", w: 7, x: 0, y: 0, minH: 2, minW: 3, moved: false, static: false },
    { h: 7, i: "commodity-stats-default", w: 10, x: 0, y: 8, minH: 2, minW: 3, moved: false, static: false },
    { h: 7, i: "commodity-comparison-default", w: 7, x: 10, y: 7, minH: 2, minW: 3, moved: false, static: false },
    { h: 7, i: "trends-default", w: 7, x: 17, y: 7, moved: false, static: false }
  ],
  md: [
    { h: 3, i: "navigation-default", w: 6, x: 0, y: 0, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "recent-jobs-default", w: 6, x: 0, y: 3, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "job-success-rate-default", w: 6, x: 0, y: 7, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "commodity-stats-default", w: 6, x: 0, y: 11, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "commodity-comparison-default", w: 6, x: 0, y: 15, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "trends-default", w: 6, x: 0, y: 18, minH: 2, minW: 3, moved: false, static: false }
  ],
  sm: [
    { h: 3, i: "navigation-default", w: 2, x: 0, y: 0, moved: false, static: false },
    { h: 4, i: "recent-jobs-default", w: 4, x: 0, y: 3, moved: false, static: false },
    { h: 4, i: "job-success-rate-default", w: 6, x: 0, y: 7, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "commodity-stats-default", w: 6, x: 0, y: 11, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "commodity-comparison-default", w: 6, x: 0, y: 15, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "trends-default", w: 6, x: 0, y: 18, minH: 2, minW: 3, moved: false, static: false }
  ],
  xs: [
    { h: 3, i: "navigation-default", w: 6, x: 0, y: 0, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "recent-jobs-default", w: 6, x: 0, y: 3, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "job-success-rate-default", w: 6, x: 0, y: 7, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "commodity-stats-default", w: 6, x: 0, y: 11, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "commodity-comparison-default", w: 6, x: 0, y: 15, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "trends-default", w: 6, x: 0, y: 18, minH: 2, minW: 3, moved: false, static: false }
  ],
  xxs: [
    { h: 3, i: "navigation-default", w: 6, x: 0, y: 0, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "recent-jobs-default", w: 6, x: 0, y: 3, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "job-success-rate-default", w: 6, x: 0, y: 7, minH: 2, minW: 3, moved: false, static: false },
    { h: 4, i: "commodity-stats-default", w: 6, x: 0, y: 11, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "commodity-comparison-default", w: 6, x: 0, y: 15, minH: 2, minW: 3, moved: false, static: false },
    { h: 3, i: "trends-default", w: 6, x: 0, y: 18, minH: 2, minW: 3, moved: false, static: false }
  ]
};

export const DashboardProvider = ({ children }) => {
  const [widgets, setWidgets] = useState([]);
  const [layouts, setLayouts] = useState({});
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  const loadDashboardConfig = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/dashboard/config`);
      
      // Check if we have existing configuration
      const hasExistingConfig = response.data && 
        response.data.widgets && 
        response.data.widgets.length > 0 && 
        response.data.layouts && 
        Object.keys(response.data.layouts).length > 0;
  
      // Use existing config or defaults
      const { widgets: configWidgets, layouts: configLayouts } = hasExistingConfig
        ? response.data
        : { widgets: DEFAULT_WIDGETS, layouts: DEFAULT_LAYOUTS };
  
      // If using defaults, save them to the backend
      if (!hasExistingConfig) {
        console.log('No existing config found, saving defaults...');
        await saveDashboardConfig(DEFAULT_WIDGETS, DEFAULT_LAYOUTS);
      }
  
      // Ensure we have layouts for all breakpoints and widgets
      const validLayouts = { ...configLayouts };
      const breakpoints = ['lg', 'md', 'sm', 'xs', 'xxs'];
      
      breakpoints.forEach(bp => {
        if (!validLayouts[bp]) validLayouts[bp] = [];
        
        // Ensure each widget has a layout position
        configWidgets.forEach(widget => {
          const hasLayout = validLayouts[bp].some(layout => layout.i === widget.instanceId);
          if (!hasLayout) {
            const widgetConfig = AVAILABLE_WIDGETS.find(w => w.id === widget.id);
            const defaultSize = widgetConfig?.defaultSize || { w: 6, h: 4 };
            const y = Math.max(0, ...validLayouts[bp].map(item => item.y + item.h));
            
            validLayouts[bp].push({
              i: widget.instanceId,
              x: 0,
              y,
              w: defaultSize.w,
              h: defaultSize.h,
              minW: 3,
              minH: 2
            });
          }
        });
      });
      
      // Ensure each widget has a settings object
      const widgetsWithSettings = configWidgets.map(widget => ({
        ...widget,
        settings: widget.settings || {} // Provide default settings if none exist
      }));
      
      setWidgets(widgetsWithSettings);
      setLayouts(validLayouts);

    } catch (error) {
      console.error('Failed to load dashboard config:', error);
      // On error, set defaults
      console.log('Error loading config, using defaults...');
      setWidgets(DEFAULT_WIDGETS);
      setLayouts(DEFAULT_LAYOUTS);
      // Try to save defaults to backend
      await saveDashboardConfig(DEFAULT_WIDGETS, DEFAULT_LAYOUTS);
    } finally {
      setLoading(false);

    }
  };

  const saveDashboardConfig = async (updatedWidgets, updatedLayouts) => {
    try {
      await axios.post(`${apiUrl}/api/dashboard/config`, {
        widgets: updatedWidgets,
        layouts: updatedLayouts
      });
    } catch (error) {
      console.error('Failed to save dashboard config:', error);
    }
  };

  const updateWidgetSettings = async (widgetInstanceId, newSettings) => {
    const updatedWidgets = widgets.map(widget => {
      if (widget.instanceId === widgetInstanceId) {
        return {
          ...widget,
          settings: {
            ...widget.settings,
            ...newSettings
          }
        };
      }
      return widget;
    });

    setWidgets(updatedWidgets);
    await saveDashboardConfig(updatedWidgets, layouts);
  };

  const addWidget = async (widgetId) => {
    const instanceId = `${widgetId}-${Date.now()}`;
    const newWidget = {
      id: widgetId,
      instanceId,
      settings: getDefaultSettingsForWidget(widgetId)
    };
  
    // Create new layouts with fixed dimensions
    const newLayouts = { ...layouts };
    const breakpoints = ['lg', 'md', 'sm', 'xs', 'xxs'];
    
    // Find the maximum y position across all existing widgets
    const getMaxY = (layoutArray) => {
      if (!layoutArray || layoutArray.length === 0) return 0;
      return Math.max(...layoutArray.map(item => item.y + item.h));
    };
  
    breakpoints.forEach(bp => {
      if (!newLayouts[bp]) newLayouts[bp] = [];
      
      const maxY = getMaxY(newLayouts[bp]);
      const maxCols = {
        lg: 24,
        md: 20,
        sm: 12,
        xs: 8,
        xxs: 4
      }[bp];
  
      const width = Math.min(12, maxCols);
      
      newLayouts[bp].push({
        i: instanceId,
        x: 0,
        y: maxY,
        w: width,
        h: 8,
        minW: 6,
        minH: 4
      });
    });
  
    const updatedWidgets = [...widgets, newWidget];
    setWidgets(updatedWidgets);
    setLayouts(newLayouts);
    await saveDashboardConfig(updatedWidgets, newLayouts);
  };

  const removeWidget = async (instanceId) => {
    const updatedWidgets = widgets.filter(w => w.instanceId !== instanceId);
    
    // Create new layouts with the widget removed
    const updatedLayouts = {};
    Object.keys(layouts).forEach(breakpoint => {
      updatedLayouts[breakpoint] = layouts[breakpoint].filter(
        layout => layout.i !== instanceId
      );
    });
  
    setWidgets(updatedWidgets);
    setLayouts(updatedLayouts);
    await saveDashboardConfig(updatedWidgets, updatedLayouts);
  };

  // Helper function to get default settings for each widget type
  const getDefaultSettingsForWidget = (widgetId) => {
    const defaults = {
      'recent-jobs': {
        limit: 5
      },
      // Add defaults for other widget types here
    };
    return defaults[widgetId] || {};
  };

  // Filter out widgets that are already on the dashboard
  const availableWidgets = AVAILABLE_WIDGETS.filter(
    available => !widgets.some(widget => widget.id === available.id)
  );

  const value = {
    widgets,
    layouts,
    loading,
    addWidget,
    removeWidget,
    updateWidgetSettings,
    loadDashboardConfig,
    saveDashboardConfig,
    availableWidgets,
    AVAILABLE_WIDGETS,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};