import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  Skeleton,
  Text,
  HStack,
  VStack,
  useToken
} from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { biaColors } from '../../../styles/colors.jsx';

const apiUrl = process.env.REACT_APP_API_URL;

const CommodityComparisonWidget = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('30');
  
  // Get Chakra UI colors for consistent theming
  //const [green500, blue500] = useToken('colors', ['green.500', 'red.500']);

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/dashboard/commodity-comparison`, {
        params: { days: timeRange }
      });
      setData(response.data);
    } catch (err) {
      setError('Failed to load commodity comparison data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timeRange]);

  if (loading) {
    return <Skeleton height="300px" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  // Sort data by total number of sets for better visualization
  const sortedData = [...data].sort((a, b) => 
    (b.typical_count + b.atypical_count) - (a.typical_count + a.atypical_count)
  );

  const plotData = [
    {
      name: 'Typical',
      type: 'bar',
      x: sortedData.map(item => item.commodity),
      y: sortedData.map(item => item.typical_count),
      marker: {
        color: biaColors.customGreen
      },
      hovertemplate: '%{y} (%{text}%)<extra></extra>',
      text: sortedData.map(item => {
        const total = item.typical_count + item.atypical_count;
        return ((item.typical_count / total) * 100).toFixed(1);
      })
    },
    {
      name: 'Atypical',
      type: 'bar',
      x: sortedData.map(item => item.commodity),
      y: sortedData.map(item => item.atypical_count),
      marker: {
        color: biaColors.customRed
      },
      hovertemplate: '%{y} (%{text}%)<extra></extra>',
      text: sortedData.map(item => {
        const total = item.typical_count + item.atypical_count;
        return ((item.atypical_count / total) * 100).toFixed(1);
      })
    }
  ];

  const layout = {
    barmode: 'stack',
    autosize: true,
    height: 250,
    margin: {
      l: 50,
      r: 20,
      t: 30,
      b: 80
    },
    xaxis: {
      tickangle: 45,
      automargin: true
    },
    yaxis: {
      title: 'Number of Sets',
      gridcolor: '#E2E8F0'
    },
    legend: {
      orientation: 'h',
      yanchor: 'bottom',
      y: 1.02,
      xanchor: 'right',
      x: 1
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    showlegend: true,
    hovermode: 'x unified'
  };

  const config = {
    displayModeBar: false,
    responsive: true
  };

  return (
    <VStack spacing={4} align="stretch" h="100%">
      <HStack spacing={4}>
        <Text>Time Range:</Text>
        <Select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          size="sm"
          w="150px"
        >
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="90">Last 90 days</option>
        </Select>
      </HStack>
      
      <Box h="calc(100% - 40px)" minH="250px">
        <Plot
          data={plotData}
          layout={layout}
          config={config}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </VStack>
  );
};

export default CommodityComparisonWidget;