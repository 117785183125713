import React, { useState, useEffect } from "react";
import { VStack, useToast } from "@chakra-ui/react";
import { useAuth } from "../../../context/AuthContext";
import CustomerTable from "./CustomerTable";
import CustomerFilters from "./CustomerFilters";
import axios from "axios";

const CustomerManagement = () => {
  const { user } = useAuth();
  const toast = useToast();
  const isGlobalAdmin = user?.roles?.includes("GLOBAL_ADMIN");
  
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    company: isGlobalAdmin ? user.company_id : "",
    page: 1,
    pageSize: 10,
  });

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const params = {
        search: filters.search,
        page: filters.page,
        per_page: filters.pageSize,
      };

      if (!isGlobalAdmin) {
        params.company_id = user.company_id;
      } else if (filters.company) {
        params.company_id = filters.company;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/customers`,
        { params }
      );

      setCustomers(response.data.items);
      setTotalCount(response.data.total);
    } catch (error) {
      toast({
        title: "Error fetching customers",
        description: error.response?.data?.detail || "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [filters]);

  const handleUpdateCustomer = async (customerId, updates) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/customers/${customerId}`,
        updates
      );
      await fetchCustomers();
      toast({
        title: "Success",
        description: "Customer updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return true;
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || "Failed to update customer",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleStatusChange = async (customerId, action) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/customers/${customerId}/${action}`
      );
      await fetchCustomers();
      toast({
        title: "Success",
        description: `Customer ${action}d successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || `Failed to ${action} customer`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreateCustomer = async (customerData) => {
    try {
      if (!isGlobalAdmin) {
        customerData.company_id = user.company_id;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/customers`,
        customerData
      );
      await fetchCustomers();
      toast({
        title: "Success",
        description: "Customer created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return true;
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || "Failed to create customer",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  };

  const handlePageChange = (newPage) => {
    setFilters(prev => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setFilters(prev => ({ ...prev, pageSize: newPageSize, page: 1 }));
  };

  return (
    <VStack spacing={5} align="stretch">
      <CustomerFilters
        filters={filters}
        setFilters={setFilters}
        isGlobalAdmin={isGlobalAdmin}
        user={user}
      />
      <CustomerTable
        customers={customers}
        loading={loading}
        onUpdateCustomer={handleUpdateCustomer}
        onStatusChange={handleStatusChange}
        onCreateCustomer={handleCreateCustomer}
        isGlobalAdmin={isGlobalAdmin}
        currentUser={user}
        page={filters.page}
        pageSize={filters.pageSize}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </VStack>
  );
};

export default CustomerManagement;