import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Box,
  Text,
  Badge,
  IconButton,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  Button,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Edit, Save, Users, X } from "lucide-react";
import axios from "axios";
import ImageUpload from "./ImageUpload";
import { useAuth } from "../../../context/AuthContext";
import CompanyForm from "./CompanyForm";
import CompanyImage from "./CompanyImage";
import { biaColors } from "../../../styles/colors.jsx"

const CompanyDetails = ({ isOpen, onClose, company, onUpdate }) => {
  const [users, setUsers] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSections, setActiveSections] = useState({
    basic: false,
    contact: false,
    info: false,
    branding: false,
  });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();
  const { user } = useAuth();
  const toast = useToast();

  // Determine user permissions
  const isGlobalAdmin = user?.roles?.includes("GLOBAL_ADMIN");
  const isCompanyAdmin = user?.roles?.includes("COMPANY_ADMIN");
  const canEdit =
    isGlobalAdmin || (isCompanyAdmin && user?.company_id === company?.id);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (isOpen && company) {
      fetchData();
    }
  }, [isOpen, company]);

  const fetchData = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchCompanyUsers(), fetchCompanyDetails()]);
    } finally {
      setLoading(false);
    }
  };

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setIsAlertOpen(false);
    setActiveSections({
      basic: false,
      contact: false,
      info: false,
      branding: false,
    });
    onClose();
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSectionEdit = (section) => {
    setActiveSections((prev) => ({
      ...prev,
      [section]: true,
    }));
  };

  const handleSectionSave = async (section) => {
    try {
      // Get only the fields relevant to this section
      const sectionFields = {
        basic: [],
        contact: ["business_email", "telephone"],
        info: ["display_name", "company_number", "address"],
        branding: ["signature_name", "signature_job_title"],
      };

      const updateData = {};
      sectionFields[section].forEach((field) => {
        if (companyDetails[field] !== undefined) {
          updateData[field] = companyDetails[field];
        }
      });

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/companies/details/${company.id}`,
        updateData
      );

      setActiveSections((prev) => ({
        ...prev,
        [section]: false,
      }));

      toast({
        title: "Success",
        description: "Company details updated successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.detail || "Failed to update company details",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleModalClose = () => {
    const hasActiveSection = Object.values(activeSections).some(
      (section) => section
    );
    if (hasActiveSection) {
      setIsAlertOpen(true);
    } else {
      onClose();
    }
  };

  const handleFileUploadComplete = async (filePath, fileType) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/companies/details/${company.id}`,
        {
          [`${fileType}_path`]: filePath,
        }
      );
      setCompanyDetails(response.data);
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.detail || "Failed to update file path",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleEditClose = (skipRefresh = false) => {
    onEditClose();
    // Only refresh if explicitly requested (i.e., when closing without saving)
    if (!skipRefresh) {
      fetchCompanyDetails();
    }
  };

  const handleEditSubmit = async (values) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/companies/${company.id}`,
        values
      );

      // Fetch fresh company details
      await fetchCompanyDetails();

      // Call parent's onUpdate to refresh the company list
      await onUpdate();

      onEditClose();
      toast({
        title: "Success",
        description: "Company updated successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.detail || "Failed to update company",
        status: "error",
        duration: 5000,
      });
    }
  };

  const fetchCompanyUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users`,
        {
          params: { company_id: company.id },
        }
      );
      setUsers(response.data);
    } catch (error) {
      toast({
        title: "Error fetching users",
        description: error.response?.data?.detail || "Failed to fetch users",
        status: "error",
        duration: 5000,
      });
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/companies/details/${company.id}`
      );
      setCompanyDetails(response.data);
    } catch (error) {
      toast({
        title: "Error fetching company details",
        description:
          error.response?.data?.detail || "Failed to fetch company details",
        status: "error",
        duration: 5000,
      });
    }
  };

  const ReadOnlyField = ({ label, value, isMultiline = false }) => (
    <FormControl>
      <FormLabel fontWeight="medium">{label}</FormLabel>
      <Box
        p={3}
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <Text color="gray.900" whiteSpace={isMultiline ? "pre-line" : "normal"}>
          {value || "Not set"}
        </Text>
      </Box>
    </FormControl>
  );

  const ActionIcon = memo(
    ({
      icon: Icon,
      onClick,
      label,
      colorScheme = "gray",
      mr,
      tooltipLabel,
    }) => {
      const colorMap = {
        gray: {
          bg: "transparent",
          hoverBg: "gray.100",
          color: "gray.600",
        },
        green: {
          bg: biaColors.customGreen,
          hoverBg: biaColors.customGreenHover,
          color: "white",
        },
      };

      const colors = colorMap[colorScheme];

      return (
        <Tooltip label={tooltipLabel} placement="top" hasArrow>
          <Box
            as="div"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            w="32px"
            h="32px"
            borderRadius="md"
            bg={colors.bg}
            color={colors.color}
            cursor="pointer"
            transition="all 0.2s"
            onClick={onClick}
            mr={mr}
            _hover={{
              bg: colors.hoverBg,
            }}
            role="button"
            aria-label={label}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                onClick(e);
              }
            }}
          >
            <Icon size={16} />
          </Box>
        </Tooltip>
      );
    }
  );

  const AccordionHeader = memo(({ title, children }) => {
    const handleChildClick = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
    }, []);

    return (
      <AccordionButton>
        <Box flex="1" textAlign="left" fontWeight="bold">
          {title}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          onClick={handleChildClick}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {children}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      size="2xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent maxHeight="90vh" overflowY="auto">
        <ModalHeader>
          <HStack spacing={4} align="center">
            {companyDetails?.logo_path && (
              <Box width="150px" height="16px" flexShrink={0}>
                <CompanyImage
                  company={company}
                  fileType="logo"
                  aspectRatio="450/80"
                  maxWidth="150px"
                />
              </Box>
            )}
            <HStack spacing={2} align="center">
              <Text>{company?.name}</Text>
              {isGlobalAdmin && (
                <Tooltip
                  label="Edit company settings"
                  placement="top"
                  isOpen={isTooltipOpen}
                  hasArrow
                >
                  <IconButton
                    icon={<Edit size={16} />}
                    onClick={onEditOpen}
                    onMouseEnter={() => setIsTooltipOpen(true)}
                    onMouseLeave={() => setIsTooltipOpen(false)}
                    size="sm"
                    variant="ghost"
                    aria-label="Edit company settings"
                  />
                </Tooltip>
              )}
            </HStack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton onClick={handleModalClose} />
        <ModalBody>
          <Accordion defaultIndex={[0]} allowMultiple>
            {/* Basic Information */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Basic Information
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <VStack align="stretch" spacing={4}>
                  <ReadOnlyField label="Domain" value={company?.email_domain} />
                  <HStack>
                    <Text fontWeight="medium">Status:</Text>
                    <Badge bg={company?.is_active ? biaColors.customGreen : biaColors.customRed} color="white">
                      {company?.is_active ? "Active" : "Inactive"}
                    </Badge>
                  </HStack>
                  <ReadOnlyField
                    label="Company Contact Name"
                    value={company?.contact_name}
                  />
                  <ReadOnlyField
                    label="Company Contact Email"
                    value={company?.contact_email}
                  />
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            {/* Contact Information */}
            <AccordionItem>
              <AccordionHeader title="Contact Information">
                {canEdit && (
                  <Box display="flex" alignItems="center" mr={2}>
                    {activeSections.contact ? (
                      <>
                        <ActionIcon
                          icon={Save}
                          bg={biaColors.customGreen}
                          color="white"
                          onClick={() => handleSectionSave("contact")}
                          mr={2}
                          label="Save changes"
                          tooltipLabel="Save changes"
                        />
                        <ActionIcon
                          icon={X}
                          onClick={() => {
                            setActiveSections((prev) => ({
                              ...prev,
                              contact: false,
                            }));
                            fetchCompanyDetails();
                          }}
                          label="Cancel changes"
                          tooltipLabel="Cancel changes"
                        />
                      </>
                    ) : (
                      <ActionIcon
                        icon={Edit}
                        onClick={() => handleSectionEdit("contact")}
                        label="Edit contact information"
                        tooltipLabel="Edit contact information"
                      />
                    )}
                  </Box>
                )}
              </AccordionHeader>
              <AccordionPanel>
                <VStack align="stretch" spacing={4}>
                  {activeSections.contact ? (
                    <>
                      <FormControl>
                        <FormLabel fontWeight="medium">
                          Business Email
                        </FormLabel>
                        <Input
                          name="business_email"
                          value={companyDetails?.business_email || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter business email"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="medium">Telephone</FormLabel>
                        <Input
                          name="telephone"
                          value={companyDetails?.telephone || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter telephone"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <ReadOnlyField
                        label="Business Email"
                        value={companyDetails?.business_email}
                      />
                      <ReadOnlyField
                        label="Telephone"
                        value={companyDetails?.telephone}
                      />
                    </>
                  )}
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            {/* Company Information */}
            <AccordionItem>
              <AccordionHeader title="Company Information">
                {canEdit && (
                  <Box display="flex" alignItems="center" mr={2}>
                    {activeSections.info ? (
                      <>
                        <ActionIcon
                          icon={Save}
                          bg={biaColors.customGreen}
                          color="white"
                          onClick={() => handleSectionSave("info")}
                          mr={2}
                          label="Save changes"
                          tooltipLabel="Save changes"
                        />
                        <ActionIcon
                          icon={X}
                          onClick={() => {
                            setActiveSections((prev) => ({
                              ...prev,
                              info: false,
                            }));
                            fetchCompanyDetails();
                          }}
                          label="Cancel changes"
                          tooltipLabel="Cancel changes"
                        />
                      </>
                    ) : (
                      <ActionIcon
                        icon={Edit}
                        onClick={() => handleSectionEdit("info")}
                        label="Edit company information"
                        tooltipLabel="Edit company information"
                      />
                    )}
                  </Box>
                )}
              </AccordionHeader>
              <AccordionPanel>
                <VStack align="stretch" spacing={4}>
                  {activeSections.info ? (
                    <>
                      <FormControl>
                        <FormLabel fontWeight="medium">Display Name</FormLabel>
                        <Input
                          name="display_name"
                          value={companyDetails?.display_name || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter display name"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="medium">
                          Company Number
                        </FormLabel>
                        <Input
                          name="company_number"
                          value={companyDetails?.company_number || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter company number"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="medium">Address</FormLabel>
                        <Textarea
                          name="address"
                          value={companyDetails?.address || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter address"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <ReadOnlyField
                        label="Display Name"
                        value={companyDetails?.display_name}
                      />
                      <ReadOnlyField
                        label="Company Number"
                        value={companyDetails?.company_number}
                      />
                      <ReadOnlyField
                        label="Address"
                        value={companyDetails?.address}
                        isMultiline
                      />
                    </>
                  )}
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            {/* Branding & Signature */}
            <AccordionItem>
              <AccordionHeader title="Branding & Signature">
                {canEdit && (
                  <Box display="flex" alignItems="center" mr={2}>
                    {activeSections.branding ? (
                      <>
                        <ActionIcon
                          icon={Save}
                          bg={biaColors.customGreen}
                          color="white"
                          onClick={() => handleSectionSave("branding")}
                          mr={2}
                          label="Save changes"
                          tooltipLabel="Save changes"
                        />
                        <ActionIcon
                          icon={X}
                          onClick={() => {
                            setActiveSections((prev) => ({
                              ...prev,
                              branding: false,
                            }));
                            fetchCompanyDetails();
                          }}
                          label="Cancel changes"
                          tooltipLabel="Cancel changes"
                        />
                      </>
                    ) : (
                      <ActionIcon
                        icon={Edit}
                        onClick={() => handleSectionEdit("branding")}
                        label="Edit branding and signature"
                        tooltipLabel="Edit branding and signature"
                      />
                    )}
                  </Box>
                )}
              </AccordionHeader>
              <AccordionPanel>
                <VStack align="stretch" spacing={6}>
                  <Box>
                    <Text fontWeight="medium" mb={2}>
                      Company Logo (450x80)
                    </Text>
                    <ImageUpload
                      company={company}
                      fileType="logo"
                      aspectRatio="450/80"
                      maxWidth="450px"
                      currentPath={companyDetails?.logo_path}
                      onUploadComplete={(path) =>
                        handleFileUploadComplete(path, "logo")
                      }
                    />
                  </Box>

                  <Box>
                    <Text fontWeight="medium" mb={2}>
                      Signature (250x80)
                    </Text>
                    <ImageUpload
                      company={company}
                      fileType="signature"
                      aspectRatio="250/80"
                      maxWidth="250px"
                      currentPath={companyDetails?.signature_path}
                      onUploadComplete={(path) =>
                        handleFileUploadComplete(path, "signature")
                      }
                    />
                  </Box>

                  {activeSections.branding ? (
                    <>
                      <FormControl>
                        <FormLabel fontWeight="medium">
                          Signature Name
                        </FormLabel>
                        <Input
                          name="signature_name"
                          value={companyDetails?.signature_name || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter signature name"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="medium">Job Title</FormLabel>
                        <Input
                          name="signature_job_title"
                          value={companyDetails?.signature_job_title || ""}
                          onChange={handleDetailsChange}
                          placeholder="Enter job title"
                          size="md"
                          borderRadius="md"
                          bg="white"
                          _hover={{ borderColor: "blue.500" }}
                          _focus={{
                            borderColor: "blue.500",
                            boxShadow: "outline",
                          }}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <ReadOnlyField
                        label="Signature Name"
                        value={companyDetails?.signature_name}
                      />
                      <ReadOnlyField
                        label="Job Title"
                        value={companyDetails?.signature_job_title}
                      />
                    </>
                  )}
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            {/* Users */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Company Users
                </Box>
                <Tooltip label="View company users" placement="top">
                  <Box mr={2}>
                    <Users size={16} />
                  </Box>
                </Tooltip>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Box>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Roles</Th>
                        <Th>Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {users.map((user) => (
                        <Tr key={user.cognito}>
                          <Td>{user.name}</Td>
                          <Td>{user.email}</Td>
                          <Td>
                            {user.roles.map((role) => (
                              <Badge key={role} bg={biaColors.customBlue} color="white" mr={1}>
                                {role.replace("_", " ")}
                              </Badge>
                            ))}
                          </Td>
                          <Td>
                            <Badge
                              bg={
                                user.status === "ACTIVE" ? biaColors.customGreen: biaColors.customRed
                              }
                              color="white"
                            >
                              {user.status}
                            </Badge>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </ModalContent>

      {canEdit && isEditOpen && (
        <CompanyForm
          isOpen={isEditOpen}
          onClose={handleEditClose}
          onSubmit={handleEditSubmit}
          initialValues={company}
        />
      )}

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unsaved Changes
            </AlertDialogHeader>

            <AlertDialogBody>
              You have unsaved changes in one or more sections. If you close
              now, all changes will be lost. Are you sure you want to continue?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCloseAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmClose} ml={3}>
                Close Without Saving
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Modal>
  );
};

export default CompanyDetails;
