import React, { useState } from 'react';
import { Box, Button, VStack, useToast } from '@chakra-ui/react';
import { Upload } from 'lucide-react';
import axios from 'axios';
import CompanyImage from './CompanyImage';

const ImageUpload = ({
  company,
  fileType, // 'logo' or 'signature'
  aspectRatio,
  maxWidth,
  currentPath,
  onUploadComplete,
}) => {
  const [uploading, setUploading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // Add refresh key state
  const toast = useToast();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      toast({
        title: 'Invalid file type',
        description: 'Please select a JPEG or PNG file',
        status: 'error',
        duration: 5000,
      });
      return;
    }

    // Validate file size (5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast({
        title: 'File too large',
        description: 'Maximum file size is 5MB',
        status: 'error',
        duration: 5000,
      });
      return;
    }

    try {
      setUploading(true);

      // Get presigned URL
      const { data: uploadData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/companies/details/${company.id}/upload-url`,
        {
          filename: file.name,
          content_type: file.type,
        },
        {
          params: { file_type: fileType },
        }
      );

      // Create separate axios instance for S3
      const s3Request = axios.create();
      delete s3Request.defaults.headers.common['Authorization'];

      // Upload to S3
      await s3Request.put(uploadData.presigned_url, file, {
        headers: {
          'Content-Type': file.type,
        },
        transformRequest: [(data) => data],
      });

      // Update the database with the new file path
      const updateResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/companies/details/${company.id}/file-path`,
        {
          file_type: fileType,
          file_path: uploadData.file_path,
        }
      );

      // Increment refresh key to trigger reload of CompanyImage
      setRefreshKey(prev => prev + 1);

      // Call the parent's callback with the updated company details
      onUploadComplete(updateResponse.data);

      toast({
        title: 'Upload successful',
        description: 'File uploaded and details updated successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error('Upload error:', error);
      let errorMessage = 'Failed to upload file';

      if (error.response?.data) {
        errorMessage = error.response.data.detail || error.response.data;
      } else if (error.message) {
        errorMessage = error.message;
      }

      toast({
        title: 'Upload failed',
        description: errorMessage,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <CompanyImage
        company={company}
        fileType={fileType}
        aspectRatio={aspectRatio}
        maxWidth={maxWidth}
        refreshKey={refreshKey} // Pass the refresh key to CompanyImage
      />
      <Button
        leftIcon={<Upload size={16} />}
        size="sm"
        isLoading={uploading}
        onClick={() => document.getElementById(`${fileType}-upload`).click()}
        colorScheme="blue"
        variant="outline"
      >
        {currentPath ? `Change ${fileType}` : `Upload ${fileType}`}
      </Button>
      <input
        id={`${fileType}-upload`}
        type="file"
        accept="image/jpeg,image/png"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </VStack>
  );
};

export default ImageUpload;