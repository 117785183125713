// src/components/dashboard/widgets/WidgetWrapper.js
import React from "react";
import {
  Box,
  Heading,
  IconButton,
  HStack,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useDashboard } from "../../../modules/dashboard/DashboardContext";
import RecentJobsWidget from "./RecentJobsWidget";
import CommodityStatsWidget from "./CommodityStatsWidget";
import DeviationWidget from "./DeviationWidget";
import JobSuccessRateWidget from "./JobSuccessRateWidget";
import NavigationWidget from "./NavigationWidget";
import CommodityComparisonWidget from "./CommodityComparisonWidget";
import TrendsWidget from "./TrendsWidget";
import { biaColors } from "../../../styles/colors";

const WIDGET_COMPONENTS = {
  "recent-jobs": RecentJobsWidget,
  "commodity-stats": CommodityStatsWidget,
  "deviation-analysis": DeviationWidget,
  "job-success-rate": JobSuccessRateWidget,
  navigation: NavigationWidget,
  "commodity-comparison": CommodityComparisonWidget,
  trends: TrendsWidget,
};

const WidgetWrapper = ({ widget, setIsDragging, isEditMode }) => {
  const { removeWidget } = useDashboard();
  const WidgetComponent = WIDGET_COMPONENTS[widget.id];
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("white", "gray.700");

  if (!WidgetComponent) {
    return null;
  }

  return (
    <Box
      h="100%"
      bg={bgColor}
      borderRadius="lg"
      border="1px solid"
      borderColor={borderColor}
      position="relative"
      onMouseEnter={() => setIsDragging(false)}
      onMouseLeave={() => setIsDragging(false)}
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{
        boxShadow: isEditMode ? "md" : "sm",
      }}
    >
      <HStack
        px={4}
        py={2}
        borderBottomWidth="1px"
        borderBottomColor={borderColor}
        justify="space-between"
        className={`widget-header ${isEditMode ? "draggable-header" : ""}`} // Changed this line
        bg={isEditMode ? "blue.50" : "transparent"}
        borderTopRadius="lg"
        transition="background-color 0.2s"
        position="relative"
      >
        <Tooltip
          label={isEditMode ? "Drag to move widget" : widget.title}
          placement="top"
          isDisabled={!isEditMode}
        >
          <Heading size="sm" cursor={isEditMode ? "grab" : "default"}>
            {widget.title}
          </Heading>
        </Tooltip>
        {isEditMode && (
          <IconButton
            size="sm"
            icon={<CloseIcon />}
            aria-label="Remove widget"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation(); // Stop event from bubbling up
              removeWidget(widget.instanceId);
            }}
            bg={biaColors.customRed}
            color="white"
          />
        )}
      </HStack>

      <Box
        p={4}
        h="calc(100% - 45px)"
        overflow="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.300",
            borderRadius: "24px",
          },
        }}
      >
        <WidgetComponent widget={widget} />
      </Box>
    </Box>
  );
};

export default WidgetWrapper;
