// src/components/Auth/AuthPage.js
import React, { useState } from 'react';
import {
  Box,
  Container,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Card,
  CardBody,
  Link,
  PinInput,
  PinInputField,
  HStack,
} from '@chakra-ui/react';
import { useAuth } from '../../context/AuthContext';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import logo from '../../assets/Bia.jpg';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { biaColors } from '../../styles/colors';

const AuthPage = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState('email'); // email -> code -> newPassword
  const { signIn, signUp, confirmSignUp } = useAuth();
  const toast = useToast();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });

  const signupSchema = loginSchema.shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });

  const verificationSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/^\d{6}$/, 'Must be exactly 6 digits')
      .required('Required'),
  });
  
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const resetPasswordSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/^\d{6}$/, 'Must be exactly 6 digits')
      .required('Required'),
    newPassword: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required'),
  });

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      await signIn(values.email, values.password);
      toast({
        title: 'Login successful',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignUp = async (values, { setSubmitting }) => {
    try {
      await signUp(values);
      setVerificationEmail(values.email);
      setNeedsVerification(true);
      toast({
        title: 'Sign up successful',
        description: 'Please check your email for verification code',
        status: 'success',
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleVerification = async (values, { setSubmitting }) => {
    try {
      await confirmSignUp(verificationEmail, values.code);
      setNeedsVerification(false);
      setIsSignUp(false);
      toast({
        title: 'Verification successful',
        description: 'You can now log in',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgotPassword = async (values, { setSubmitting }) => {
    try {
      await resetPassword({ username: values.email });
      setVerificationEmail(values.email);
      setForgotPasswordStep('code');
      toast({
        title: 'Code sent',
        description: 'Please check your email for the verification code',
        status: 'success',
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleResetPassword = async (values, { setSubmitting }) => {
    try {
      await confirmResetPassword({
        username: verificationEmail,
        confirmationCode: values.code,
        newPassword: values.newPassword,
      });
      toast({
        title: 'Password reset successful',
        description: 'You can now login with your new password',
        status: 'success',
        duration: 5000,
      });
      setIsForgotPassword(false);
      setForgotPasswordStep('email');
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (needsVerification) {
    return (
      <Container maxW="md" centerContent py={8}>
        <VStack spacing={8} w="full">
          <Image src={logo} alt="Logo" h="100px" />
          <Card w="full">
            <CardBody>
              <VStack spacing={6}>
                <Text fontSize="2xl" fontWeight="bold">Verify Your Email</Text>
                <Formik
                  initialValues={{ code: '' }}
                  validationSchema={verificationSchema}
                  onSubmit={handleVerification}
                >
                  {({ isSubmitting, errors, touched, getFieldProps }) => (
                    <Form style={{ width: '100%' }}>
                      <VStack spacing={4}>
                        <FormControl isInvalid={errors.code && touched.code}>
                          <FormLabel>Verification Code</FormLabel>
                          <Input {...getFieldProps('code')} placeholder="Enter 6-digit code" />
                          {errors.code && touched.code && (
                            <Text color="red.500" fontSize="sm">{errors.code}</Text>
                          )}
                        </FormControl>
                        <Button
                          colorScheme="blue"
                          width="full"
                          type="submit"
                          isLoading={isSubmitting}
                        >
                          Verify Email
                        </Button>
                      </VStack>
                    </Form>
                  )}
                </Formik>
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Container>
    );
  }

  if (isForgotPassword) {
    return (
      <Container maxW="md" centerContent py={8}>
        <VStack spacing={8} w="full">
          <Image src={logo} alt="Logo" h="100px" />
          <Card w="full">
            <CardBody>
              <VStack spacing={6}>
                <Text fontSize="2xl" fontWeight="bold">Reset Password</Text>
                {forgotPasswordStep === 'email' && (
                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={handleForgotPassword}
                  >
                    {({ isSubmitting, errors, touched, getFieldProps }) => (
                      <Form style={{ width: '100%' }}>
                        <VStack spacing={4}>
                          <Text fontSize="sm" color="gray.600">
                            Enter your email address and we'll send you a verification code.
                          </Text>
                          <FormControl isInvalid={errors.email && touched.email}>
                            <FormLabel>Email</FormLabel>
                            <Input {...getFieldProps('email')} type="email" />
                            {errors.email && touched.email && (
                              <Text color="red.500" fontSize="sm">{errors.email}</Text>
                            )}
                          </FormControl>
                          <Button
                            colorScheme="blue"
                            width="full"
                            type="submit"
                            isLoading={isSubmitting}
                          >
                            Send Code
                          </Button>
                          <Button
                            variant="ghost"
                            width="full"
                            onClick={() => setIsForgotPassword(false)}
                          >
                            Back to Login
                          </Button>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                )}

                {forgotPasswordStep === 'code' && (
                  <Formik
                    initialValues={{ code: '', newPassword: '', confirmPassword: '' }}
                    validationSchema={resetPasswordSchema}
                    onSubmit={handleResetPassword}
                  >
                    {({ isSubmitting, errors, touched, getFieldProps, setFieldValue }) => (
                      <Form style={{ width: '100%' }}>
                        <VStack spacing={4}>
                          <Text fontSize="sm" color="gray.600">
                            Enter the verification code sent to {verificationEmail}
                          </Text>
                          <FormControl isInvalid={errors.code && touched.code}>
                            <FormLabel>Verification Code</FormLabel>
                            <HStack justifyContent="center">
                              <PinInput
                                otp
                                onChange={(value) => setFieldValue('code', value)}
                              >
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                              </PinInput>
                            </HStack>
                            {errors.code && touched.code && (
                              <Text color="red.500" fontSize="sm">{errors.code}</Text>
                            )}
                          </FormControl>
                          <FormControl isInvalid={errors.newPassword && touched.newPassword}>
                            <FormLabel>New Password</FormLabel>
                            <Input {...getFieldProps('newPassword')} type="password" />
                            {errors.newPassword && touched.newPassword && (
                              <Text color="red.500" fontSize="sm">{errors.newPassword}</Text>
                            )}
                          </FormControl>
                          <FormControl isInvalid={errors.confirmPassword && touched.confirmPassword}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input {...getFieldProps('confirmPassword')} type="password" />
                            {errors.confirmPassword && touched.confirmPassword && (
                              <Text color="red.500" fontSize="sm">{errors.confirmPassword}</Text>
                            )}
                          </FormControl>
                          <Button
                            colorScheme="blue"
                            width="full"
                            type="submit"
                            isLoading={isSubmitting}
                          >
                            Reset Password
                          </Button>
                          <Button
                            variant="ghost"
                            width="full"
                            onClick={() => setForgotPasswordStep('email')}
                          >
                            Back
                          </Button>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                )}
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Container>
    );
  }


  return (
    <Container maxW="md" centerContent py={8}>
      <VStack spacing={8} w="full">
        <Image src={logo} alt="Logo" h="100px" />
        <Card w="full">
          <CardBody>
            <VStack spacing={6}>
              <Text fontSize="2xl" fontWeight="bold">
                {isSignUp ? 'Create Account' : 'Welcome Back'}
              </Text>
              <Tabs isFitted variant="enclosed" index={isSignUp ? 1 : 0} onChange={(index) => setIsSignUp(index === 1)} width="100%">
                <TabList mb="1em">
                  <Tab>Login</Tab>
                  <Tab>Sign Up</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Formik
                      initialValues={{ email: '', password: '' }}
                      validationSchema={loginSchema}
                      onSubmit={handleLogin}
                    >
                      {({ isSubmitting, errors, touched, getFieldProps }) => (
                        <Form>
                          <VStack spacing={4}>
                            <FormControl isInvalid={errors.email && touched.email}>
                              <FormLabel>Email</FormLabel>
                              <Input {...getFieldProps('email')} type="email" />
                              {errors.email && touched.email && (
                                <Text color="red.500" fontSize="sm">{errors.email}</Text>
                              )}
                            </FormControl>
                            <FormControl isInvalid={errors.password && touched.password}>
                              <FormLabel>Password</FormLabel>
                              <Input {...getFieldProps('password')} type="password" />
                              {errors.password && touched.password && (
                                <Text color="red.500" fontSize="sm">{errors.password}</Text>
                              )}
                            </FormControl>
                            <Button
                              colorScheme="blue"
                              width="full"
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Sign In
                            </Button>
                            <Link
                              color={biaColors.customBlue}
                              onClick={() => setIsForgotPassword(true)}
                              textAlign="center"
                              width="full"
                              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              Forgot Password?
                            </Link>
                          </VStack>
                        </Form>
                      )}
                    </Formik>
                  </TabPanel>
                  <TabPanel>
                    <Formik
                      initialValues={{
                        email: '',
                        password: '',
                        confirmPassword: '',
                        firstName: '',
                        lastName: '',
                      }}
                      validationSchema={signupSchema}
                      onSubmit={handleSignUp}
                    >
                      {({ isSubmitting, errors, touched, getFieldProps }) => (
                        <Form>
                          <VStack spacing={4}>
                            <FormControl isInvalid={errors.firstName && touched.firstName}>
                              <FormLabel>First Name</FormLabel>
                              <Input {...getFieldProps('firstName')} />
                              {errors.firstName && touched.firstName && (
                                <Text color="red.500" fontSize="sm">{errors.firstName}</Text>
                              )}
                            </FormControl>
                            <FormControl isInvalid={errors.lastName && touched.lastName}>
                              <FormLabel>Last Name</FormLabel>
                              <Input {...getFieldProps('lastName')} />
                              {errors.lastName && touched.lastName && (
                                <Text color="red.500" fontSize="sm">{errors.lastName}</Text>
                              )}
                            </FormControl>
                            <FormControl isInvalid={errors.email && touched.email}>
                              <FormLabel>Email</FormLabel>
                              <Input {...getFieldProps('email')} type="email" />
                              {errors.email && touched.email && (
                                <Text color="red.500" fontSize="sm">{errors.email}</Text>
                              )}
                            </FormControl>
                            <FormControl isInvalid={errors.password && touched.password}>
                              <FormLabel>Password</FormLabel>
                              <Input {...getFieldProps('password')} type="password" />
                              {errors.password && touched.password && (
                                <Text color="red.500" fontSize="sm">{errors.password}</Text>
                              )}
                            </FormControl>
                            <FormControl isInvalid={errors.confirmPassword && touched.confirmPassword}>
                              <FormLabel>Confirm Password</FormLabel>
                              <Input {...getFieldProps('confirmPassword')} type="password" />
                              {errors.confirmPassword && touched.confirmPassword && (
                                <Text color="red.500" fontSize="sm">{errors.confirmPassword}</Text>
                              )}
                            </FormControl>
                            <Button
                              colorScheme="blue"
                              width="full"
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Sign Up
                            </Button>
                          </VStack>
                        </Form>
                      )}
                    </Formik>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Container>
  );
};

export default AuthPage;