import React, { useState, useEffect } from 'react';
import {
  HStack,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Search } from 'lucide-react';
import axios from 'axios';

const CustomerFilters = ({ filters, setFilters, isGlobalAdmin, user }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (isGlobalAdmin) {
      const fetchCompanies = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/companies`);
          setCompanies(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
      fetchCompanies();
    }
  }, [isGlobalAdmin]);

  return (
    <HStack spacing={4}>
      <InputGroup maxW="300px">
        <InputLeftElement>
          <Search size={20} />
        </InputLeftElement>
        <Input
          placeholder="Search customers..."
          value={filters.search}
          onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
        />
      </InputGroup>

      {isGlobalAdmin && (
        <Select
          value={filters.company}
          onChange={(e) => setFilters(prev => ({ ...prev, company: e.target.value, page: 1 }))}
          maxW="250px"
        >
          <option value="">All Companies</option>
          {companies.map(company => (
            <option key={company.id} value={company.id}>
              {company.name}{user?.company_id === company.id ? " (My Company)" : ""}
            </option>
          ))}
        </Select>
      )}
    </HStack>
  );
};

export default CustomerFilters;