import { Alert, AlertIcon, Text, VStack, HStack } from "@chakra-ui/react";

// Core utility functions
const getBaseFileName = (filename) => {
  const withoutExtension = filename.replace(/\.[^/.]+$/, "");
  return withoutExtension.slice(0, -1);
};

const isValidSuffix = (filename) => {
  const suffix = filename
    .replace(/\.[^/.]+$/, "")
    .slice(-1)
    .toLowerCase();
  return ["a", "b", "c"].includes(suffix);
};

export const validateAndGroupFiles = (files) => {
  const errors = [];
  const groupedSets = [];
  const filesByBase = {};

  // First pass: group files by base name
  files.forEach((file) => {
    if (!isValidSuffix(file.name)) {
      errors.push(
        `File "${file.name}" does not end with a valid suffix (a, b, or c)`
      );
      return;
    }

    const baseName = getBaseFileName(file.name);
    const isControl = file.name.toLowerCase().includes("ctrl");

    if (!filesByBase[baseName]) {
      filesByBase[baseName] = {
        control: [],
        sample: [],
      };
    }

    if (isControl) {
      filesByBase[baseName].control.push(file);
    } else {
      filesByBase[baseName].sample.push(file);
    }
  });

  // Second pass: validate sets and organize them
  Object.entries(filesByBase).forEach(([baseName, group]) => {
    // Validate control files
    if (group.control.length > 0 && group.control.length !== 3) {
      errors.push(
        `Incomplete control set for "${baseName}": Found ${group.control.length} files, need exactly 3`
      );
    }

    // Validate sample files
    if (group.sample.length > 0 && group.sample.length !== 3) {
      errors.push(
        `Incomplete sample set for "${baseName}": Found ${group.sample.length} files, need exactly 3`
      );
    }

    // Validate suffixes for control files
    if (group.control.length === 3) {
      const suffixes = group.control
        .map((f) =>
          f.name
            .replace(/\.[^/.]+$/, "")
            .slice(-1)
            .toLowerCase()
        )
        .sort();

      if (suffixes.join("") !== "abc") {
        errors.push(
          `Invalid suffixes for control set "${baseName}": Found ${suffixes.join(
            ", "
          )}, need exactly a, b, c`
        );
      } else {
        group.control.sort((a, b) => {
          const suffixA = a.name
            .replace(/\.[^/.]+$/, "")
            .slice(-1)
            .toLowerCase();
          const suffixB = b.name
            .replace(/\.[^/.]+$/, "")
            .slice(-1)
            .toLowerCase();
          return suffixA.localeCompare(suffixB);
        });
      }
    }

    // Validate suffixes for sample files
    if (group.sample.length === 3) {
      const suffixes = group.sample
        .map((f) =>
          f.name
            .replace(/\.[^/.]+$/, "")
            .slice(-1)
            .toLowerCase()
        )
        .sort();

      if (suffixes.join("") !== "abc") {
        errors.push(
          `Invalid suffixes for sample set "${baseName}": Found ${suffixes.join(
            ", "
          )}, need exactly a, b, c`
        );
      } else {
        group.sample.sort((a, b) => {
          const suffixA = a.name
            .replace(/\.[^/.]+$/, "")
            .slice(-1)
            .toLowerCase();
          const suffixB = b.name
            .replace(/\.[^/.]+$/, "")
            .slice(-1)
            .toLowerCase();
          return suffixA.localeCompare(suffixB);
        });
      }
    }

    // Add valid sets to groupedSets
    if (
      (group.control.length === 3 || group.control.length === 0) &&
      (group.sample.length === 3 || group.sample.length === 0)
    ) {
      groupedSets.push({
        baseName,
        control: group.control,
        sample: group.sample,
      });
    }
  });

  return {
    isValid: errors.length === 0,
    errors,
    fileSets: groupedSets,
    hasControlSets: groupedSets.some((set) => set.control.length > 0),
    hasSampleSets: groupedSets.some((set) => set.sample.length > 0),
  };
};

// Reusable UI components for showing file status
export const FileSetStatus = ({ set }) => {
  const isComplete = set.length === 3;
  const suffixes = set
    .map((f) =>
      f.name
        .replace(/\.[^/.]+$/, "")
        .slice(-1)
        .toLowerCase()
    )
    .sort();
  const missingSuffixes = ["a", "b", "c"].filter((s) => !suffixes.includes(s));

  if (!isComplete) {
    return (
      <Alert status="warning" size="sm">
        <VStack align="start" spacing={0}>
          <Text fontWeight="medium">Missing {3 - set.length} file(s):</Text>
          <Text>Need suffixes: {missingSuffixes.join(", ")}</Text>
        </VStack>
      </Alert>
    );
  }

  if (suffixes.join("") !== "abc") {
    return (
      <Alert status="error" size="sm">
        <Text>
          Invalid suffixes: {suffixes.join(", ")}
          <br />
          Need exactly: a, b, c
        </Text>
      </Alert>
    );
  }

  return (
    <Alert status="success" size="sm">
      <Text>Complete set (a, b, c)</Text>
    </Alert>
  );
};

// Reusable validation message component
export const ValidationMessage = ({
  commodityHasValidControl,
  hasControlSets,
}) => {
  if (commodityHasValidControl && hasControlSets) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text>
          This commodity already has valid control files. Please go back and
          remove all control files before proceeding.
        </Text>
      </Alert>
    );
  }

  if (!commodityHasValidControl && !hasControlSets) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <Text>
          This commodity requires control files. Please go back and add control
          files before proceeding.
        </Text>
      </Alert>
    );
  }

  return null;
};

// Helper function to check if submission should be allowed
export const canSubmit = (
  commodityHasValidControl,
  hasControlSets,
  fileValidation
) => {
  return (
    fileValidation.isValid &&
    !(
      (commodityHasValidControl && hasControlSets) ||
      (!commodityHasValidControl && !hasControlSets)
    )
  );
};