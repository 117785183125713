import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Company name is required')
    .min(2, 'Name must be at least 2 characters'),
  email_domain: Yup.string()
    .required('Domain is required')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
      'Enter a valid domain (e.g., company.com)'
    ),
  contact_name: Yup.string().nullable(),
  contact_email: Yup.string()
    .email('Enter a valid email')
    .when('contact_name', {
      is: (val) => val && val.length > 0,
      then: () => Yup.string().required('Contact email is required when contact name is provided'),
    })
    .nullable(),
  description: Yup.string().nullable(),
});

const CompanyForm = ({ isOpen, onClose, onSubmit, initialValues = {} }) => {
  // Convert null values to empty strings and ensure all required fields exist
  const defaultValues = {
    name: '',
    email_domain: '',
    contact_name: '',
    contact_email: '',
    description: '',
    ...Object.fromEntries(
      Object.entries(initialValues || {}).map(([key, value]) => [
        key,
        value === null ? '' : value
      ])
    )
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            // Convert empty strings back to null before submitting
            const submitValues = Object.fromEntries(
              Object.entries(values).map(([key, value]) => [
                key,
                value === '' ? null : value
              ])
            );
            await onSubmit(submitValues);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalHeader>
                {initialValues?.id ? 'Edit Company' : 'Add New Company'}
              </ModalHeader>

              <ModalBody>
                <VStack spacing={4}>
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel>Company Name</FormLabel>
                        <Input {...field} placeholder="Enter company name" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="email_domain">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email_domain && form.touched.email_domain}>
                        <FormLabel>Domain</FormLabel>
                        <Input {...field} placeholder="company.com" />
                        <FormErrorMessage>{form.errors.email_domain}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="contact_name">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.contact_name && form.touched.contact_name}>
                        <FormLabel>Contact Name</FormLabel>
                        <Input {...field} placeholder="Primary contact person" />
                        <FormErrorMessage>{form.errors.contact_name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="contact_email">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.contact_email && form.touched.contact_email}>
                        <FormLabel>Contact Email</FormLabel>
                        <Input {...field} placeholder="contact@company.com" />
                        <FormErrorMessage>{form.errors.contact_email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="description">
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.description && form.touched.description}>
                        <FormLabel>Description</FormLabel>
                        <Input {...field} placeholder="Brief company description" />
                        <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {initialValues?.id ? 'Save Changes' : 'Create Company'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CompanyForm;