import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  fetchJobs,
  fetchCommodities,
  fetchModels,
  JOB_STATUS,
} from "../modules/JobApiService";
import JobsTable from "./JobsTable.jsx";
import JobFilters from "./predictions/JobFilters";
import { useAuth } from "../context/AuthContext";

const JobsPage = () => {
  const [jobs, setJobs] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [commodities, setCommodities] = useState({});
  const [models, setModels] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();

  const [filters, setFilters] = useState({
    search: "",
    view_type: "my_jobs",
    company_id: undefined,
    page: 1,
    per_page: 10,
    sort_by: undefined,
    sort_desc: false
  });

  // Single useEffect for static data load
  useEffect(() => {
    const loadStaticData = async () => {
      try {
        const [commoditiesData, modelsData] = await Promise.all([
          fetchCommodities(),
          fetchModels(),
        ]);

        const commoditiesMap = commoditiesData.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

        const modelsMap = modelsData.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

        setCommodities(commoditiesMap);
        setModels(modelsMap);
      } catch (err) {
        toast({
          title: "Error loading data",
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    loadStaticData();
  }, []); // Only run once on mount

  // Separate useEffect for jobs data and auto-refresh
  useEffect(() => {
    let isMounted = true;

    const loadJobs = async () => {
      if (!isMounted) return;
      
      try {
        setTableLoading(true);
        const response = await fetchJobs(filters);
        if (isMounted) {
          setJobs(response.jobs);
          setTotalItems(response.total);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError("Failed to load jobs");
          toast({
            title: "Error loading jobs",
            description: err.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } finally {
        if (isMounted) {
          setTableLoading(false);
        }
      }
    };

    loadJobs();

    // Set up auto-refresh
    const intervalId = setInterval(loadJobs, 60000);

    // Cleanup function
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [filters]); // Only depends on filters

  const handleViewSets = (job) => {
    navigate(`/portal/predictions/sets`, {
      state: {
        jobId: job.jobId,
        jobName: job.jobName,
        commodityName: job.commodityName,
        userName: job.userName,
        raised: job.raised,
        customerRef: job.customerRef,
      },
    });
  };

  const handleSort = (field, isDesc) => {
    setFilters(prev => ({
      ...prev,
      sort_by: field,
      sort_desc: isDesc
    }));
  };

  const handlePageChange = (newPage) => {
    setFilters(prev => ({
      ...prev,
      page: newPage
    }));
  };

  const handlePerPageChange = (newPerPage) => {
    setFilters(prev => ({
      ...prev,
      per_page: newPerPage,
      page: 1
    }));
  };

  if (error) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return (
    <Box>
      <JobFilters
        filters={filters}
        setFilters={setFilters}
      />
      <Box mt={4}>
        <JobsTable
          jobs={jobs}
          commodities={commodities}
          JOB_STATUS={JOB_STATUS}
          handleViewSets={handleViewSets}
          onSort={handleSort}
          currentSort={{
            field: filters.sort_by,
            isDesc: filters.sort_desc
          }}
          pagination={{
            currentPage: filters.page,
            pageSize: filters.per_page,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePerPageChange,
            totalItems: totalItems
          }}
          isLoading={tableLoading}
        />
      </Box>
    </Box>
  );
};

export default JobsPage;