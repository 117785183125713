import React, { useState, useEffect } from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  Button,
  HStack,
  Text,
  Box,
  Collapse,
  Toast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { fetchCustomers, fetchNotes, parseFilename } from "../../../modules/JobApiService";
import { useAuth } from "../../../context/AuthContext";
import PropTypes from 'prop-types';



const validationSchema = Yup.object({
  customer_id: Yup.string().required("Customer is required"),
  received_date: Yup.date().required("Received date is required"),
  test_date: Yup.date().required("Test date is required"),
  sample_id: Yup.string().required("Sample ID is required"),
  customer_sample_id: Yup.string().required("Customer Sample Id is required").max(20, "Must be 20 characters or less"),
  description: Yup.string().required("Customer description is required").max(50, "Must be 50 characters or less"),
  /* notes: Yup.string().required("Notes are required"), */
});

const CertificateForm = ({ set, onSubmit, onCancel, previousVersion }) => {
  const [customers, setCustomers] = useState([]);
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(true);
  const [showPreviousNotes, setShowPreviousNotes] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch all data in parallel
        const [customersData, notesData] = await Promise.all([
          fetchCustomers({company_id: user.company_id}),
          fetchNotes(set.id)
        ]);

        setCustomers(customersData.items);
        
        const certificateNotes = notesData
          .filter((note) => note.include_in_cert)
          .map((note) => note.notes)
          .join("\n\n");
        setNotes(certificateNotes);
        
        // Only set formReady after all data is loaded
        setFormReady(true);
      } catch (error) {
        console.error("Error fetching form data:", error);
        Toast({
          title: "Error loading form data",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [set.id, user.company_id]);

  const { date: parsedDate, sampleId: parsedSampleId } = parseFilename(
    set.inputFile1
  );

  if (loading || !formReady) {
    return <Text>Loading form data...</Text>;
  }

  // Determine initial values based on previous version or parsed data
  const initialValues = previousVersion ? {
    customer_id: previousVersion.customer_id || "",
    received_date: previousVersion.received_date || parsedDate.toISOString().split("T")[0],
    test_date: previousVersion.test_date || parsedDate.toISOString().split("T")[0],
    sample_id: previousVersion.sample_id || parsedSampleId,
    customer_sample_id: previousVersion.customer_sample_id || "",
    description: previousVersion.description || "",
    notes: notes
  } : {
    customer_id: "",
    received_date: parsedDate.toISOString().split("T")[0],
    test_date: parsedDate.toISOString().split("T")[0],
    sample_id: parsedSampleId,
    customer_sample_id: "",
    description: "",
    notes: notes,
  };

  const FieldInfo = ({ children }) => (
    <Text fontSize="sm" color="gray.500" ml={1}>
      {children}
    </Text>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={false} 
    >
      {({ values, errors, touched }) => (
        <Form>
          <VStack spacing={4} align="stretch">
            {previousVersion && (
              <Text fontSize="sm" color="gray.600" mb={2}>
                Form pre-populated with data from version {previousVersion.version}
              </Text>
            )}

            <Field name="customer_id">
              {({ field }) => (
                <FormControl
                  isInvalid={errors.customer_id && touched.customer_id}
                >
                  <FormLabel>
                    Customer
                    {previousVersion?.customer_id && (
                      <FieldInfo>(from previous version)</FieldInfo>
                    )}
                  </FormLabel>
                  <Select {...field} placeholder="Select customer">
                    {customers.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.customer_id}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <HStack spacing={4}>
              <Field name="received_date">
                {({ field }) => (
                  <FormControl
                    isInvalid={errors.received_date && touched.received_date}
                  >
                    <FormLabel>
                      Received Date
                      {previousVersion?.received_date && (
                        <FieldInfo>(from previous version)</FieldInfo>
                      )}
                    </FormLabel>
                    <Input {...field} type="date" />
                    <FormErrorMessage>{errors.received_date}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="test_date">
                {({ field }) => (
                  <FormControl
                    isInvalid={errors.test_date && touched.test_date}
                  >
                    <FormLabel>
                      Test Date
                      {previousVersion?.test_date && (
                        <FieldInfo>(from previous version)</FieldInfo>
                      )}
                    </FormLabel>
                    <Input {...field} type="date" />
                    <FormErrorMessage>{errors.test_date}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </HStack>

            <Field name="sample_id">
              {({ field }) => (
                <FormControl isInvalid={errors.sample_id && touched.sample_id}>
                  <FormLabel>
                    Sample ID
                    {previousVersion?.sample_id && (
                      <FieldInfo>(from previous version)</FieldInfo>
                    )}
                  </FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{errors.sample_id}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="customer_sample_id">
              {({ field }) => (
                <FormControl
                  isInvalid={errors.customer_sample_id && touched.customer_sample_id}
                >
                  <FormLabel>
                    Customer Sample ID
                    {previousVersion?.customer_sample_id && (
                      <FieldInfo>(from previous version)</FieldInfo>
                    )}
                  </FormLabel>
                  <Input {...field} maxLength={20} />
                  <Text fontSize="sm" color="gray.500">
                    {field.value.length}/20 characters
                  </Text>
                  <FormErrorMessage>{errors.customer_sample_id}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="description">
              {({ field }) => (
                <FormControl
                  isInvalid={errors.description && touched.description}
                >
                  <FormLabel>
                    Customer Description
                    {previousVersion?.description && (
                      <FieldInfo>(from previous version)</FieldInfo>
                    )}
                  </FormLabel>
                  <Input {...field} maxLength={50} />
                  <Text fontSize="sm" color="gray.500">
                    {field.value.length}/50 characters
                  </Text>
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="notes">
              {({ field }) => (
                <FormControl isInvalid={errors.notes && touched.notes}>
                  <FormLabel>Notes</FormLabel>
                  <Textarea {...field} rows={8} />
                  <FormErrorMessage>{errors.notes}</FormErrorMessage>
                  
                  {previousVersion?.notes && (
                    <Box mt={2}>
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => setShowPreviousNotes(!showPreviousNotes)}
                      >
                        {showPreviousNotes ? "Hide" : "Show"} Previous Version Notes
                      </Button>
                      
                      <Collapse in={showPreviousNotes}>
                        <Box
                          mt={2}
                          p={3}
                          bg="gray.50"
                          borderRadius="md"
                          fontSize="sm"
                        >
                          <Text fontWeight="bold" mb={2}>
                            Notes from Version {previousVersion.version}:
                          </Text>
                          <Text whiteSpace="pre-wrap">
                            {previousVersion.notes}
                          </Text>
                        </Box>
                      </Collapse>
                    </Box>
                  )}
                </FormControl>
              )}
            </Field>

            <HStack spacing={4} justify="flex-end">
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="submit" colorScheme="blue">
                Generate Certificate
              </Button>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

CertificateForm.propTypes = {
  set: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  previousVersion: PropTypes.shape({
    customer_id: PropTypes.string,
    received_date: PropTypes.string,
    test_date: PropTypes.string,
    sample_id: PropTypes.string,
    customer_sample_id: PropTypes.string,
    description: PropTypes.string,
    notes: PropTypes.string,
    version: PropTypes.number
  })
};

CertificateForm.defaultProps = {
  previousVersion: null
};

export default CertificateForm;
