import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  Button,
  VStack,
  HStack,
  Box,
  Checkbox,
  FormErrorMessage,
  useToast,
  Spacer,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

const PREPROCESSING_OPTIONS = [
  "SNV",
  "SNVSDGOL",
  "SDGOLSNV",
  "FDGOLSNV",
  "SNVFDGOL",
];
const MODEL_TYPES = ["pls", "opls"];

const ModelUploadModal = ({ isOpen, onClose, onSuccess, existingModels }) => {
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleModelUpload = async (values, setSubmitting) => {
    try {
      const modelData = {
        ...values,
        filelocation: values.file.name,
      };

      const modelCreateResponse = await axios.post(
        `${apiUrl}/api/models`,
        modelData
      );

      const modelId = modelCreateResponse.data.id;

      // Upload RDS file
      const rdsResponse = await axios.post(`${apiUrl}/api/models/upload`, {
        id: modelId,
        filename: values.file.name,
      });

      const s3Request = axios.create();
      delete s3Request.defaults.headers.common["Authorization"];

      await s3Request.put(rdsResponse.data.url, values.file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (values.enableplotting) {
        // Upload typical plotting file
        const typicalResponse = await axios.post(
          `${apiUrl}/api/models/upload`,
          {
            id: modelId,
            filename: "typical_plotting.csv",
          }
        );

        await s3Request.put(typicalResponse.data.url, values.typical_plotting, {
          headers: {
            "Content-Type": "text/csv",
          },
        });

        // Upload atypical plotting file
        const atypicalResponse = await axios.post(
          `${apiUrl}/api/models/upload`,
          {
            id: modelId,
            filename: "atypical_plotting.csv",
          }
        );

        await s3Request.put(
          atypicalResponse.data.url,
          values.atypical_plotting,
          {
            headers: {
              "Content-Type": "text/csv",
            },
          }
        );
      }

      onSuccess();
      onClose();
      toast({
        title: "Model created successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error uploading model:", error);
      toast({
        title: "Error creating model",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Model</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              name: "",
              upper: "",
              middle: "",
              lower: "",
              description: "",
              preprocessing: "",
              modeltype: "",
              file: null,
              enableplotting: false,
              plot_3d: false,
              typical_plotting: null,
              atypical_plotting: null,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Name is required")
                .test(
                  "unique-name",
                  "This name is already in use",
                  (value) =>
                    !existingModels.some(
                      (c) => c.name.toLowerCase() === value?.toLowerCase()
                    )
                ),
              upper: Yup.number()
                .min(0, "Must be between 0 and 2")
                .max(2, "Must be between 0 and 2")
                .required("Upper cutoff is required"),
              middle: Yup.number()
                .min(0, "Must be between 0 and 2")
                .max(2, "Must be between 0 and 2")
                .required("Middle cutoff is required"),
              lower: Yup.number()
                .min(0, "Must be between 0 and 2")
                .max(2, "Must be between 0 and 2")
                .required("Lower cutoff is required"),
              description: Yup.string().required("Description is required"),
              preprocessing: Yup.string().required("Preprocessing is required"),
              modeltype: Yup.string().required("Model type is required"),
              file: Yup.mixed()
                .required("File is required")
                .test(
                  "unique-name",
                  "This filename is already in use",
                  (value) =>
                    !existingModels.some(
                      (c) =>
                        c.filename.toLowerCase() === value?.name?.toLowerCase()
                    )
                ),
              enableplotting: Yup.boolean(),
              plot_3d: Yup.boolean(),
              typical_plotting: Yup.mixed().when("enableplotting", {
                is: true,
                then: () =>
                  Yup.mixed().required("Typical plotting file is required"),
                otherwise: () => Yup.mixed().nullable(),
              }),
              atypical_plotting: Yup.mixed().when("enableplotting", {
                is: true,
                then: () =>
                  Yup.mixed().required("Atypical plotting file is required"),
                otherwise: () => Yup.mixed().nullable(),
              }),
            })}
            onSubmit={(values, { setSubmitting }) =>
              handleModelUpload(values, setSubmitting)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <VStack spacing={4}>
                  {/* Form fields from your existing code */}
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <HStack spacing={4} align="start" width="full">
                    <FormControl isInvalid={errors.upper && touched.upper}>
                      <FormLabel>Upper Cutoff</FormLabel>
                      <NumberInput max={2} min={0} precision={3}>
                        <NumberInputField
                          name="upper"
                          value={values.upper}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </NumberInput>
                      <FormErrorMessage>{errors.upper}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.middle && touched.middle}>
                      <FormLabel>Middle Cutoff</FormLabel>
                      <NumberInput max={2} min={0} precision={3}>
                        <NumberInputField
                          name="middle"
                          value={values.middle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </NumberInput>
                      <FormErrorMessage>{errors.middle}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.lower && touched.lower}>
                      <FormLabel>Lower Cutoff</FormLabel>
                      <NumberInput max={2} min={0} precision={3}>
                        <NumberInputField
                          name="lower"
                          value={values.lower}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </NumberInput>
                      <FormErrorMessage>{errors.lower}</FormErrorMessage>
                    </FormControl>
                  </HStack>

                  <FormControl
                    isInvalid={errors.description && touched.description}
                  >
                    <FormLabel>Description</FormLabel>
                    <Input
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={errors.preprocessing && touched.preprocessing}
                  >
                    <FormLabel>Preprocessing</FormLabel>
                    <Select
                      name="preprocessing"
                      value={values.preprocessing}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Preprocessing</option>
                      {PREPROCESSING_OPTIONS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.preprocessing}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={errors.modeltype && touched.modeltype}
                  >
                    <FormLabel>Model Type</FormLabel>
                    <Select
                      name="modeltype"
                      value={values.modeltype}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Model Type</option>
                      {MODEL_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.modeltype}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.file && touched.file}>
                    <FormLabel>RDS File</FormLabel>
                    <Input
                      type="file"
                      accept=".rds"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                    <FormErrorMessage>{errors.file}</FormErrorMessage>
                  </FormControl>

                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    p={4}
                    mb={4}
                    w="full"
                  >
                    <FormControl>
                      <Checkbox
                        name="enableplotting"
                        isChecked={values.enableplotting}
                        onChange={handleChange}
                      >
                        Enable Plotting
                      </Checkbox>
                    </FormControl>

                    {values.enableplotting && (
                      <VStack spacing={4} mt={4} pl={6}>
                        <FormControl>
                          <Checkbox
                            name="plot_3d"
                            isChecked={values.plot_3d}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            Allow 3D Plotting
                          </Checkbox>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.typical_plotting && touched.typical_plotting
                          }
                        >
                          <FormLabel>Typical Plotting File</FormLabel>
                          <Input
                            type="file"
                            accept=".csv"
                            onChange={(event) => {
                              setFieldValue(
                                "typical_plotting",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {errors.typical_plotting}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            errors.atypical_plotting &&
                            touched.atypical_plotting
                          }
                        >
                          <FormLabel>Atypical Plotting File</FormLabel>
                          <Input
                            type="file"
                            accept=".csv"
                            onChange={(event) => {
                              setFieldValue(
                                "atypical_plotting",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {errors.atypical_plotting}
                          </FormErrorMessage>
                        </FormControl>
                      </VStack>
                    )}
                  </Box>

                  <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    width="full"
                  >
                    Create Model
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModelUploadModal;
