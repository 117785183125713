import React, { useEffect, useState } from 'react';
import { Box, Image, Spinner } from '@chakra-ui/react';
import axios from 'axios';

const CompanyImage = ({ 
  company, 
  fileType,  // 'logo' or 'signature'
  aspectRatio,
  maxWidth,
  refreshKey, 
  ...props 
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/companies/files/${company.id}/${fileType}/url`
        );
        setImageUrl(data.url);
        setError(false);
      } catch (err) {
        console.error('Error fetching image URL:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (company?.id) {
      fetchImageUrl();
    }
  }, [company?.id, fileType, refreshKey]); // Add refreshKey to dependencies

  if (loading) {
    return (
      <Box
        width="100%"
        maxW={maxWidth}
        aspectRatio={aspectRatio}
        bg="gray.50"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <Spinner size="sm" color="gray.400" />
      </Box>
    );
  }

  if (error || !imageUrl) {
    return (
      <Box
        width="100%"
        maxW={maxWidth}
        aspectRatio={aspectRatio}
        bg="gray.100"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <Box color="gray.400">No {fileType} available</Box>
      </Box>
    );
  }

  return (
    <Box
      position="relative"
      width="100%"
      maxW={maxWidth}
      aspectRatio={aspectRatio}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      overflow="hidden"
    >
      <Image
        src={imageUrl}
        alt={`Company ${fileType}`}
        objectFit="contain"
        width="100%"
        height="100%"
        {...props}
      />
    </Box>
  );
};

export default CompanyImage;