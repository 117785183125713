import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Container,
  VStack,
  HStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormErrorMessage,
  Spacer,
  IconButton,
  ListItem,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import { useEffect, useState, React } from "react";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { DeleteIcon } from "@chakra-ui/icons";
import UploadProgressPage from "../components/FileUpload";
import { Upload, HelpCircle } from "lucide-react";
import {
  validateAndGroupFiles,
  FileSetStatus,
  ValidationMessage,
  canSubmit,
} from "../modules/predictions/FileReview";

const steps = [
  { title: "Define", description: "Define the job details" },
  { title: "Upload", description: "Upload the job files" },
  { title: "Create", description: "Validate and create the job" },
];

const getBaseFileName = (filename) => {
  const withoutExtension = filename.replace(/\.[^/.]+$/, "");
  return withoutExtension.slice(0, -1); // Remove last character (a/b/c)
};

// Helper function to validate suffix
const isValidSuffix = (filename) => {
  const suffix = filename
    .replace(/\.[^/.]+$/, "")
    .slice(-1)
    .toLowerCase();
  return ["a", "b", "c"].includes(suffix);
};

// Helper to check if files belong to the same set
const belongToSameSet = (file1, file2) => {
  return getBaseFileName(file1.name) === getBaseFileName(file2.name);
};

// Main validation function for file sets
const validateFileSets = (files) => {
  const errors = [];
  const filesByBase = {};

  // Group files by their base name
  files.forEach((file) => {
    if (!isValidSuffix(file.name)) {
      errors.push(
        `File "${file.name}" does not end with a valid suffix (a, b, or c)`
      );
      return;
    }

    const baseName = getBaseFileName(file.name);
    if (!filesByBase[baseName]) {
      filesByBase[baseName] = [];
    }
    filesByBase[baseName].push(file);
  });

  // Validate each group has exactly 3 files with a, b, c suffixes
  Object.entries(filesByBase).forEach(([baseName, groupFiles]) => {
    if (groupFiles.length !== 3) {
      errors.push(
        `Incomplete set for "${baseName}": Found ${groupFiles.length} files, need exactly 3 (a, b, c)`
      );
      return;
    }

    const suffixes = groupFiles
      .map((f) =>
        f.name
          .replace(/\.[^/.]+$/, "")
          .slice(-1)
          .toLowerCase()
      )
      .sort();
    if (suffixes.join("") !== "abc") {
      errors.push(
        `Invalid suffixes for "${baseName}": Found ${suffixes.join(
          ", "
        )}, need exactly a, b, c`
      );
    }
  });

  // Check if all control files are properly marked
  Object.entries(filesByBase).forEach(([baseName, groupFiles]) => {
    const hasCtrl = baseName.toLowerCase().includes("ctrl");
    const isControlSet = groupFiles.every((f) =>
      f.name.toLowerCase().includes("ctrl")
    );
    if (hasCtrl !== isControlSet) {
      errors.push(
        `Inconsistent control file naming for set "${baseName}": All files in a set must be either control or sample files`
      );
    }
  });

  return {
    isValid: errors.length === 0,
    errors,
    validSets: Object.values(filesByBase).filter((group) => group.length === 3),
  };
};

export default function NewPrediction() {
  const [step, setStep] = useState(1);
  const [commodityOptions, setCommodityOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileSets, setFileSets] = useState([]);
  const [validating, setValidating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [commodityHasValidControl, setCommodityHasValidControl] =
    useState(false);
  const [selectedCommodity, setSelectedCommodity] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  //Removal actions for dropzone
  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeAll = () => {
    setFiles([]);
  };

  //schema for validation of the first step
  const formSchema = Yup.object().shape({
    jobName: Yup.string()
      .required("Job Name is required")
      .min(5, "Job Name must be at least 5 characters"),
    commodities: Yup.string().test(
      "not-default",
      "Commodity selection is required",
      (value) => value && value !== "" && value !== "Select a commodity"
    ),
  });

  // Fetch commodities from API
  useEffect(() => {
    const fetchCommodities = async () => {
      try {
        // Replace with actual API endpoint
        const response = await axios.get(`${apiUrl}/api/commodities`);
        setCommodityOptions(response.data);
      } catch (error) {
        console.error("Error fetching commodities", error);
      }
    };

    fetchCommodities();
  }, [apiUrl]);

  const [fileValidation, setFileValidation] = useState({
    isValid: false,
    errors: [],
    fileSets: [],
    hasControlSets: false,
    hasSampleSets: false,
  });

  useEffect(() => {
    const validation = validateAndGroupFiles(files);
    setFileValidation(validation);
  }, [files]);

  // File drop zone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "text/tab-separated-values": [".tsv"],
      "application/octet-stream": [".spa", ".SPA"],
      "application/spa": [".spa", ".SPA"],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      // Filter out duplicates
      const newFiles = acceptedFiles.filter((newFile) => {
        const isDuplicate = files.some(
          (existingFile) => existingFile.name === newFile.name
        );
        return !isDuplicate;
      });

      if (newFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
    },
  });

  // Group files into sets
  useEffect(() => {
    const groupedSets = [];
    const processedFiles = [...files];

    while (processedFiles.length > 0) {
      const controlFiles = processedFiles.filter(
        (file) =>
          file.name.toUpperCase().includes("CTRL") &&
          ["A", "B", "C"].includes(
            file.name
              .replace(/\.[^/.]+$/, "")
              .slice(-1)
              .toUpperCase()
          )
      );
      const sampleFiles = processedFiles.filter(
        (file) =>
          !file.name.toUpperCase().includes("CTRL") &&
          ["A", "B", "C"].includes(
            file.name
              .replace(/\.[^/.]+$/, "")
              .slice(-1)
              .toUpperCase()
          )
      );

      if (controlFiles.length >= 3 || sampleFiles.length >= 3) {
        const controlSet = controlFiles.slice(0, 3);
        const sampleSet = sampleFiles.slice(0, 3);

        groupedSets.push({
          control: controlSet,
          sample: sampleSet,
        });

        // Remove processed files
        controlSet.forEach((file) => {
          const index = processedFiles.indexOf(file);
          if (index > -1) processedFiles.splice(index, 1);
        });
        sampleSet.forEach((file) => {
          const index = processedFiles.indexOf(file);
          if (index > -1) processedFiles.splice(index, 1);
        });
      } else {
        break;
      }
    }

    setFileSets(groupedSets);
  }, [files]);

  // Submit form handler
  const handleFinalSubmit = async (values) => {
    try {
      setFormData(values);

      setIsUploading(true);
    } catch (error) {
      console.error("Submission error", error);
      alert("Submission failed");
    }
  };

  // Render first step (Job Name and Commodity)
  const renderFirstStep = (formikProps) => (
    <VStack spacing={4} width="100%">
      <Field type="text" name="jobName">
        {({ field, form }) => (
          <FormControl
            isInvalid={
              form.errors.jobName && (form.touched.jobName || validating)
            }
            isRequired
          >
            <FormLabel htmlFor="commodities">Job Name</FormLabel>
            <Input {...field} placeholder="Job Name" id="jobName" />
            <FormErrorMessage>{form.errors.jobName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Field type="text" name="customerName">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.customerName && form.touched.customerName}
          >
            <FormLabel>Customer Name/Reference</FormLabel>
            <Input {...field} placeholder="Customer Name/Reference" />
            <FormErrorMessage>{form.errors.customerName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Field name="commodities">
        {({ field, form }) => (
          <FormControl
            isInvalid={
              form.errors.commodities &&
              (form.touched.commodities || validating)
            }
            isRequired
          >
            <FormLabel htmlFor="commodities">Select Commodity</FormLabel>
            <Select
              {...field}
              id="commodities"
              onChange={(e) => {
                field.onChange(e);
                const selected = commodityOptions.find(
                  (option) => option.name === e.target.value
                );
                setCommodityHasValidControl(
                  selected ? selected.hasValidControl : false
                );
                setSelectedCommodity(selected.name);
              }}
            >
              <option key="" value="Select a commodity">
                Select a commodity
              </option>
              {commodityOptions.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{form.errors.commodities}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <HStack direction="row" spacing={4} alignContent="right" width="100%">
        <Spacer />
        <Button
          colorScheme="blue"
          onClick={() => {
            setValidating(true);
            formikProps.validateForm().then((errors) => {
              if (Object.keys(errors).length === 0) {
                setStep(2);
                setActiveStep(activeStep + 1);
                setValidating(false);
              } else {
                console.log(errors);
              }
            });
          }}
        >
          Next
        </Button>
      </HStack>
    </VStack>
  );

  const FileTable = () => {
    const groupedFiles = files.reduce((acc, file) => {
      const base = getBaseFileName(file.name);
      if (!acc[base]) {
        acc[base] = [];
      }
      acc[base].push(file);
      return acc;
    }, {});
  
    return (
      <TableContainer width="100%">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Set Type</Th>
              <Th>Base Name</Th>
              <Th>Files</Th>
              <Th>Set Status</Th>
            </Tr>
            <Tr>
              <Th colSpan={4}>
                <Button
                  size="sm"
                  colorScheme="teal"
                  variant="outline"
                  onClick={removeAll}
                  leftIcon={<DeleteIcon />}
                >
                  Clear All
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(groupedFiles).map(([baseName, groupFiles]) => {
              const isComplete = groupFiles.length === 3;
              const suffixes = groupFiles
                .map((f) =>
                  f.name
                    .replace(/\.[^/.]+$/, "")
                    .slice(-1)
                    .toLowerCase()
                )
                .sort();
              const isControlSet = baseName.toLowerCase().includes('ctrl');
              const missingSuffixes = ["a", "b", "c"].filter(
                (s) => !suffixes.includes(s)
              );
  
              return (
                <Tr key={baseName}>
                  <Td>{isControlSet ? "Control" : "Sample"}</Td>
                  <Td>{baseName}</Td>
                  <Td>
                    <VStack align="start" spacing={2}>
                      {groupFiles.map((file) => (
                        <HStack key={file.name} spacing={2}>
                          <Text>{file.name}</Text>
                          <IconButton
                            size="xs"
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            variant="ghost"
                            onClick={removeFile(file)}
                            aria-label="Delete file"
                          />
                        </HStack>
                      ))}
                    </VStack>
                  </Td>
                  <Td>
                    {!isComplete && (
                      <Alert status="warning" size="sm">
                        <VStack align="start" spacing={0}>
                          <Text fontWeight="medium">
                            Missing {3 - groupFiles.length} file(s):
                          </Text>
                          <Text>
                            Need suffixes: {missingSuffixes.join(", ")}
                          </Text>
                        </VStack>
                      </Alert>
                    )}
                    {isComplete && suffixes.join("") !== "abc" && (
                      <Alert status="error" size="sm">
                        <Text>
                          Invalid suffixes: {suffixes.join(", ")}
                          <br />
                          Need exactly: a, b, c
                        </Text>
                      </Alert>
                    )}
                    {isComplete && suffixes.join("") === "abc" && (
                      <Alert status="success" size="sm">
                        <Text>Complete set (a, b, c)</Text>
                      </Alert>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  // Render second step (File Upload)
  const renderFileUploadStep = () => (
    // Change from VStack width="100%" to:
    <VStack spacing={4} width="100%" maxWidth="100%">
      <Box
        {...getRootProps()}
        border="3px dashed"
        borderColor={isDragActive ? "blue.500" : "gray.300"}
        p={10}
        textAlign="center"
        width="100%"
        transition="all 0.2s"
        _hover={{ borderColor: "blue.400", bg: "blue.50" }}
        bg={isDragActive ? "blue.50" : "white"}
        borderRadius="lg"
      >
        <input {...getInputProps()} />
        <VStack spacing={6} alignItems="center" width="100%">
          <Upload
            size={32}
            color={
              isDragActive
                ? "var(--chakra-colors-blue-500)"
                : "var(--chakra-colors-gray-400)"
            }
          />
          <VStack spacing={2}>
            <Text
              fontSize="lg"
              fontWeight="medium"
              color={isDragActive ? "blue.600" : "inherit"}
            >
              {isDragActive
                ? "Drop the files here ..."
                : "Drag 'n' drop files here, or click to select files"}
            </Text>
            <HStack justify="center">
              <Text fontSize="sm" color="gray.600">
                Supported formats: .csv, .tsv
              </Text>
              <Tooltip
                hasArrow
                label={
                  <VStack align="start" spacing={2} p={2}>
                    <Text fontWeight="bold">File Upload Requirements:</Text>
                    <UnorderedList spacing={1}>
                      <ListItem>
                        Files are uploaded in sets of 3 matching files
                      </ListItem>
                      <ListItem>
                        Each set must be either all control files or all sample
                        files
                      </ListItem>
                      <ListItem>
                        Files in a set must have identical names except for
                        suffixes a, b, c
                      </ListItem>
                      <ListItem>
                        Control files must include 'ctrl' in the filename
                      </ListItem>
                      <ListItem>Examples:</ListItem>
                      <UnorderedList pl={4}>
                        <ListItem>
                          Control set: basil_ctrl_a.csv, basil_ctrl_b.csv,
                          basil_ctrl_c.csv
                        </ListItem>
                        <ListItem>
                          Sample set: sample1_a.csv, sample1_b.csv,
                          sample1_c.csv
                        </ListItem>
                      </UnorderedList>
                    </UnorderedList>
                  </VStack>
                }
                placement="top"
                bg="gray.700"
              >
                <Box cursor="help">
                  <HelpCircle size={16} color="var(--chakra-colors-gray-400)" />
                </Box>
              </Tooltip>
            </HStack>
          </VStack>

          <Alert
            status={commodityHasValidControl ? "info" : "warning"}
            variant="solid"
            borderRadius="md"
            width="full"
          >
            <AlertIcon />
            <VStack align="center" width="full" spacing={2}>
              {commodityHasValidControl ? (
                <>
                  <Text>
                    Control Files for <b>{selectedCommodity}</b> have already
                    been uploaded today
                  </Text>
                  <Text fontSize="lg" fontWeight="bold">
                    Upload Sample File Sets Only (3 matching files per set)
                  </Text>
                </>
              ) : (
                <>
                  <Text>
                    No Control Files for <b>{selectedCommodity}</b> have been
                    uploaded today
                  </Text>
                  <Text fontSize="lg" fontWeight="bold">
                    Upload Control File Sets ('ctrl' in filename) AND Sample
                    File Sets
                  </Text>
                  <Text fontSize="sm">Each set must be 3 matching files</Text>
                </>
              )}
            </VStack>
          </Alert>
        </VStack>
      </Box>

      {files.length > 0 && (
        // Ensure this container takes full width
        <Box width="100%" maxWidth="100%">
          <Heading size="md" mb={4}>
            Selected Files ({files.length} files)
          </Heading>
          <FileTable />
        </Box>
      )}

      <HStack direction="row" spacing={4} width="100%">
        <Spacer />
        <Button
          colorScheme="blue"
          variant="outline"
          onClick={() => {
            setStep(1);
            setActiveStep(activeStep - 1);
          }}
        >
          Back
        </Button>

        <Button
          colorScheme="blue"
          onClick={() => {
            setStep(3);
            setActiveStep(activeStep + 1);
          }}
          isDisabled={fileErrors.length > 0 || files.length === 0}
          opacity={fileErrors.length > 0 || files.length === 0 ? 0.5 : 1}
          cursor={
            fileErrors.length > 0 || files.length === 0
              ? "not-allowed"
              : "pointer"
          }
          _hover={{
            bg:
              fileErrors.length > 0 || files.length === 0
                ? "blue.500"
                : "blue.600",
          }}
          title={
            files.length === 0
              ? "Please add files before proceeding"
              : fileErrors.length > 0
              ? "Please fix all validation errors before proceeding"
              : ""
          }
        >
          Next
        </Button>
      </HStack>
    </VStack>
  );

  // Render third step (File Set Review)
  const renderFileSetReviewStep = () => {
    return (
      <VStack spacing={4} width="100%">
        <TableContainer width="100%">
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th>Control Files</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fileValidation.fileSets.map(
                (set, index) =>
                  set.control.length > 0 && (
                    <Tr
                      key={`control-${index}`}
                      backgroundColor={
                        commodityHasValidControl ? "bg-red-100" : undefined
                      }
                    >
                      <Td>
                        {set.control.map((file) => (
                          <Text
                            key={file.name}
                            className={
                              commodityHasValidControl ? "text-red-600" : ""
                            }
                          >
                            {file.name}
                          </Text>
                        ))}
                      </Td>
                    </Tr>
                  )
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer width="100%">
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th>Sample Files</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fileValidation.fileSets.map(
                (set, index) =>
                  set.sample.length > 0 && (
                    <Tr key={`sample-${index}`}>
                      <Td>
                        {set.sample.map((file) => (
                          <Text key={file.name}>{file.name}</Text>
                        ))}
                      </Td>
                    </Tr>
                  )
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <ValidationMessage
          commodityHasValidControl={commodityHasValidControl}
          hasControlSets={fileValidation.hasControlSets}
        />

        <HStack direction="row" spacing={4} width="100%">
          <Spacer />
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              setStep(2);
              setActiveStep(activeStep - 1);
            }}
          >
            Back
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            isDisabled={
              !canSubmit(
                commodityHasValidControl,
                fileValidation.hasControlSets,
                fileValidation
              )
            }
          >
            Submit Job
          </Button>
        </HStack>
      </VStack>
    );
  };

  //if uploading then display this
  if (isUploading) {
    return (
      <UploadProgressPage
        files={fileSets}
        formValues={formData}
        onComplete={() => {
          setIsUploading(false);
          setFileSets([]);
          setFormData(null);
        }}
      />
    );
  }

  return (
    <div>
      <VStack spacing="24px">
        <Box marginY={30}>
          <Center border="none" bg="white">
            <VStack spacing="2px">
              <Container margin={"20px"} padding={"1px"} textAlign={"center"}>
                Create a new prediction job using one or more sets (including
                control sets).
              </Container>
              <Stepper index={activeStep}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              <Container maxW="100%" py={10}>
                <Formik
                  initialValues={{
                    jobName: "",
                    commodites: "",
                    customerName: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={handleFinalSubmit}
                >
                  {(formikProps) => (
                    <Form>
                      <VStack spacing={6} width="100%">
                        {step === 1 && renderFirstStep(formikProps)}
                        {step === 2 && renderFileUploadStep()}
                        {step === 3 && renderFileSetReviewStep()}
                      </VStack>
                    </Form>
                  )}
                </Formik>
              </Container>
            </VStack>
          </Center>
        </Box>
      </VStack>
    </div>
  );
}
