import React, { useEffect, useState } from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Center } from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

// Admin section components
import UserManagement from '../components/admin/UserManagement';
import CompanyManagement from '../components/admin/CompanyManagement';
import ModelManagement from '../components/admin/ModelManagement';
import CommodityManagement from '../components/admin/CommodityManagement';
import CustomerManagement from '../components/admin/CustomerManagement';

const Admin = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    const roles = user.roles || [];
    const isAdmin = roles.includes('GLOBAL_ADMIN') || roles.includes('COMPANY_ADMIN');

    if (!isAdmin) {
      navigate('/portal/main');
    }
    
    setIsLoading(false);
  }, [user, navigate]);

  if (isLoading || !user) {
    return null;
  }

  const roles = user.roles || [];
  const isGlobalAdmin = roles.includes('GLOBAL_ADMIN');
  const isCompanyAdmin = roles.includes('COMPANY_ADMIN');

  if (!isGlobalAdmin && !isCompanyAdmin) {
    return null;
  }

  return (
    <Box p={5}>
      <Box 
        position="sticky"
        top="100px"
        zIndex={10}
      >
        <Tabs>
          <Box 
            bg="white" 
            borderBottom="1px solid" 
            borderColor="gray.200"
          >
            <Center>
              <TabList>
                {(isGlobalAdmin || isCompanyAdmin) && (
                  <Tab minWidth="150px">Companies</Tab>
                )}
                <Tab minWidth="150px">Users</Tab>
                <Tab minWidth="150px">Customers</Tab>
                {isGlobalAdmin && (
                  <Tab minWidth="150px">Models</Tab>
                )}
                {isGlobalAdmin && (
                  <Tab minWidth="150px">Commodities</Tab>
                )}
              </TabList>
            </Center>
          </Box>

          <Box bg="white">
            <TabPanels>
              {(isGlobalAdmin || isCompanyAdmin) && (
                <TabPanel>
                  <CompanyManagement />
                </TabPanel>
              )}
              <TabPanel>
                <UserManagement />
              </TabPanel>
              <TabPanel>
                <CustomerManagement />
              </TabPanel>
              {isGlobalAdmin && (
                <TabPanel>
                  <ModelManagement />
                </TabPanel>
              )}
              {isGlobalAdmin && (
                <TabPanel>
                  <CommodityManagement />
                </TabPanel>
              )}
            </TabPanels>
          </Box>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Admin;